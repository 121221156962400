<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png">
    <div><span style="font-size: 36px">西施工作站</span></div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'Home',
  data(){
    return {
      // publicPath: process.env.BASE_URL,
    }
  }
}
</script>
