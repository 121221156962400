import {createApp} from 'vue';
import App from './App.vue';
import router from './router/router';
import 'w3-css/w3.css';
import axios from 'axios';
import VueAxios from "vue-axios";
import store from './store';
import VueLoading from 'vue-loading-overlay';
import Notifications from '@kyvg/vue3-notification';
import VueSmoothScroll from 'vue3-smooth-scroll';
import { plugin, defaultConfig } from '@formkit/vue'
// import { VueReCaptcha } from 'vue-recaptcha-v3'

createApp(App)
    .use(store)
    .use(router)
    .use(VueAxios,axios)
    .use(plugin, defaultConfig)
    .use(VueLoading,{
        color: '#64d78b',
    })
    .use(Notifications)
    .use(VueSmoothScroll,{
        updateHistory: false,
        duration: 600,
    })
    // .use(VueReCaptcha, { siteKey: '6LcG3-8bAAAAAIipOb_TdlEU2TuiozYTo8s6ifaB' })
    .mount('#app');
