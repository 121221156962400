<template>
  <button class="w3-button w3-deep-purple" @click="resetInitialPos" v-if="resetButton">{{btnName}}</button>
  <div ref="draggableContainer" id="draggable-container">
    <div id="draggable-header" @mousedown="dragMouseDown" @dblclick="toggleElement">
      <slot name="header"></slot>
      <div class="toggle-button w3-right" @mousedown.stop @click="toggleElement"><i :class=toggleIcon></i></div>
    </div>
    <div id="draggable-main" :style="{'max-height': maxHeight+'px'}" :class="{'hidden-main':!isShow}">
      <slot name="main"></slot>
    </div>
    <div id="draggable-foot">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DraggableDiv',
  data() {
    return {
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0
      },
      isShow: true,
    }
  },
  props: {
    btnName: {
      type: String,
      required: false,
      default: 'RESET',
      note: 'reset button name'
    },
    resetButton: {
      type: Boolean,
      required: false,
      default: false,
      note: 'reset button name'
    },
    iniPosition: {
      type: Array,
      required: false,
      default: ()=>["15vh","65vw"],
      note: 'initial position of (x, y)'
    },
    maxHeight: {
      type: Number,
      required: false,
      default: 500,
      note: 'max height of main container'
    }
  },
  methods: {
    dragMouseDown(event) {
      event.preventDefault()
      // console.log(event);
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      document.onmousemove = this.elementDrag
      document.onmouseup = this.closeDragElement
    },
    elementDrag(event) {
      event.preventDefault()
      this.positions.movementX = this.positions.clientX - event.clientX
      this.positions.movementY = this.positions.clientY - event.clientY
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      // set the element's new position:
      this.$refs.draggableContainer.style.top = (this.$refs.draggableContainer.offsetTop - this.positions.movementY) + 'px'
      this.$refs.draggableContainer.style.left = (this.$refs.draggableContainer.offsetLeft - this.positions.movementX) + 'px'
    },
    closeDragElement() {
      document.onmouseup = null
      document.onmousemove = null
    },
    toggleElement() {
      this.isShow = !this.isShow;
    },
    resetInitialPos(){
      this.$refs.draggableContainer.style.top = this.iniPosition[0];
      this.$refs.draggableContainer.style.left = this.iniPosition[1];
    }
  },
  computed: {
    toggleIcon() {
      return {
        'fa': true,
        'fa-caret-up': this.isShow,
        'fa-caret-down': !this.isShow,
      }
    },
  },
  mounted() {
    this.$refs.draggableContainer.style.top = this.iniPosition[0];
    this.$refs.draggableContainer.style.left = this.iniPosition[1];
  }
}
</script>

<style lang="scss" scoped>
#draggable-container {
  position: absolute;
  z-index: 9998;
  width: fit-content;
  min-width: 300px;
  max-width: 400px;
}

#draggable-header {
  z-index: 9999;
  cursor: grab;
  background-color: #56dc8e;
  border: 1px solid #3baf68;
  font-size: 1.5em;
  user-select: none;

  .toggle-button {
    padding: 0 18px 0 18px;
    cursor: pointer;
  }
}

#draggable-main {
  position: relative;
  background-color: #ffffff;
  border: 1px solid #b3ecce;
  //max-height: 500px;
  height: auto;
  transition: max-height .5s ease;
  overflow-y: scroll;
  &.hidden-main {
    max-height: 0!important;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}

</style>
