<template>
<div :style="{width:width+'px'}" class="boolean-options" :ref="'container'" tabindex="0" @keydown="onKeyDown">
  <div :style="{width:(width/2)+'px'}" :class="{ selected: check, default: !check}" @click="onClick(true)">是</div>
  <div :style="{width:(width/2)+'px'}" :class="{ selected: !check, default: check}" @click="onClick(false)">否</div>
</div>
</template>

<script>
export default {
  name: "booleanSelectEditor",
  data() {
    return{
      check: false,
      width: 0,
    }
  },
  methods:{
    getValue(){
      return this.check;
    },
    isPopup(){
      return true;
    },
    setCheck(check){
      this.check = check;
    },
    toggleCheck(){
      this.setCheck(!this.check);
    },
    onClick(check){
      this.setCheck(check);
      this.params.api.stopEditing();
    },
    onKeyDown(event){
      const key = event.key;
      if (key === 'ArrowLeft' || key === 'ArrowRight') {
        this.toggleCheck();
        event.stopPropagation();
      }
    },
  },
  created() {
    this.setCheck(this.params.value);
    // console.log(this.params);
    this.width = this.params.column.actualWidth;
  },
  mounted() {
    this.$nextTick(()=>{
      this.$refs.container.focus();
    })
  }
}
</script>

<style scoped lang="scss">
</style>
