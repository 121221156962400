<template>
    <div class="w3-container w3-center filter-panel">
        <div class="filter-title" @click="isFilterPanelOpen=!isFilterPanelOpen"><h3>設定篩選條件</h3></div>
        <div class="filter-fold" :class="filterPanelClass">
            <div class="filter-row">
                <div class="filter-col">
                    <div>產品名稱</div>
                    <!--        <input type="text" placeholder="例: SHE001-A"/>-->
                    <Dropdown
                            ref="dropdownElement"
                            :options="options"
                            :max-item="12"
                            :max-height="190"
                            v-on:selected="validateSelection"
                            placeholder="請輸入產品編號" @click.prevent>
                    </Dropdown>
                </div>
                <div class="filter-col">
                    <div>填單人</div>
                    <div>
                        <input type="text" v-model="filterConditions.applicant" placeholder="例: 小明"/>
                    </div>
                </div>
            </div>
            <div class="filter-row">
                <div class="filter-col">
                    <div>客製簡稱</div>
                    <div>
                        <input type="text" v-model="filterConditions.customized" placeholder="例: E123改垂SE321主體"/>
                    </div>
                </div>
                <div class="filter-col">
                    <div>模具編號</div>
                    <div>
                        <input type="text" v-model="filterConditions.ctMoldNum" placeholder="例: SUYA105-A"/>
                    </div>
                </div>
            </div>
            <div class="filter-row"><span>下單日期</span><span>從</span><input type="date"
                                                                               v-model="filterConditions.date.from"/><span>到</span><input
                    type="date" v-model="filterConditions.date.to"/></div>
            <div class="filter-row"><span>開始日期</span><span>從</span><input type="date"
                                                                               v-model="filterConditions.start.from"/><span>到</span><input
                    type="date" v-model="filterConditions.start.to"/></div>
            <div class="filter-row"><span>結束日期</span><span>從</span><input type="date"
                                                                               v-model="filterConditions.end.from"/><span>到</span><input
                    type="date" v-model="filterConditions.end.to"/></div>
            <div class="w3-container w3-center filter-row">
                <button class="w3-button w3-teal w3-text-white w3-hover-pale-green" @click.prevent="applyFilter">
                    開始篩選
                </button>
                <button class="w3-button w3-deep-orange w3-text-white w3-hover-pale-red" @click.prevent="resetFilter">
                    清除條件
                </button>
            </div>
        </div>
    </div>
    <div class="w3-container w3-center button-row">
        <button class="w3-button w3-red w3-text-white w3-hover-pale-red" @click="deleteRow"
                :disabled="isDeleted||selectedRow.length===0">刪除訂單
        </button>
        <!--    <button class="w3-button w3-cyan w3-text-white w3-hover-pale-blue" v-print="'#printMe'">列印清單</button>-->
    </div>
    <div id="printMe" class="castOrderContainer w3-container w3-center">
        <div class="rows-display w3-right">
            <span>列表順序 </span>
            <input type="radio" v-model="listOrder" value="ASC"/>
            <label>由舊到新&nbsp;</label>
            <input type="radio" v-model="listOrder" value="DESC"/>
            <label>由新到舊&nbsp;&nbsp;&nbsp;&nbsp;</label>
            <span>每頁顯示筆數</span>
            <input type="radio" name="rows" value="10" v-model="rowsPerPage"><label>10</label>
            <input type="radio" name="rows" value="20" v-model="rowsPerPage"><label>20</label>
            <input type="radio" name="rows" value="30" v-model="rowsPerPage"><label>30</label>
        </div>
        <table id="castOrderDisplayTable" class="w3-table-all">
            <thead>
            <tr>
                <th class="w3-center"></th>
                <th class="w3-center">鑄造單ID</th>
                <th class="w3-center">填單人</th>
                <th class="w3-center">#常規</th>
                <th class="w3-center">#客製</th>
                <th class="w3-center">下單日期</th>
                <th class="w3-center">開始日期</th>
                <th class="w3-center">結束日期</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in castOrderList" :key="item.castOrder_number"
                :style="{'border': clicked===item.castOrder_number?'2px solid #56b9ea':'none'}"
                @click="clickOneCastOrder(item.castOrder_number,item.castOrder_date)">
                <td class="w3-center" @click.stop><input class="w3-check" :value=item.castOrder_number
                                                         v-model="selectedRow"
                                                         type="checkbox"></td>
                <td class="w3-center">{{ item.castOrder_id }}</td>
                <td>{{ item.castOrder_applicant }}</td>
                <td class="w3-center">{{ item.p_count }}</td>
                <td class="w3-center">{{ item.c_count }}</td>
                <td class="w3-center">{{ item.castOrder_date }}</td>
                <td class="w3-center">{{ item.castOrder_start }}</td>
                <td class="w3-center">{{ item.castOrder_end }}</td>
            </tr>
            </tbody>
        </table>
        <div class="table-page">
            <ul class="pagination">
                <li class="page-item" @click.prevent="clickNextPage('previous')"><a class="page-link" href>&lt;</a></li>
                <!--        <li class="page-item" v-if="maxPages>criticalPages" @click.prevent="clickPage(1)"-->
                <!--            :class="{'active':1===currentPage}">-->
                <!--          <a class="page-link" href>1</a>-->
                <!--        </li>-->
                <li class="page-item" v-for="page in truncatedPageArr" :key="page" @click.prevent="clickPage(page)"
                    :class="{'active':page===currentPage}">
                    <a class="page-link" href>{{ page }}</a>
                </li>
                <!--        <li class="page-item" v-if="maxPages>criticalPages" @click.prevent="clickPage(maxPages)"-->
                <!--            :class="{'active':maxPages===currentPage}">-->
                <!--          <a class="page-link" href>{{ maxPages }}</a>-->
                <!--        </li>-->
                <li class="page-item" @click.prevent="clickNextPage('next')"><a class="page-link" href>&gt;</a></li>
            </ul>
        </div>
    </div>
    <LightBox ref="lightBoxElement">
        <template #order>
            <SingleCastingOrderDisplay ref="singleOrder"></SingleCastingOrderDisplay>
        </template>
        <template #results>
            <CastingOrderResultsTable></CastingOrderResultsTable>
        </template>
    </LightBox>

    <div style="position: relative">
        <vue-loading v-model:active="isLoading"
                     :is-full-page="true"
                     :background-color="'#959595'"
                     :color="'#64d78b'"
                     :opacity="0.4"
                     :width="150"
                     :height="150">
        </vue-loading>
    </div>
</template>

<script>
import {mapGetters, mapMutations, mapState} from "vuex";
import print from 'vue3-print-nb';
import range from 'lodash/range';
import LightBox from "@/components/LightBox";
import SingleCastingOrderDisplay from "@/components/SingleCastingOrderDisplay";
import CastingOrderResultsTable from "@/components/CastingOrderResultsTable";
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Dropdown from "@/components/DropdownTrue";

export default {
    name: "DisplayCastingOrder",
    components: {CastingOrderResultsTable, SingleCastingOrderDisplay, LightBox, VueLoading, Dropdown},
    directives: {
        print
    },
    data() {
        return {
            currentPage: 1,//當前指向分頁
            rowsPerPage: 10,//每頁顯示多少筆訂單
            criticalPages: 9,//臨界值，當分頁數超過時自動縮減同時顯示的分頁

            clicked: null,
            results: this.castOrderResults,

            selectedRow: [],
            isDeleted: false,
            listOrder: "ASC",

            isLoading: false,

            isFilterSet: false,//啟用後才會讓所有查詢都應用篩選條件
            filterConditions: {
                productName: '',
                applicant: '',
                date: {from: '', to: ''},
                start: {from: '', to: ''},
                end: {from: '', to: ''},
                customized: '',
                ctMoldNum: '',
            },
            isFilterPanelOpen: false,
        }
    },
    methods: {
        ...mapMutations(['setRowsPerPage', 'setCastOrderId', 'setRowsCount']),
        clickPage(page) {
            if (this.currentPage !== page && page !== "...") {
                this.selectedRow = [];
                this.currentPage = page;
                this.isLoading = true;
                this.$store.dispatch('fetchCastingOrderList', {
                    currentPage: this.currentPage,
                    filterConditions: this.determineFilter,
                })
                    .catch(e => console.error(e)).finally(() => this.isLoading = false);
            }
        },
        clickNextPage(orientation) {
            if (orientation === "next") {
                if (this.currentPage < this.maxPages) {
                    this.selectedRow = [];
                    this.currentPage += 1;
                    this.isLoading = true;
                    this.$store.dispatch('fetchCastingOrderList', {
                        currentPage: this.currentPage,
                        filterConditions: this.determineFilter,
                    })
                        .catch(e => console.error(e)).finally(() => this.isLoading = false);
                }
            } else if (orientation === "previous") {
                if (this.currentPage > 1) {
                    this.selectedRow = [];
                    this.currentPage -= 1;
                    this.isLoading = true;
                    this.$store.dispatch('fetchCastingOrderList', {
                        currentPage: this.currentPage,
                        filterConditions: this.determineFilter,
                    })
                        .catch(e => console.error(e)).finally(() => this.isLoading = false);
                }
            }
        },
        clickOneCastOrder(uuid, data) {
            if (this.clickedOrder !== uuid) {
                this.clicked = uuid;
                this.$refs.singleOrder.selectRow = "";
                this.$refs.singleOrder.searchFilter = "";
                this.setCastOrderId(uuid);
                this.$store.dispatch('fetchCastingOrder', uuid).catch(e => console.error(e));
            }
            this.$nextTick(() => {
                this.$refs.lightBoxElement.toggleBox();
                this.$refs.lightBoxElement.setTitle(data);
            });
        },
        deleteRow() {
            // console.log(JSON.parse(JSON.stringify(this.selectedRow)));
            const answer = window.confirm(`將要刪除${this.selectedRow.length}筆訂單，刪除後無法復原，確定嗎?`);
            if (this.selectedRow.length === 0 || !answer) return false;
            this.isDeleted = true;
            this.isLoading = true;
            this.$store.dispatch('deleteCastingOrder', JSON.parse(JSON.stringify(this.selectedRow)))
                .then(() => {
                    this.$store.dispatch('fetchCastingOrderRowsCount').catch(e => console.error(e));
                    this.$store.dispatch('fetchCastingOrderList', {
                        currentPage: this.currentPage,
                        filterConditions: this.determineFilter,
                    })
                        .catch(e => console.error(e));
                    this.isDeleted = false;
                })
                .catch(e => {
                    console.error(e);
                    this.isDeleted = false;
                }).finally(() => this.isLoading = false);
        },
        //產品編號自動填入功能
        validateSelection(selection) {
            this.filterConditions.productName = selection;
            console.log(selection + ' has been selected');
        },
        resetFilter() {
            this.$refs.dropdownElement.searchFilter = '';
            this.$refs.dropdownElement.selected = '';
            this.isFilterSet = false;
            this.filterConditions = {
                productName: '',
                applicant: '',
                date: {from: '', to: ''},
                start: {from: '', to: ''},
                end: {from: '', to: ''},
                customized: '',
                ctMoldNum: '',
            };
            let loader = this.$loading.show({
                container: null,
                isFullPage: true,
                backgroundColor: '#959595',
                loader: 'dots',
                opacity: 0.5,
                width: 150,
                height: 150,
            });
            this.$store
                .dispatch('fetchCastingOrderRowsCount')
                .then(() => {
                    this.$store
                        .dispatch('fetchCastingOrderList', {currentPage: 1})
                        .then(() => loader.hide())
                        .catch(e => {
                            console.error(e);
                            loader.hide();
                        })
                })
                .catch(e => {
                    console.error(e);
                    loader.hide();
                });
        },
        applyFilter() {
            console.log(this.filterConditions);
            this.isFilterSet = true;
            this.isLoading = true;
            this.$store.dispatch('fetchCastingOrderList', {
                currentPage: this.currentPage,
                filterConditions: this.determineFilter,
            })
                .catch(e => console.error(e)).finally(() => this.isLoading = false);
        },
    },
    computed: {
        ...mapState({
            clickedOrder: 'clickedOrderId',
            castOrderList: 'castOrderList',
            listIsLoaded: 'castOrderListIsLoaded',
            castOrderResults: 'castOrderResults',
            options: 'productList',//獲取所有產品編號資料
            productListIsLoaded: 'productListIsLoaded',//是否成功獲取產品清單
        }),
        ...mapGetters({
            maxPages: 'maxPages',
        }),
        truncatedPageArr() {
            let pageArr = range(1, this.maxPages + 1);
            if (this.maxPages < this.criticalPages) {
                return pageArr;
            } else {
                if (this.currentPage <= 3) {
                    return [...pageArr.slice(0, 3), "...", ...pageArr.slice(-2)];
                } else if (this.currentPage > 3 && this.currentPage <= this.maxPages - 3) {
                    return [...pageArr.slice(0, 2), "...", ...pageArr.slice(this.currentPage - 2, this.currentPage + 1), "...", ...pageArr.slice(-2)];
                } else {
                    return [...pageArr.slice(0, 2), "...", ...pageArr.slice(-3)];
                }
            }
        },
        determineFilter() {
            return this.isFilterSet ? JSON.parse(JSON.stringify(this.filterConditions)) : false;
        },
        filterPanelClass() {
            return {
                'open': this.isFilterPanelOpen,
                'close': !this.isFilterPanelOpen,
            }
        },
    },
    created() {
        if (localStorage.getItem('listOrderProperty')) {
            this.listOrder = localStorage.getItem('listOrderProperty');
        }
        let loader = this.$loading.show({
            container: null,
            isFullPage: true,
            backgroundColor: '#959595',
            loader: 'dots',
            opacity: 0.5,
            width: 150,
            height: 150,
        });
        this.$store
            .dispatch('fetchCastingOrderRowsCount')
            .then(() => {
                this.$store
                    .dispatch('fetchCastingOrderList', {currentPage: 1})
                    .then(() => loader.hide())
                    .catch(e => {
                        console.error(e);
                        loader.hide();
                    })
            })
            .catch(e => {
                console.error(e);
                loader.hide();
            });
    },
    beforeRouteLeave() {
        this.setCastOrderId('');
    },
    watch: {
        rowsPerPage() {
            this.isLoading = true;
            this.setRowsPerPage(this.rowsPerPage);
            this.$store.dispatch('fetchCastingOrderList', {
                currentPage: 1,
                filterConditions: this.determineFilter,
            })
                .catch(e => console.error(e)).finally(() => this.isLoading = false);
        },
        castOrderResults() {
            // console.log("DDDDDisplay!");
            this.results = this.castOrderResults;
        },
        listOrder() {
            this.isLoading = true;
            localStorage.setItem('listOrderProperty', this.listOrder);
            this.$store.dispatch('fetchCastingOrderList', {
                currentPage: this.currentPage,
                filterConditions: this.determineFilter,
            })
                .catch(e => console.error(e)).finally(() => this.isLoading = false);
        }
    }
}
</script>

<style lang="scss" scoped>
@import "src/assets/defaultStyle";

/* 移除input數字型別右側的增減按鈕 */
::v-deep(input::-webkit-outer-spin-button),
::v-deep(input::-webkit-inner-spin-button) {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
::v-deep(input[type=number]) {
  -moz-appearance: textfield;
}

.filter-panel {
  width: 90%;
  margin: 1em auto;
  background-color: #eeeeee;

  .filter-title {
    background-color: $table-head-background-color;
    cursor: pointer;
  }

  .filter-fold {
    height: 240px;
    transition: height 500ms ease;
    overflow-y: hidden;

    &.close {
      height: 0;
    }
  }

  .filter-row {
    width: 90%;
    margin: 0.5em auto;
    //display: inline-flex;
    .filter-col {
      width: 50%;
      position: relative;
      display: inline-flex;

      div:nth-child(1) {
        width: 30%;
        font-weight: bold;
      }

      div:nth-child(2) {
        width: 70%;

        input {
          width: 100%;
        }
      }
    }

    span {
      margin: 0.75em;
      font-weight: bold;
    }

    input {
      width: 30%;
    }

    button {
      margin: 0 5px;
    }
  }
}

.button-row button {
  margin: 0 5px;
}

.castOrderContainer {
  position: relative;
  margin: 0 auto;
  width: 90%;
  min-width: 600px;
}

table {
  user-select: text;
  margin-top: 10px;
  margin-bottom: 10px;

  thead tr th, tfoot tr th {
    height: 40px;
    background-color: $table-head-background-color;
  }

  tbody {
    tr {
      cursor: pointer;

      td {
        height: $table-row-height;
        padding: 5px 0 !important;
        vertical-align: middle;
        max-height: 40px;
        min-height: 0;

        &:nth-child(1) {
          width: 5%;
        }

        &:nth-child(2) {
          width: 10%;
        }

        &:nth-child(3) {
          width: 30%;
        }
      }

      &:hover td {
        background-color: #c1c1c1;
      }
    }


  }
}

input[name="rows"] {
  margin-left: 5px;

  & ~ label {
    margin-left: 2px;
  }
}

.table-page {
  margin: 0 auto;
  user-select: none;
}

.pagination {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 20px auto;
  padding: 0 2px;
  width: fit-content;

  .page-item {
    cursor: pointer;
    font-size: 1rem;
    list-style: none;
    display: block;
    width: 40px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border: 1px solid $pagination-border-color;
    color: $pagination-text-color;
    float: left;
    margin: 0 -1px 0 0;

    &:hover {
      background-color: $pagination-background-color;
    }

    &.active {
      background-color: $pagination-background-color-hover;

      a {
        color: $pagination-text-color-hover;
      }
    }

    a {
      color: #3eaf7c;
      text-decoration: none;
    }
  }
}

@media print {
  table thead tr th:nth-child(1),
  table tbody tr td:nth-child(1),
  .rows-display,
  .table-page {
    display: none;
  }
}
</style>
