<template>
  <form>
    <div class="information w3-container ">
      <div class="w3-panel w3-padding-24 w3-grey w3-row">
        <div class="w3-row-padding">
          <div class="w3-col s12 m6 l3">
            <label>填寫人<span style="color: red">*</span>
              <input class="w3-input w3-border" type="text" v-model="myFormData.applicant" name="applicant"
                     placeholder="請輸入名稱">
            </label>
          </div>
          <div class="w3-col s12 m6 l3">
            <label>填寫日期<span style="color: red">*</span>
              <input class="w3-input w3-border" type="date" v-model="myFormData.fillingDate" name="fillingDate">
            </label>
          </div>
          <div class="w3-col s12 m6 l3">
            <label>訂單開始日期
              <input class="w3-input w3-border" type="date" v-model="myFormData.startDate" name="startDate"
                     @change="startDateChange">
            </label>
          </div>
          <div class="w3-col s12 m6 l3">
            <label>結束日期
              <input class="w3-input w3-border" type="date" v-model="myFormData.endDate" name="endDate"
                     @change="endDateChange">
            </label>
          </div>
        </div>
        <div class="w3-row-padding">
          <div class="w3-col s12 m12 l12 w3-center">
            <button id="submitForm" class="w3-button w3-red w3-hover-pale-red w3-large w3-round"
                    type="submit" name="submit" :disabled=submitting
                    @click.prevent="submitMyForm">
              開始轉換
            </button>&nbsp;
            <div style="margin-top: 25px;display: inline-block;vertical-align: middle;">
              <label>完成後清空內容?</label>
              <Toggle v-model="isFormClear"
                      @change="toggleChange"
                      :on-label="'是'"
                      :off-label="'否'"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import Toggle from '@vueform/toggle';

export default {
  name: "InfoForm",
  components: {Toggle},
  data() {
    return {
      myFormData: {
        applicant: "",
        fillingDate: "",
        startDate: "",
        endDate: "",
      },
      submitting: false,
      isFormClear: false,
    }
  },
  methods: {
    endDateChange() {
      let that = this;
      if (this.myFormData.startDate === "" || this.myFormData.startDate === null || this.myFormData.startDate > this.myFormData.endDate) {
        that.myFormData.startDate = this.myFormData.endDate;
      }
    },
    startDateChange() {
      let that = this;
      if (this.myFormData.endDate === "" || this.myFormData.endDate === null || this.myFormData.endDate < this.myFormData.startDate) {
        that.myFormData.endDate = this.myFormData.startDate;
      }
    },
    submitMyForm() {
      if (this.myFormData.applicant && this.myFormData.fillingDate) {
        if (this.myFormData.startDate === "" && this.myFormData.endDate === "") {
          [this.myFormData.startDate, this.myFormData.endDate] = [this.myFormData.fillingDate, this.myFormData.fillingDate];
        }
        this.submitting = true;
        this.$emit('submitData', this.myFormData);
        console.log("送出訂單!")
      } else {
        alert("請輸入填寫人和填寫日期。");
      }
    },
    toggleChange(){
      localStorage.setItem('locCastIsFormClear', JSON.stringify({isFormClear: this.isFormClear}));
    },
  },
  beforeMount() {
    const record = localStorage.getItem('locCastIsFormClear');
    if (record) this.isFormClear = JSON.parse(record).isFormClear;
  },
}
</script>

<style scoped>
#submitForm {
  margin-top: 25px;
}
</style>
