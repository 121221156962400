import {createRouter, createWebHashHistory} from 'vue-router';
import store from '../store'

import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import Order from "../views/Order.vue";
import Casting from "../views/Casting.vue";
import Task from "../views/Task.vue";
import Material from "../views/Material.vue";
import CreateNewCastingOrder from "../views/CreateNewCastingOrder.vue";
import DisplayCastingOrderList from "@/views/DisplayCastingOrderList";
import SearchMoldRelation from "@/views/SearchMoldRelation";
import CastMoldMuTextTable from "@/views/CastMoldMuTextTable";
import ProductData from "@/views/ProductData";
import MoldData from "@/views/MoldData";
import NotFound from "../views/NotFound.vue";


const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        alias: ['/home', '/homepage', '/h', '/dist'],
        meta: {
            title: '西施工作站-首頁',
        },
    },
    {
        path: '/order',
        name: 'Order',
        component: Order,
        alias: ['/訂單'],
        meta: {
            title: '西施工作站-訂單',
        },
    },
    {
        path: '/casting',
        name: 'Casting',
        component: Casting,
        alias: ['/casting_order', '/castingOrder'],
        meta: {
            title: '西施工作站-鑄造',
        },
        children: [
            {
                path: 'new',
                component: CreateNewCastingOrder,
                alias: ['new_cast_order', 'newCastOrder']
            },
            {
                path: 'list',
                component: DisplayCastingOrderList,
                alias: ['cast_order_list', 'castOrderList']
            },
            {
                path: 'search_mold',
                component: SearchMoldRelation,
                alias: ['searchMold']
            },
            {
                path: 'mutate',
                component: CastMoldMuTextTable,
            },
            {
                path: 'product_dat',
                component: ProductData,
            },
            {
                path: 'mold_dat',
                component: MoldData,
            },
        ]
    },
    {
        path: '/task',
        name: 'Task',
        component: Task,
        alias: ['/task_order', '/taskOrder'],
        meta: {
            title: '西施工作站-工作派發',
        },
    },
    {
        path: '/material',
        name: 'Material',
        component: Material,
        alias: ['/material_order', '/materialOrder'],
        meta: {
            title: '西施工作站-材料發回',
        },
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFound,
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            title: '西施工作站-登入',
        },
    }
]

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL), //createWebHistory(process.env.BASE_URL),
    routes: routes,
});

function userAuth() {
    return store.state.isAuthenticated;
}

router.beforeEach(async (to) => {
    if (to.name !== 'Login' && !userAuth()) return {name: 'Login'};
    if (to.meta.title) {
        document.title = to.meta.title;
    }
})

export default router
