<template>
  <div id="anchorTop" style="position: absolute"></div>
  <transition name="fade" mode="out-in">
    <div class="sees-cast-order" v-if="isShow">
      <info-form @submitData="submitAllData" ref="info"></info-form>
      <div class="toggle-table-button">
        <div class="toggle2regular" @click.prevent.stop="toggleTable(true)" :class="{active: isRegularShow}">
          <span>常規</span>
        </div>
        <div class="toggle2customized" @click.prevent.stop="toggleTable(false)" :class="{active: !isRegularShow}">
          <span>客製</span>
        </div>
      </div>

      <div class="shifting-panel" :class="{'shift-right': !isRegularShow}">

        <div class="casting-table w3-container w3-center">
          <button class="w3-button w3-lime w3-hover-pale-yellow" @click.self="toggleBox">批次輸入產品</button>
          <button class="w3-button w3-pink w3-hover-pale-red" @click.self="undoDeleted">復原上一筆資料</button>
          <button class="w3-button w3-light-blue w3-hover-pale-blue" @click.self="backup">暫存資料</button>
          <button class="w3-button w3-red" @click.self="resetData" :disabled="products.length===0">清空資料</button>

          <table id="myTable" class="w3-table-all">
            <thead>
            <tr>
              <th class="w3-center"></th>
              <th><label @click="setSort('name')">產品名稱</label></th>
              <th class="w3-center">
                <label @click="setSort('quantity')">數量</label></th>
              <th><label @click="setSort('prior')">優先</label></th>
              <th class="w3-center">
                <label @click.stop="allEditSwitch(true)">編輯</label> / <label
                  @click.stop="allEditSwitch(false)">儲存</label>
              </th>
              <th class="w3-center">刪除</th>
            </tr>

            <tr class="add-button">
              <th class="new-row w3-center" colspan="6" @click="addNewRow('top')">
                <i class="fa fa-plus-square fa-lg"></i>
              </th>
            </tr>
            </thead>

            <transition-group name="slide" tag="TBODY">
              <EditableRow
                  :style="{'z-index': products.length-index}"
                  v-bind="item" v-for="(item,index) in products"
                  :key="item.id" :index="index" :ref="setItemRef"
                  @delete-row="del"
                  @update="updateProduct"
                  @drag-row.self="ondragRow"
                  @drop-row.self="ondropRow"
                  @drag-enter.self="dragxRows">
              </EditableRow>
            </transition-group>


            <tfoot>
            <tr class="add-button">
              <td class="new-row w3-center" colspan="6" @click="addNewRow('bottom')">
                <i class="fa fa-plus-square fa-lg"></i>
              </td>
            </tr>

            <tr>
              <th class="w3-center"></th>
              <th><label @click="setSort('name')">產品名稱</label></th>
              <th class="w3-center">
                <label @click="setSort('quantity')">數量</label></th>
              <th><label @click="setSort('prior')">優先</label></th>
              <th class="w3-center">
                <label @click.stop="allEditSwitch(true)">編輯</label> /
                <label @click.stop="allEditSwitch(false)">儲存</label>
              </th>
              <th class="w3-center">刪除</th>
            </tr>
            </tfoot>
          </table>

        </div>

        <div class="customized-table w3-container w3-center">
          <SelectMoldOfCustomizedProduct ref="customizedProducts"></SelectMoldOfCustomizedProduct>
        </div>
      </div>
    </div>

    <div class="sees-cast-order-result" v-else>
      <div id="casting-results" class="w3-container w3-center">
        <button class='w3-button w3-indigo w3-center' @click="csvExport(castOrderResults)">
          下載檔案
        </button>
        <CastingOrderResultsTable></CastingOrderResultsTable>
      </div>
    </div>
  </transition>

    <button @click="showMe">baseURL為:{{ baseURL }}</button>
  <Keypress key-event="keyup" :multiple-keys="multiple" @success="addNewRow('bottom')"></Keypress>

  <teleport to="body">
    <LightBoxTrue ref="lightBoxElement" :opacity="0.5">
      <template #main>
        <div>批次輸入產品</div>
        <textarea class="product-list-window"
                  v-model="product4import"
                  placeholder="請直接從試算表複製貼上產品編號，可選取多筆後貼上，數量必須'*'符號接在產品編號之後且不可有空格，才能正確讀取，每項產品需以至少一個空格或換行分開"></textarea>
        <div class="w3-center">
          <button class="w3-button w3-teal w3-hover-pale-green"
                  @click.stop="text2Array" :disabled="!product4import">匯入
          </button>&nbsp;
          <Toggle v-model="product4importToggle"
                  :on-label="'開'"
                  :off-label="'關'"/>
          <label>忽略錯誤編號</label>
        </div>
      </template>
    </LightBoxTrue>
    <notifications group="conversionNotice" position="bottom right" :max="8" :speed="500" :ignore-duplicates="true"/>
    <div class="scroll-widget">
      <div class="scroll-button" @click.prevent.stop="reloadTableComponent">
        <i class="fas fa-redo" style="font-size: 1.8em"></i>
      </div>
      <div class="scroll-button" @click.prevent.stop="scrollToTop">
        <i class="far fa-arrow-alt-circle-up"></i>
      </div>
      <div class="scroll-button" @click.prevent.stop="scrollToBottom">
        <i class="far fa-arrow-alt-circle-down"></i>
      </div>
    </div>
  </teleport>
  <div id="anchorBottom" style="position: absolute"></div>
</template>

<script>
import EditableRow from "../components/EditableRow";
import CastingOrderResultsTable from "@/components/CastingOrderResultsTable";
import {mapState} from 'vuex';
import InfoForm from "@/components/InfoForm";
import SelectMoldOfCustomizedProduct from "@/components/SelectMoldOfCustomizedProduct";
import Keypress from "vue-keypress";
import Toggle from '@vueform/toggle';
import LightBoxTrue from "@/components/LightBoxTrue";
// import DraggableDiv from "@/components/DraggableFloatingWindow";
// import SearchProductMoldAssociationRealtime from "@/components/SearchProductMoldAssociationRealtime";
// import {mapGetters} from 'vuex';
// import {mapMutations} from 'vuex';
// import axios from "axios";


export default {
  name: "CreateNewCastingOrder",
  components: {
    SelectMoldOfCustomizedProduct,
    // SearchProductMoldAssociationRealtime,
    // DraggableDiv,
    CastingOrderResultsTable,
    InfoForm,
    EditableRow,
    Keypress,
    LightBoxTrue,
    Toggle,
  },
  data() {
    return {
      products: [
        {"id": 0, "name": "", "quantity": 1, "prior": false, "editing": true},
        {"id": 1, "name": "", "quantity": 1, "prior": false, "editing": true},
        {"id": 2, "name": "", "quantity": 1, "prior": false, "editing": true},
      ],
      sortMethod: {"ref": "id", "isAsc": -1},
      itemRefs: [],
      draggedIndex: 0,
      timeout: false,
      requestLastTime: null,

      trash: [],//preserved the deleted row data

      isShow: true,
      // results: [],
      uploadSuccess: false,

      isRegularShow: true,//顯示常規品頁面

      multiple: [
        {
          keyCode: 65, // A
          modifiers: ['altKey'],
          preventDefault: true,
        },
      ],
      product4import: "",
      product4importToggle: false,
    }
  },
  methods: {
    //vuex
    // ...mapMutations({}),
    toggleTable(isReg) {
      // this.isRegularShow = !this.isRegularShow;
      this.isRegularShow = isReg;
    },
    //將用於v-for迭代的每個元素存為參照對象
    //TODO 這樣做每次更動products就會再次塞入新的ref元素，會不斷擴增itemRefs的長度
    setItemRef(el) {
      if (el) this.itemRefs.push(el);
    },
    //滾動螢幕
    screenScroll(offset) {
      this.$nextTick(() => {
        const el = document.getElementsByTagName("TABLE")[0];
        const elementRect = el.getBoundingClientRect();
        document.documentElement.scrollTop = elementRect.height - offset;
      });
    }
    ,
    //對應EditableRw元件中的自訂事件update，元件元素偵測到更新，傳送更新元件資料與index到母元件做同步更新
    updateProduct(val, index) {
      this.products[index] = val;
      this.backup(false);
    },
    addNewRow(pos) {
      if (pos === "top") {
        let newId = Math.min(...this.products.map(product => product.id)) - 1;
        if (newId === -Infinity || newId === Infinity) newId = 0;
        this.products.unshift(
            {"id": newId, "name": "", "quantity": 1, "prior": false, "editing": true}
        );
        this.$nextTick(() => {
          // console.log(document.querySelectorAll("#myTable tbody tr td input.dropdown-input"));
          document.querySelectorAll("#myTable tbody tr td input.dropdown-input")[0].focus();
        });
      } else if (pos === "bottom") {
        let newId = Math.max(...this.products.map(product => product.id)) + 1;
        if (newId === -Infinity || newId === Infinity) newId = 0;
        this.products.push(
            {"id": newId, "name": "", "quantity": 1, "prior": false, "editing": true}
        );
        this.$nextTick(() => {
          let allInput = document.querySelectorAll("#myTable tbody tr td input.dropdown-input");
          allInput[allInput.length - 1].focus();
        });
        this.screenScroll(200);
      }
      this.sortMethod.ref = "id";
      this.sortMethod.isAsc = 1;
    },
    //切換排序依據
    setSort(reference) {
      if (this.timeout) return;
      this.sortMethod.ref = reference;
      this.sortMethod.isAsc = this.sortMethod.isAsc * -1;
      this.products = this.sortBy;
      this.timeout = setTimeout(() => {
        this.timeout = false;
      }, 600);
      this.backup(false);
    },
    //全體切換編輯/儲存狀態
    allEditSwitch(val = true) {
      if (!val) {
        this.itemRefs.forEach(e => e.saveData(true));
      } else {
        this.itemRefs.forEach(e => e.productItem.editing = true);
      }
    },
    //移除一筆資料，並儲存到垃圾桶，若資料中產品編號為空，則刪除後不儲存
    del(id) {
      let item = this.products.splice(id, 1)[0];
      if (item.name !== "") this.trash.push(item);
      this.backup(false);
    },
    //還原一筆刪除的資料，並且會重新編號為當前資料最大編號+1
    undoDeleted() {
      if (this.trash.length === 0) return;
      let undoRow = this.trash.splice(this.trash.length - 1, 1)[0];
      let newId = Math.max(...this.products.map(product => product.id)) + 1;
      if (newId === -Infinity) newId = 0;
      undoRow.id = newId;
      this.products.push(undoRow);
      this.screenScroll();
      this.backup(false);
    },
    //拖曳功能
    ondragRow(a) {
      this.draggedIndex = a;
    },
    ondropRow(a, b) {
      console.log(a, b);
    },
    dragxRows(a) {
      if (a !== this.draggedIndex) {
        this.products.splice(a, 0, this.products.splice(this.draggedIndex, 1)[0]);
        this.draggedIndex = a;
        this.backup(false);
      }
    },
    //表單提交
    submitAllData(data) {
      let copyData = [];
      let copyCus = [];
      let isErrorInput = document.querySelector("input.dropdown-input.error");
      if (isErrorInput) {
        const answer = window.confirm("先前批量匯入時出現錯誤編號，確定要轉換? 若想返回請點'取消'。");
        if (!answer) {
          setTimeout(() => {
            this.$refs.info.submitting = false
          }, 2000);
          return false;
        }
      }
      this.allEditSwitch(false);///全部列儲存
      this.products.forEach((value) => {
        if (this.productList.includes(value.name)) {
          if (copyData.map(x => x.name).includes(value.name)) {
            let id = copyData.findIndex(x => x.name === value.name);
            copyData[id].quantity = parseInt(copyData[id].quantity) + parseInt(value.quantity);
            copyData[id].prior = copyData[id].prior || value.prior;
          } else {
            copyData.push(JSON.parse(JSON.stringify(value)));
          }
        } else {
          this.$notify({
            group: "conversionNotice",
            title: value.name ? `產品編號${value.name}不存在，已自動忽略。` : "未輸入產品編號，已忽略。",
            type: "warn",
          });
        }
      });
      this.$refs.customizedProducts.customized.forEach((value) => {
        if (value.name !== "" && value.moldAssociation.length !== 0) {
          copyCus.push(JSON.parse(JSON.stringify(value)));
        }
      });
      let allData = {
        applicantData: data,
        castOrderData: copyData,
        customizedData: copyCus,
      };
      //檢查訂單是否為空
      if (allData.castOrderData.length === 0 && allData.customizedData.length === 0) {
        this.$notify({
          group: "conversionNotice",
          title: "常規與客製訂單中無任何有效品項，停止動作。",
          type: "error",
        });
        this.$refs.info.submitting = false;
        return false;
      }
      // console.log(allData);
      let loader = this.$loading.show({
        container: null,
        isFullPage: true,
        backgroundColor: '#959595',
        loader: 'dots',
        opacity: 0.5,
        width: 150,
        height: 150,
      });
      this.$store.dispatch('submitConvertAllInOne', allData)
          .then(() => {
            this.isShow = false;
            this.uploadSuccess = true;
            this.$notify({
              group: "conversionNotice",
              title: "已成功轉換成鑄造單。",
              type: "success",
            });
            const record = localStorage.getItem('locCastIsFormClear') ? JSON.parse(localStorage.getItem('locCastIsFormClear')).isFormClear : false;
            if (record) {
              localStorage.removeItem('productTempData')
              localStorage.removeItem('customizedTempData')
            }
          })
          .catch(e => {
            console.error(e);
            this.$notify({
              group: "conversionNotice",
              title: "轉換失敗，請聯繫工作人員。",
              type: "error",
            });
          }).finally(() => loader.hide());
    },
    //初始化資料，若有先前暫時存的資料則載入
    formDataInit() {
      if (localStorage.getItem('productTempData')) {
        let local = JSON.parse(localStorage.getItem('productTempData'));
        if (local.products.length!==0) {
          console.log('已載入暫存資料');
          this.products = local.products;
        }
      }
    },
    //下載結果成CSV檔案
    csvExport(arrData) {
      if (arrData) {
        let csvContent = [
          Object.keys(arrData[0]).join(","),
          ...arrData.map(item => Object.values(item).join(","))
        ]
            .join("\n")
            .replace(/(^\[)|(]$)/gm, "");

        const data = encodeURIComponent(csvContent);
        const link = document.createElement("a");
        const filename = 'export_castOrder_' + new Date().toLocaleDateString() + '.csv';
        link.setAttribute("href", 'data:text/csv;charset=big5,' + data);
        link.setAttribute("download", filename);
        link.click();
      }
    },
    //重製預設資料
    resetData() {
      // Object.assign(this.$data, this.$options.data());
      const answer = window.confirm('清空資料後無法恢復，確定要執行?')
      if (!answer) return false;
      this.products = [];
      localStorage.removeItem('productTempData');
      this.$forceUpdate();
    },
    //批次輸入產品清單視窗切換
    toggleBox() {
      this.$refs.lightBoxElement.toggleBox();
    },
    //轉換文字為陣列並插入products中
    text2Array() {
      if (!this.product4import) return false;
      let stringArray = this.product4import.trim().split(/\s+/);
      let reg = new RegExp('[a-zA-Z]+[0-9]+(-[a-zA-Z]+[0-9]?|#[0-9]+)?', 'i');
      let newId = Math.max(...this.products.map(product => product.id)) + 1;
      stringArray.forEach(value => {
        let newValue = reg.exec(value);
        // let newQuantity = /\*\d+/i.exec(value);
        let newQuantity = /(?<=.*\*)\d+(?:\.\d+)*/i.exec(value);
        newQuantity = newQuantity ? parseFloat(newQuantity[0]) : 1;
        if (newValue) {
          newValue = newValue[0].toUpperCase();
          if (this.productList.includes(newValue) || !this.product4importToggle) {
            if (newId === -Infinity || newId === Infinity) newId = 0;
            this.products.push(
                {"id": newId, "name": newValue, "quantity": newQuantity, "prior": false, "editing": true}
            );
            newId++;
          }
        }
      })
      this.$nextTick(() => {
        this.product4import = "";
        let allInput = document.querySelectorAll("#myTable tbody tr td input.dropdown-input");
        allInput[allInput.length - 1].focus();
        this.$notify({
          group: "conversionNotice",
          title: "成功匯入",
          type: "success",
        });
      });
      this.screenScroll(200);
      this.backup(false);
    },

    // async recaptcha() {
    //   // (optional) Wait until recaptcha has been loaded.
    //   await this.$recaptchaLoaded()
    //
    //   // Execute reCAPTCHA with action "login".
    //   const token = await this.$recaptcha('login')
    //
    //   // Do stuff with the received token.
    //   console.log(token);
    // },
    showMe() {
      console.log(this.itemRefs);
      console.log(this.listIsLoaded);
      console.log(this.$refs.customizedProducts.customized);
    },
    scrollToTop() {
      const myEl = document.getElementById("anchorTop");
      this.$smoothScroll({scrollTo: myEl,});
    },
    scrollToBottom() {
      const myEl = document.getElementById("anchorBottom");
      this.$smoothScroll({scrollTo: myEl,});
    },
    reloadTableComponent() {
      // this.itemRefs = [];
      this.products = [];
      this.$forceUpdate();
      this.products = JSON.parse(localStorage.getItem('productTempData')).products;
      this.$forceUpdate();
      this.$notify({
        group: "conversionNotice",
        title: "已重新載入元件",
        type: "success",
      });
    },

    //備份當前資料
    backup(notice=true) {
      if (this.products === null || this.products.length===0) return false;
      const tempData = {products: this.products};
      localStorage.setItem('productTempData', JSON.stringify(tempData));
      this.uploadSuccess = true;
      if (notice) this.$notify({
        group: "conversionNotice",
        title: "已儲存當前表單",
        type: "success",
      });
    },
  },
  computed: {
    //根據當前排序依序來更新資料的順序
    sortBy() {
      let that = this;
      let r;
      if (this.sortMethod.ref === "id") {
        r = that.products.sort((a, b) => (a.id - b.id || a.name.localeCompare(b.name)));
      } else if (this.sortMethod.ref === "name") {
        r = that.products.sort((a, b) => this.sortMethod.isAsc * ((a.name.toUpperCase() < b.name.toUpperCase()) ? -1 : (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : 0));
      } else if (this.sortMethod.ref === "prior") {
        r = that.products.sort((a, b) => this.sortMethod.isAsc * ((a.prior === b.prior) ? 0 : a.prior ? -1 : 1));
      } else if (this.sortMethod.ref === "quantity") {
        r = that.products.sort((a, b) => this.sortMethod.isAsc * (a.quantity - b.quantity || a.name.localeCompare(b.name)));
      }
      return r;
    },
    ...mapState({
      productList: 'productList',
      listIsLoaded: 'productListIsLoaded',

      castOrderResults: 'castOrderResults',//
      castOrderResultsIsLoaded: 'castOrderResultsIsLoaded',//

      baseURL: 'baseURL',//
    }),
    // ...mapGetters({}),
  },
  beforeMount() {
    this.formDataInit();
  },
  beforeUpdate() {
    console.log(this.itemRefs.length);
    this.itemRefs = [];
  },
  beforeRouteLeave() {
    if (!this.uploadSuccess) {
      const answer = window.confirm('資料尚未送出或儲存，離開的話目前資料可能會遺失，確定要離開嗎?')
      if (!answer) return false;
    }
  }
}
</script>

<style lang="scss" scoped>
$color-button: #303366;
@mixin hover-td {
  &:hover {
    background-color: $color-button;

    i {
      color: white;
    }
  }
}

/* 移除input數字型別右側的增減按鈕 */
::v-deep(input::-webkit-outer-spin-button),
::v-deep(input::-webkit-inner-spin-button) {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
::v-deep(input[type=number]) {
  -moz-appearance: textfield;
}

.sees-cast-order {
  .toggle-table-button {
    position: relative;
    width: 95%;
    margin: 12px auto;
    user-select: none;

    .toggle2regular, .toggle2customized {
      position: relative;
      display: inline-flex;
      width: 50%;
      font-size: 1.7em;
      opacity: 1;
      transition: background-color 0.5s ease, width 0.5s ease, opacity 0.5s ease, transform 0.5s ease;

      &:not(.active):hover {
        opacity: 0.8;
        transform: scaleY(1.05);
      }

      span {
        margin: 0 auto;
      }

      &:nth-child(1) {
        background-color: #9e7250;
        width: 20%;
        cursor: pointer;

        &.active {
          background-color: #f3af78;
          width: 80%;
          cursor: default;

          span {
            font-weight: bold;
          }
        }
      }

      &:nth-child(2) {
        background-color: #6657af;
        width: 20%;
        cursor: pointer;

        &.active {
          background-color: #8774ef;
          width: 80%;
          cursor: default;

          span {
            font-weight: bold;
          }
        }
      }
    }
  }

  .shifting-panel {
    position: relative;
    width: 200% !important; //兩倍容器寬度
    overflow-x: hidden;
    overflow-y: visible;
    transform: translateX(0);
    transition: transform 0.5s ease;

    &.shift-right {
      transform: translateX(-50%);
    }

    .casting-table, .customized-table {
      //寬度+左右邊界要剛好50%
      margin: 0 2.5%;
      width: 45%;

      position: relative;
      display: block;
      float: left;
      overflow-x: hidden;
      overflow-y: visible;
      min-height: 500px;

      user-select: none;

      &::-webkit-scrollbar {
        display: none;
      }

      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }
}

#casting-results {
  margin: 0 auto;
  width: 90%;
}

button {
  margin: 1px 2px;
}

table {
  user-select: none;
  margin-top: 10px;
  margin-bottom: 10px;

  thead tr th, tfoot tr th {
    height: 40px;
    background-color: #74ffa2;

    label {
      cursor: pointer;
      font-size: 1.1em;

      &:hover {
        color: white;
        background-color: #00bcd4;
        border-radius: 2px;
      }
    }
  }

  //tbody {
  //  ::v-deep(tr td) {
  //    z-index: 50!important;
  //  }
  //}

  .new-row {
    user-select: none;
    z-index: 7 !important;
    height: 40px;
    background-color: #a7a7a7;
    cursor: pointer;
    padding: 5px 0 !important;

    transition: all 300ms ease;
    @include hover-td;

    i {
      transform: translateY(2px);
      vertical-align: middle !important;
    }
  }
}

//批次輸入產品清單視窗
textarea.product-list-window {
  width: 300px;
  height: auto;
  min-height: 300px;
  max-height: 80vh;
  resize: none;
}

//滾動至頂部底部小工具
.scroll-widget {
  z-index: 999999;
  position: fixed;
  right: 8px;
  bottom: 45px;
  padding: 15px 8px 8px;
  transform: scale(2, 2) translateX(20px);
  transition: transform 500ms;

  &:hover {
    transform: scale(2, 2) translateX(0);
  }

  .scroll-button {
    width: 30px;
    height: 30px;
    //background-color: rgba(66, 185, 131, 0.71);
    i {
      font-size: 2em;
      color: rgba(66, 185, 131, 0.25);
      transition: color 500ms;

      &:hover {
        color: rgba(67, 198, 139, 0.95);
      }
    }
  }
}

//資料列被拖動、新增、刪除時的動畫
.slide-move {
  transition: all 500ms;
}

.slide-enter-from {
  transform: translateY(-40px) rotate3d(1, 0, 0, -90deg);
}

.slide-enter-active {
  position: relative;
  z-index: -1 !important;
  transition: all 500ms ease;
}

.slide-enter-to {
}

.slide-leave-from {
}

.slide-leave-active {
  position: relative;
  z-index: -1 !important;
  transition: all 500ms ease;
}

.slide-leave-to {
  transform: translateX(80vw) rotate3d(1, 0, 0, 90deg);
  opacity: 0;
}

//傳送資料後顯示結果的動畫
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.75s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}
</style>
<style src="@vueform/toggle/themes/default.css"></style>
