<template>
    <div class="productMoldContainer w3-container w3-center">
        <div>
            <input class="w3-input" placeholder="請輸入模具編號" v-model="searchMold">
        </div>
        <div ref="loaderContainer">
            <table id="productMoldTable" class="w3-table-all" v-show="MoldAssoc.length>0">
                <thead>
                <tr>
                    <th class="w3-center">圖片</th>
                    <th class="w3-center">編號</th>
                    <th class="w3-center">分支</th>
                    <!--                    <th class="w3-center">廠</th>-->
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in association" :key="index">
                    <!--        <td class="w3-center">{{ item.parent ? item.parent + " => " : "" }}{{ item.mold }}</td>-->
                    <td class="w3-center">
                        <div class="img-grid">
                            <img class="img" :src="item.image" v-if="item.image"
                                 alt="沒有圖片" @click="clickImageBox(item.image)"/>
                        </div>
                    </td>
                    <td class="w3-center tooltip">
                        {{ item.mNum }}
                        <span class="tooltip-text">{{ item.name }}</span>
                    </td>
                    <td class="w3-center">{{ item.comp }}</td>
                    <!--                    <td class="w3-center">{{ item.factory }}</td>-->
                </tr>
                </tbody>
            </table>
        </div>
        <div class="message-box" v-show="!isExisted">
            <span>查無此產品</span>
        </div>
    </div>
    <teleport to="body">
        <light-box ref="lightBoxElement" :opacity="0.5">
            <template #main>
                <div class="img-box2">
                    <img class="img" :src="imageInLightBox" alt="沒有圖片"/>
                </div>
            </template>
        </light-box>
    </teleport>
</template>

<script>
import {mapState} from "vuex";
import LightBox from "./LightBoxTrue.vue";

export default {
    name: "SearchMoldRealtime",
    components: {LightBox},
    template: "SearchMoldRealtime",
    data() {
        return {
            searchMold: '',
            association: this.MoldAssoc,
            isExisted: true,

            imageInLightBox: "",
        }
    },
    computed: {
        ...mapState({
            moldList: 'moldList',
            MoldAssoc: 'moldRelationTable',
            IsLoaded: 'productMoldAssociationTableIsLoaded',
        }),
        moldList2 () {
            return this.moldList.map(x=>x.number);
        }
    },
    methods: {
        toggleBox() {
            this.$refs.lightBoxElement.toggleBox();
        },
        clickImageBox(v) {
            if (!v) {
                return false;
            }
            this.imageInLightBox = v;
            this.toggleBox();
        },
    },
    watch: {
        searchMold() {
            this.isExisted = true;
            this.association = [];
            if (this.moldList2.includes(this.searchMold.toUpperCase())) {
                let loader = this.$loading.show({
                    container: this.$refs.loaderContainer,
                    canCancel: false,
                });
                this.$store.dispatch('fetchMoldRelation', this.searchMold)
                    .then(() => this.association = this.MoldAssoc)
                    .catch(e => console.error(e))
                    .finally(() => loader.hide());
            } else {
                this.isExisted = false;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "src/assets/defaultStyle";

.productMoldContainer {
  padding: 0 5px;
}

input {
  margin: 5px 1px;
}

table {
  position: relative;
  user-select: text;
  margin-top: 10px;
  margin-bottom: 10px;
  overflow-y: scroll;

  thead tr th, tfoot tr th {
    font-size: 0.7em;
    height: 25px;
    background-color: $table-head-background-color;
  }

  tbody {
    tr {
      //cursor: pointer;
      td {
        height: $table-row-height;
        padding: 5px 0 !important;
        vertical-align: middle;
        max-height: 40px;
        min-height: 0;

        &:nth-child(1) {
          width: 45%;
          max-width: 100px;
          overflow: hidden;
        }

        &:nth-child(2) {
          width: 40%;
        }

        &:nth-child(3) {
          width: 15%;
        }

        //&:nth-child(4) {
        //  width: 10%;
        //}

        //&:nth-child(5) {
        //  width: 10%;
        //}
      }

      &:hover td {
        background-color: #c1c1c1;
      }
    }
  }
}

.tooltip .tooltip-text {
  position: absolute;
  visibility: hidden;
  width: fit-content;
  background-color: black;
  color: #ffffff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  opacity: 0.7;
  transform: translate(5px, -5px);
}

.tooltip:hover .tooltip-text {
  visibility: visible;
}

.img-grid {
  width: 100%;
  height: 40px;
  //border: solid;
  display: flex;
  justify-content: center;
  align-items: center;

  .img {
    max-width: 100%;
    height: auto;
    max-height: 40px;
    margin: 0;
  }
}
</style>
