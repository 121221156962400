<template>
  <input class="number-input" type="number" min="1" :style="{width:width+'px'}" autocomplete="off" :ref="'input'"
         v-model="value" @keydown="onKeyDown" @change="onChange"/>
</template>

<script>
import {nextTick} from 'vue';

const KEY_BACKSPACE = 'Backspace';
const KEY_DELETE = 'Delete';
const KEY_F2 = 'F2';
const KEY_ENTER = 'Enter';
const KEY_TAB = 'Tab';

export default {
  name: "numberCellEditor",
  data() {
    return {
      value: 1,
      cancelBeforeStart: true,
      suppressCellFocus: false,
      highlightAllOnFocus: true,
    };
  },
  methods: {
    getValue() {
      return this.value;
    },

    isCancelBeforeStart() {
      return this.cancelBeforeStart;
    },

    setInitialState(params) {
      let startValue = params.value;
      let highlightAllOnFocus = true;

      if (params.key === KEY_BACKSPACE || params.key === KEY_DELETE) {
        // if backspace or delete pressed, we clear the cell
        startValue = 1;
      } else if (params.charPress) {
        // if a letter was pressed, we start with the letter
        startValue = params.charPress;
        highlightAllOnFocus = false;
      } else {
        // otherwise we start with the current value
        startValue = params.value;
        if (params.key === KEY_F2) {
          highlightAllOnFocus = false;
        }
      }

      this.value = startValue;
      this.highlightAllOnFocus = highlightAllOnFocus;
    },

    // will reject the number if it greater than 1,000,000
    // not very practical, but demonstrates the method.
    isCancelAfterEnd() {
      return this.value > 1000000;
    },

    onKeyDown(event) {
      if (this.isLeftOrRight(event) || this.deleteOrBackspace(event)) {
        event.stopPropagation();
        return;
      }
      if (this.isUp(event)){
        this.value+=1;
      }
      if (this.isDown(event) && this.value>=2){
        this.value-=1;
      }
      //阻止輸入非數字
      if (
          !this.finishedEditingPressed(event) &&
          !this.isKeyPressedNumeric(event)
      ) {
        if (event.preventDefault) event.preventDefault();
      }
    },
    onChange() {
      if (this.value < 1) {
        this.value = 1
        console.log("數量不可小於1");
      }
    },

    isCharNumeric(charStr) {
      return /\d/.test(charStr);
    },

    isKeyPressedNumeric(event) {
      const charStr = event.key;
      return this.isCharNumeric(charStr);
    },

    finishedEditingPressed(event) {
      const key = event.key;
      return key === KEY_ENTER || key === KEY_TAB;
    },

    deleteOrBackspace(event) {
      return [KEY_DELETE, KEY_BACKSPACE].indexOf(event.key) > -1;
    },

    isLeftOrRight(event) {
      return ['ArrowLeft', 'ArrowRight'].indexOf(event.key) > -1;
    },
    isUp(event) {
      return ['ArrowUp'].indexOf(event.key) > -1;
    },
    isDown(event) {
      return ['ArrowDown'].indexOf(event.key) > -1;
    },
  },

  created() {
    this.setInitialState(this.params);

    // only start edit if key pressed is a number, not a letter
    this.cancelBeforeStart =
        this.params.charPress && '1234567890'.indexOf(this.params.charPress) < 0;
    this.width = this.params.column.actualWidth;
    this.suppressCellFocus = this.params.colDef.cellEditorParams.suppressCellFocus;
    // console.log(this.params);
  },
  mounted() {
    nextTick(() => {
      if (this.suppressCellFocus) return false;
      // need to check if the input reference is still valid - if the edit was cancelled before it started there
      // wont be an editor component anymore
      if (this.$refs.input) {
        this.$refs.input.focus();
        if (this.highlightAllOnFocus) {
          this.$refs.input.select();

          this.highlightAllOnFocus = false;
        } else {
          // when we started editing, we want the caret at the end, not the start.
          // this comes into play in two scenarios:
          //   a) when user hits F2
          //   b) when user hits a printable character
          const length = this.$refs.input.value
              ? this.$refs.input.value.length
              : 0;
          if (length > 0) {
            this.$refs.input.setSelectionRange(length, length);
          }
        }

        this.$refs.input.focus();
      }
    });
  },
};

</script>

<style scoped lang="scss">
</style>
