<template>
  <div class="w3-container w3-center login-panel">
    <div class="login-row">
      <div class="login-col">
        <div>
          <span>使用者名稱</span>
        </div>
        <div>
          <input type="text" v-model="username" required/>
        </div>
      </div>
    </div>
    <div class="login-row">
      <div class="login-col">
        <div>
          <span>密碼</span>
        </div>
        <div>
          <input type="password" v-model="password" required/>
        </div>
      </div>
    </div>
    <div class="login-row">
      <button @click.prevent="userLogin" :disabled="(!username || !password)||isLoading">登入</button>
      <div>
        <input class="w3-check" type="checkbox" v-model="rememberMe"/>
        <span>記住我</span>
      </div>
    </div>
  </div>
  <div style="position: relative">
    <vue-loading v-model:active="isLoading"
                 :is-full-page="true"
                 :background-color="'#959595'"
                 :color="'#64d78b'"
                 :opacity="0.4"
                 :width="150"
                 :height="150">
    </vue-loading>
  </div>
</template>

<script>
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {mapState} from "vuex";
import router from "@/router/router";

export default {
  name: "Login",
  template: "Login",
  components: {VueLoading},
  data() {
    return {
      isLoading: false,

      username: "",
      password: "",
      keepLogin: false,
      rememberMe: false,
    }
  },
  computed: {
    ...mapState({
      isAuthenticated: "isAuthenticated",
    }),
  },
  methods: {
    userLogin() {
      this.isLoading = true;
      this.$store.dispatch('userAuthentication', {
        username: this.username,
        password: this.password,
      }).then(() => {
        if (this.isAuthenticated) {
          if (this.rememberMe){
            const keep = {
              username: this.username,
              password: this.password,
            }
            localStorage.setItem('rememberMe', JSON.stringify(keep));
          }
          this.isLoading = false;
          router.push({path: 'home'});
        }
      }).catch(e => console.error(e)).finally(() => this.isLoading = false);
    },
  },
  beforeMount() {
    const load = localStorage.getItem('rememberMe');
    if (load) {
      this.rememberMe = true;
      this.username = JSON.parse(load).username;
      this.password = JSON.parse(load).password;
    }
  },
  mounted() {
    const load = localStorage.getItem('rememberMe');
    if (load) {
      this.userLogin();
    }
  },
}
</script>

<style lang="scss" scoped>
.login-panel {
  width: 30%;
  background-color: #e3e3e3;
  font-size: 1.1em;
  margin: 25vh auto;

  .login-row {
    width: 95%;
    margin: 1em 0;

    .login-col {
      width: 100%;
      position: relative;
      display: inline-flex;

      div:nth-child(1) {
        width: 30%;

        span {
          font-weight: bold;
        }
      }

      div:nth-child(2) {
        width: 70%;

        input {
          width: 100%;
          margin: 0;
        }
      }
    }

    button {
      cursor: pointer;
      width: 5em;
      height: 3em;
      border: none;
      border-radius: 3px;
      background-color: #74ffa2;
      transition: background-color 0.2s ease, color 0.2s ease;

      &:hover {
        background-color: #42b983;
        color: white;
      }

      &:disabled {
        background-color: #3c6853;
        color: white;
      }

      & + div {
        display: inline-block;
        position: absolute;
        line-height: 50px;

        input[type=checkbox] {
          margin-left: 1em;
        }

        span {
          font-size: 0.8em;
        }
      }
    }
  }
}
</style>
