<template>
  <div v-if="options.length>0">
    <div :style="{width:width+'px', height: 26*options.length+10+'px'}" class="free-options" :ref="'container'" tabindex="0" @keydown="onKeyDown">
      <div :style="{width:width-12+'px'}" :class="{ selected: index===selectIndex, default: index!==selectIndex}"
           v-for="(option,index) in options" v-bind="option" :key="index" :tabindex="index"
           @click.prevent="onClick(option.value,index)" :data-value="option.value">{{ option.name }}
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "freeSelectEditor",
  data() {
    return {
      selectValue: null,
      selectIndex: 0,
      options: [],
      width: 0,
    }
  },
  methods: {
    getValue() {
      return this.selectValue;
    },
    isPopup() {
      return true;
    },
    setSelect(value,index) {
      this.selectValue = value;
      this.selectIndex = index;
    },
    getOptions(options) {
      this.options = options;
    },
    onClick(value,index) {
      this.setSelect(value,index);
      this.params.api.stopEditing();
      // console.log(this.selectValue,this.selectIndex)
    },
    onKeyDown(event) {
      const key = event.key;
      if (key === 'ArrowLeft' || key === 'ArrowUp') {
        event.preventDefault();
        // this.changeSelect();
        let newIndex = this.selectIndex-1>=0?this.selectIndex-1:this.options.length-1;
        this.setSelect(this.options[newIndex].value,newIndex);
        event.stopPropagation();
      } else if (key === 'ArrowDown' || key === 'ArrowRight') {
        event.preventDefault();
        let newIndex = (this.selectIndex+1<=this.options.length-1)?this.selectIndex+1:0;
        this.setSelect(this.options[newIndex].value,newIndex);
        event.stopPropagation();
      }
    },
  },
  created() {
    this.getOptions(this.params.colDef.cellEditorParams.values);
    this.setSelect(this.params.value,this.params.colDef.cellEditorParams.values.map(x=>x.value).indexOf(this.params.value));
    // console.log(this.params);
    this.width = this.params.column.actualWidth;
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.container.focus();
    })
  }
}
</script>

<style scoped lang="scss">
</style>
