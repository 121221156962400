<template>
  <div class="container w3-container w3-center">
    <div class="table-title"><span>模具文字替換表</span></div>
    <div class="button-bar">
      <button class="w3-button w3-teal w3-hover-pale-green button-bar-btn-margin" @click="saveTable"
              :disabled="!hasEdited">儲存
      </button>
      <button class="w3-button w3-yellow w3-hover-pale-yellow button-bar-btn-margin" @click="sortTable">整理</button>
      <button class="w3-button w3-blue w3-hover-pale-blue button-bar-btn-margin" @click="addNewRule">新增</button>
      <button class="w3-button w3-red w3-hover-pale-red button-bar-btn-margin" @click="deleteRule">刪除</button>
    </div>
    <ag-grid-vue style="padding-bottom: 5px"
                 class="ag-theme-alpine"
                 :localeText="localeText"
                 domLayout='autoHeight'
                 :columnDefs="columnDefs"
                 :defaultColDef="defaultColDef"
                 :columnTypes="columnTypes"
                 :rowData="mutateTable"
                 :rowDragManaged="true"
                 :rowDragMultiRow="true"
                 :animateRows="true"
                 rowSelection="multiple"
                 @grid-ready="onGridReady"
                 @first-data-rendered="onFirstDataRendered"
                 @gridSizeChanged="onGridSizeChanged"
                 @cellValueChanged="onCellValueChanged">
    </ag-grid-vue>
  </div>
  <teleport to="body">
    <notifications group="moldMutationRow" position="bottom right" :max="8" :speed="500" :ignore-duplicates="true"/>
  </teleport>
</template>

<script>
import {computed, onMounted, reactive, ref} from "vue";
import {onBeforeRouteLeave} from "vue-router"
import {useStore} from 'vuex'
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {AgGridVue} from "ag-grid-vue3";
import {notify} from '@kyvg/vue3-notification';
import booleanSelectEditor from "@/components/booleanSelectEditor";
import freeSelectEditor from "@/components/freeSelectEditor";

export default {
  name: "CastMoldMuTextTable",
  components: {AgGridVue},
  setup() {
    const store = useStore();

    //模具名稱替換預設值
    let mutateTable = ref([
          {id: 0, regex: "-combine", newText: "組合", locked: true},
        ]
    );
    let hasEdited = ref(false);
    let hasSaved = ref(true);//是否已儲存
    let columnDefs = reactive([
      {headerName: "目標", field: "regex", rowDrag: true},
      {headerName: "替換", field: "newText",},
      {
        headerName: "方式", field: "method", singleClickEdit: true,
        cellEditor: freeSelectEditor,
        cellEditorParams: {
          values: [
            {"name": "完整", "value": 0},
            {"name": "開頭", "value": 1},
            {"name": "結尾", "value": 2},
            {"name": "任意", "value": 3}],
        },
        valueFormatter: params => {
          const methods = {
            0: "完整",
            1: "開頭",
            2: "結尾",
            3: "任意",
          };
          return methods[parseInt(params.value)] ?? "無資料";
        },
      },
      {
        headerName: "鎖定", field: "locked", singleClickEdit: true,
        cellEditor: booleanSelectEditor,
        cellEditorPopup: true,
        valueFormatter: params => {
          return params.value ? "是" : "否";
        }
      },
    ]);
    let gridApi = ref(null);
    let columnApi = ref(null);

    onMounted(() => {
      const mtb = computed(() => store.state.moldTextMutationTable).value;
      if (typeof mtb === "string" || mtb !== "") {
        mutateTable.value = JSON.parse(mtb.toString().trim() + '');
      }
    });
    //檢查有沒有儲存
    onBeforeRouteLeave(() => {
      if (!hasSaved.value) {
        const answer = window.confirm('尚未儲存，確定要離開嗎?')
        if (!answer) return false;
        hasSaved.value = true;
      }
    });

    const onGridReady = params => {
      gridApi.value = params.api;
      columnApi.value = params.columnApi;
    };
    const onFirstDataRendered = params => {
      params.api.sizeColumnsToFit();
    };
    //自動調整欄位寬度
    const onGridSizeChanged = params => {
      if (params.clientWidth > 600) {
        gridApi.value.sizeColumnsToFit();
      } else {
        columnDefs.forEach(x => {
          x.width = 200;
        });
        gridApi.value.setColumnDefs(columnDefs);
      }
    };
    const onCellValueChanged = params => {
      // console.log(mutateTable.value[params.rowIndex][params.colDef.field]);
      mutateTable.value[params.rowIndex][params.colDef.field] = params.newValue;
      hasSaved.value = false;
      hasEdited.value = true;
    };
    //儲存變換表格
    const saveTable = () => {
      // console.log(mutateTable.value);
      // console.log(JSON.stringify(mutateTable.value));
      hasEdited.value = false;
      const load = localStorage.getItem('rememberMe');
      const rowData = getRowData();
      mutateTable.value = rowData;
      const payload = {username: JSON.parse(load).username, str: JSON.stringify(rowData)};
      store.dispatch('updateMoldTextMutationTable', payload).then(() => {
        notify({
          group: "moldMutationRow",
          title: "成功儲存。",
          type: "success",
        });
        hasSaved.value = true;
      }).catch(e => {
        notify({
          group: "moldMutationRow",
          title: "儲存失敗，請檢察後再嘗試。",
          type: "error",
        });
        console.error(e);
        hasEdited.value = true;
      });
    };
    //新增一條規則到變換表格
    const addNewRule = () => {
      const maxID = Math.max(...mutateTable.value.map(x => x.id));
      let rowData = getRowData();
      rowData.push({id: maxID, regex: "要替換的編號或文字", newText: "替換成什麼文字", locked: false});
      mutateTable.value = rowData;
      gridApi.value.setRowData(rowData);
      hasSaved.value = false;
      hasEdited.value = true;
    };
    //刪除所選的規則
    const deleteRule = () => {
      const selectedNodes = gridApi.value.getSelectedNodes();
      if (selectedNodes.length === 0) return false;
      const selectedData = selectedNodes.map(node => node.rowIndex).reverse();
      // console.log(selectedData);
      selectedData.forEach(x => {
        const isLocked = mutateTable.value[x].locked;
        if (!isLocked) {
          mutateTable.value.splice(x, 1);
          hasSaved.value = false;
          hasEdited.value = true;
        } else {
          notify({
            group: "moldMutationRow",
            title: "無法刪除鎖定的項目。",
            type: "error",
          });
        }
      });
      gridApi.value.setRowData(mutateTable.value);
    };
    const sortTable = () => {
      const collator = new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: "base"
      });
      gridApi.value.setRowData(mutateTable.value.sort((a, b) => collator.compare(a.regex, b.regex)));
      notify({
        group: "moldMutationRow",
        title: "已重新排序。",
        type: "success",
      });
      hasSaved.value = false;
      hasEdited.value = true;
    };
    //回傳當前表格呈現的data狀態與順序
    const getRowData = () => {
      let arr = [];
      gridApi.value.forEachNode((rowNode) => {
        arr.push(rowNode.data);
      });
      return JSON.parse(JSON.stringify(arr));
    };

    //////導出
    return {
      //全局變數
      localeText: computed(() => store.state.AG_GRID_LOCALE_CH),
      mutateTable,
      hasEdited,
      moldTextMutationTableIsLoaded: computed(() => store.state.moldTextMutationTableIsLoaded),
      columnTypes: {
        rightAligned: {
          headerClass: 'ag-right-aligned-header',
          cellClass: 'ag-right-aligned-cell'
        },
      },
      columnDefs,
      defaultColDef: {
        editable: true,
        filter: true,
        sortable: true,
        lockPosition: true,
      },
      onGridReady,
      onFirstDataRendered,
      onGridSizeChanged,
      onCellValueChanged,
      saveTable,
      addNewRule,
      deleteRule,
      sortTable,
    };
  },
}
</script>

<style scoped lang="scss">
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";

.container {
  width: 90%;
  margin: 0 auto;

  .button-bar-btn-margin {
    margin: 2px 5px;
  }
}

.table-title {
  margin: 20px auto 0;
  font-size: 2em;
}

::v-deep(.ag-header-cell-label) {
  justify-content: center !important;
}
</style>
<style lang="scss">
.boolean-options {
  height: 40px;
  border-radius: 5px;
  border: 1px solid grey;
  background-color: #eeeeee;
  padding: 5px;
  text-align: center;
  display: inline-flex;
  outline: none;

  .selected {
    border-radius: 2px;
    padding: 4px;
    background-color: #b8b8b8;
    cursor: pointer;

    &:hover {
      background-color: #d9d9d9;
      -webkit-transition: background-color 0.3s ease;
      -ms-transition: background-color 0.3s ease;
      transition: background-color 0.3s ease;
    }
  }

  .default {
    border-radius: 2px;
    padding: 4px;
    background-color: #eeeeee;
    cursor: pointer;

    &:hover {
      background-color: #d9d9d9;
      -webkit-transition: background-color 0.3s ease;
      -ms-transition: background-color 0.3s ease;
      transition: background-color 0.3s ease;
    }
  }
}

.free-options {
  //height: 40px;
  border-radius: 5px;
  border: 1px solid grey;
  background-color: #eeeeee;
  padding: 5px;
  text-align: center;
  //display: inline-flex;
  outline: none;

  .selected {
    border-radius: 2px;
    padding: 4px;
    background-color: #b8b8b8;
    cursor: pointer;

    &:hover {
      background-color: #d9d9d9;
      -webkit-transition: background-color 0.3s ease;
      -ms-transition: background-color 0.3s ease;
      transition: background-color 0.3s ease;
    }
  }

  .default {
    border-radius: 2px;
    padding: 4px;
    background-color: #eeeeee;
    cursor: pointer;

    &:hover {
      background-color: #d9d9d9;
      -webkit-transition: background-color 0.3s ease;
      -ms-transition: background-color 0.3s ease;
      transition: background-color 0.3s ease;
    }
  }
}
</style>
