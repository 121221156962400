<template>
    <div class="container w3-container w3-center">
        <div class="left-panel">
            <div class="search-box">
                <div class="button-bar">
                    <button class="w3-button w3-teal w3-hover-pale-green button-bar-btn-margin"
                            :disabled="productDetailVar.isEditing || moldDemandVar.isEditing" @click="submitSearch">查詢
                    </button>
                    <button class="w3-button w3-brown w3-hover-pale-red button-bar-btn-margin"
                            :disabled="productDetailVar.isEditing || moldDemandVar.isEditing" @click="cleanForm">清空
                    </button>
                </div>
                <FormKit type="form" id="searchForm" autocomplete="off" v-model="formData" :disabled="false"
                         @submit="()=>{return false}">
                    <FormKit type="text" name="number" label="產品編號" placeholder="例：SE001、SUYA05"
                             autofocus></FormKit>
                    <FormKit type="text" name="name" label="產品名稱" placeholder="例：京都美人"></FormKit>
                    <FormKit type="select" name="class" label="金類" :options="classOptions2"></FormKit>
                    <FormKit type="select" name="series" label="系列" :options="seriesOptions2"></FormKit>
                    <FormKit type="select" name="jewelry" label="主要寶石" :options="jewelryOptions"></FormKit>
                    <FormKit type="select" name="type" label="產品種類" :options="typeOptions"></FormKit>
                </FormKit>
            </div>
            <div class="search-result-table" v-if="true">
                <ag-grid-vue style="height: 540px; padding: 5px 5px 5px 0;"
                             class="ag-theme-alpine"
                             :localeText="localeText"
                             domLayout="normal"
                             :columnDefs="columnDefs"
                             :defaultColDef="defaultColDef"
                             :columnTypes="columnTypes"
                             :rowData="rowData"
                             rowSelection="single"
                             :suppressRowClickSelection="productDetailVar.isEditing"
                             :suppressCellFocus="productDetailVar.isEditing"
                             @grid-ready="onGridReady"
                             @first-data-rendered="onFirstDataRendered"
                             @gridSizeChanged="onGridSizeChanged"
                             @row-clicked="onRowClicked">
                </ag-grid-vue>
            </div>
        </div>
        <div class="right-panel">
            <div class="product-detail-table">
                <div class="button-bar">
                    <button class="w3-button w3-green w3-hover-pale-green button-bar-btn-margin"
                            @click="createNewProduct"
                            :disabled="btDisableCtrl.add">新增
                    </button>
                    <button class="w3-button button-bar-btn-margin" :class="[editSaveStyle]" @click="modification"
                            :disabled="btDisableCtrl.edit">
                        {{ productDetailVar.isEditing ? "儲存" : "編輯" }}
                    </button>
                    <button class="w3-button w3-cyan w3-hover-pale-blue button-bar-btn-margin" @click="copyProduct"
                            :disabled="btDisableCtrl.copy">
                        複製
                    </button>
                    <button class="w3-button w3-blue-grey w3-hover-pale-blue button-bar-btn-margin"
                            @click="cancellation"
                            :disabled="btDisableCtrl.cancel">取消
                    </button>
                    <button class="w3-button w3-red w3-hover-pale-red button-bar-btn-margin" @click="deletion"
                            :disabled="btDisableCtrl.delete">
                        刪除
                    </button>
                </div>
                <FormKit type="form" autocomplete="off" v-model="formData2" :disabled="!productDetailVar.isEditing"
                         @submit="()=>{return false}">
                    <div class="w3-row">
                        <div class="w3-col m4 l3">
                            <div class="img-box">
                                <img class="img" :src="formData2.image" v-if="formData2.image"
                                     alt="沒有圖片" @click="clickImageBox"/>
                            </div>
                        </div>
                        <div class="w3-col m8 l9">
                            <div class="triple">
                                <FormKit type="text" name="number" label="產品編號" style="text-transform: uppercase;"
                                         oninput="this.value = this.value.toUpperCase()"
                                         validation="required|(500)productChecker|productRuler"
                                         :validation-rules="{productChecker,productRuler}"
                                         validation-visibility="live"
                                         :validation-messages="{required:'必填',productChecker:'該編號已存在',productRuler:'該編號不合規定'}"></FormKit>
                                <FormKit type="text" name="name" label="產品名稱"></FormKit>
                                <FormKit type="select" name="series" label="系列" :options="seriesOptions"
                                         validation="required"></FormKit>
                            </div>
                            <div class="triple">
                                <FormKit type="select" name="class" label="金類" :options="classOptions"
                                         validation="required"></FormKit>
                                <FormKit type="select" name="jewelry" label="主要寶石"
                                         :options="jewelryOptions"></FormKit>
                                <FormKit type="select" name="type" label="產品種類" :options="typeOptions"></FormKit>
                            </div>
                        </div>
                    </div>
                </FormKit>
            </div>
            <hr/>
            <div class="mold-demand-table" v-if="true">
                <div class="button-bar">
                    <button class="w3-button w3-green w3-hover-pale-green button-bar-btn-margin" @click="addMoldDemand"
                            :disabled="btDisableCtrl2.add">新增
                    </button>
                    <button class="w3-button button-bar-btn-margin" :class="[editSaveStyle2]" @click="modification2"
                            :disabled="btDisableCtrl2.edit">
                        {{ moldDemandVar.isEditing ? "儲存" : "編輯" }}
                    </button>
                    <button class="w3-button w3-blue-grey w3-hover-pale-blue button-bar-btn-margin"
                            @click="cancellation2"
                            :disabled="btDisableCtrl2.cancel">取消
                    </button>
                    <button class="w3-button w3-red w3-hover-pale-red button-bar-btn-margin" @click="deletion2"
                            :disabled="btDisableCtrl2.delete">
                        刪除
                    </button>
                </div>
                <ag-grid-vue style="height: 395px; padding: 5px 5px 5px 0;"
                             class="ag-theme-alpine"
                             :localeText="localeText"
                             domLayout="normal"
                             :columnDefs="columnDefs2"
                             :defaultColDef="defaultColDef2"
                             :columnTypes="columnTypes2"
                             :rowData="rowData2"
                             rowSelection="single"
                             :suppressRowClickSelection="moldDemandVar.isEditing"
                             :suppressCellFocus="moldDemandVar.isEditing"
                             :suppressClickEdit="true"
                             :editType="'fullRow'"
                             :getRowId="getRowId2"
                             @grid-ready="onGridReady2"
                             @first-data-rendered="onFirstDataRendered2"
                             @gridSizeChanged="onGridSizeChanged2"
                             @row-clicked="onRowClicked2" @row-editing-stopped="onRowEditingStopped2">
                </ag-grid-vue>
            </div>
        </div>
    </div>
    <teleport to="body">
        <notifications group="productData" position="bottom right" :max="8" :speed="500" :ignore-duplicates="true"/>
        <light-box ref="lightBoxElement" :opacity="0.5">
            <template #main>
                <div class="img-box2">
                    <img class="img" :src="imageInLightBox" alt="沒有圖片"/>
                </div>
            </template>
        </light-box>
    </teleport>
</template>

<script>
import {computed, onMounted, reactive, ref} from "vue";
import {useStore} from 'vuex';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "@formkit/themes/dist/genesis/theme.css";
import {AgGridVue} from "ag-grid-vue3";
import {onBeforeRouteLeave} from "vue-router";
import FormKit from "@formkit/vue";
import numberCellEditor from "../components/numberCellEditor";
import autoCompleteEditor from "../components/autoCompleteEditor";
import {notify} from "@kyvg/vue3-notification";
import imageGrid from "../components/imageGrid.vue";
import LightBox from "../components/LightBoxTrue.vue";

export default {
    name: "ProductData",
    components: {LightBox, FormKit, AgGridVue},//Dropdown
    setup() {
        const store = useStore();

        ////////////////////////////////////////////產品搜尋框////////////////////////////////////////////
        const formData = ref({});
        const submitSearch = () => {
            if (productDetailVar.value.isEditing) return false;
            const fD = JSON.parse(JSON.stringify(formData.value));
            if (fD.number === undefined && fD.name === undefined && fD.class === '' && fD.series === '' && fD.jewelry === '' && fD.type === '') {
                notify({
                    group: "productData",
                    title: "搜尋條件不可為空",
                    type: "error",
                });
                return false;
            }
            if (fD.number === undefined) fD.number = '';
            if (fD.name === undefined) fD.name = '';
            //發送查詢請求
            store.dispatch('fetchProductDataSearchList', fD).then(() => {
                gridApi.value.setRowData(store.state.productDataSearchList);
            }).catch(e => {
                notify({
                    group: "productData",
                    title: "查詢失敗，請稍後再試或連繫管理員",
                    type: "error",
                });
                console.error(e);
            });
        };
        const cleanForm = () => {
            if (productDetailVar.value.isEditing) return false;
            formData.value = {};
        }

        ////////////////////////////////////////////產品搜尋結果////////////////////////////////////////////
        let rowData = ref([]);
        let columnDefs = reactive([
            {headerName: "產品ID", field: "id", editable: false, hide: true},
            {headerName: "搜尋結果", field: "number", editable: false}
        ]);
        const lastClickedRow = ref({});

        let gridApi = ref(null);
        let columnApi = ref(null);
        const onGridReady = params => {
            gridApi.value = params.api;
            columnApi.value = params.columnApi;
        };
        const onFirstDataRendered = params => {
            params.api.sizeColumnsToFit();
        };
        const onGridSizeChanged = () => {
            gridApi.value.sizeColumnsToFit();
        };
        const onRowClicked = params => {
            //若正在編輯，停止查詢
            if (productDetailVar.value.isEditing || moldDemandVar.value.isEditing) return false;
            //檢查清單狀態，判定所選編號是否相同，若通過，透過axios發送請求查詢產品資料，再送至右側表格formData2
            if (Object.keys(lastClickedRow.value).length === 0 || lastClickedRow.value.id !== params.data.id) {
                const targetId = params.data.id;
                store.dispatch('ctrlProductData', {"method": "r", "data": targetId}).then(() => {
                    lastClickedRow.value = JSON.parse(JSON.stringify(params.data));
                    formData2.value = store.state.productData;
                    gridApi2.value.setRowData(store.state.productDataMold);
                    rowData2.value = store.state.productDataMold;
                    moldDemandVar.value.selectedRow = -1;
                    moldDemandVar.value.oldValue = {};
                }).catch(e => {
                    notify({
                        group: "productData",
                        title: "獲取資料失敗，請稍後再試或連繫管理員",
                        type: "error",
                    });
                    console.error(e);
                });
            } else if (lastClickedRow.value.id === params.data.id) {
                return false;
            }
        };

        ////////////////////////////////////////////產品資料區////////////////////////////////////////////
        const formData2 = ref({
            "id": -1,
            "number": "",
            "name": "",
            "class": "黃金",
            "series": "西施",
            "jewelry": "",
            "type": "",
            "image": "",
        });
        let productDetailVar = ref({
            "isEditing": false,
            "isErrorItem": computed(() => formData2.value.id < 0 || formData2.value.id === null || formData2.value.id === ''),
            "tempData": {},
            "isCreateCopy": false,
        });
        //按鈕disable屬性控制器，true時按鈕不可按
        const btDisableCtrl = computed(() => {
            return {
                "add": moldDemandVar.value.isEditing || productDetailVar.value.isEditing,
                "edit": moldDemandVar.value.isEditing || productDetailVar.value.isErrorItem || (Object.keys(lastClickedRow.value).length === 0 && !productDetailVar.value.isEditing),
                "copy": moldDemandVar.value.isEditing || productDetailVar.value.isEditing || productDetailVar.value.isErrorItem || Object.keys(lastClickedRow.value).length === 0,
                "cancel": moldDemandVar.value.isEditing || !productDetailVar.value.isEditing || productDetailVar.value.isErrorItem,
                "delete": moldDemandVar.value.isEditing || productDetailVar.value.isEditing || productDetailVar.value.isErrorItem || Object.keys(lastClickedRow.value).length === 0,
            }
        });
        //資料CRUD，動作代碼: 修改 mod，新增 add，複製 copy，刪除 del
        //編輯狀態切換
        const editSave = () => {
            productDetailVar.value.isEditing = !productDetailVar.value.isEditing;
        };
        //編輯按鈕樣式切換
        const editSaveStyle = computed(() => {
            return {
                'w3-amber': !productDetailVar.value.isEditing,
                'w3-hover-pale-yellow': !productDetailVar.value.isEditing,
                'w3-deep-orange': productDetailVar.value.isEditing,
                'w3-hover-pale-red': productDetailVar.value.isEditing,
            }
        });
        //表單規則-檢查產品編號是否存在
        const productChecker = ({value}) => {
            return !store.state.productList.includes(value) || (value === lastClickedRow.value.number && !productDetailVar.value.isCreateCopy);
        };
        const productRuler = ({value}) => {
            const regex = /^[A-Z]+[0-9]{3,}(?:-[A-Z]+)*$|^[A-Z]+[0-9]{3,}$/;
            return typeof value === "string" ? regex.test(value) : false;
        };
        //動作管理器
        const modification = () => {
            //修改動作
            if (!productDetailVar.value.isEditing) {
                //開始編輯
                editSave();
                productDetailVar.value.tempData = JSON.parse(JSON.stringify(formData2.value));
            } else {
                //儲存，先區分是原產品編輯，還是新增/複製產品儲存(new row)
                if (productDetailVar.value.isCreateCopy) {
                    if (formData2.value.number === "" || store.state.productList.includes(formData2.value.number)) {
                        window.alert("產編設定有誤，請再次確認。")
                        return false;
                    }
                    store.dispatch('ctrlProductData', {
                        "method": "c",
                        "data": formData2.value,//****
                    }).then(() => {
                        gridApi.value.setRowData(store.state.productDataSearchList);
                        gridApi2.value.setRowData([]);
                        productDetailVar.value.isCreateCopy = false;
                        lastClickedRow.value = {"id": formData2.value.id, "number": formData2.value.number};
                        editSave();
                        notify({
                            group: "productData",
                            title: "成功新增產品資料",
                            type: "success",
                        });
                    }).catch(e => {
                        notify({
                            group: "productData",
                            title: "獲取資料失敗，請稍後再試或連繫管理員",
                            type: "error",
                        });
                        console.error(e);
                    });
                } else {
                    if (formData2.value.number === "" || (store.state.productList.includes(formData2.value.number) && formData2.value.number !== lastClickedRow.value.number)) {
                        window.alert("產編設定有誤，請再次確認。")
                        return false;
                    }
                    store.dispatch('ctrlProductData', {
                        "method": "u",
                        "data": JSON.parse(JSON.stringify(formData2.value)),
                        "data2": JSON.parse(JSON.stringify(productDetailVar.value.tempData)),
                        "moldIdSet": rowData2.value.map(x => x.id)
                    }).then(() => {
                        gridApi.value.setRowData(store.state.productDataSearchList);
                        editSave();
                        notify({
                            group: "productData",
                            title: "成功更新產品資料",
                            type: "success",
                        });
                    }).catch(e => {
                        notify({
                            group: "productData",
                            title: "獲取資料失敗，請稍後再試或連繫管理員",
                            type: "error",
                        });
                        console.error(e);
                    });
                }
            }
        };
        //新增動作
        const createNewProduct = () => {
            if (productDetailVar.value.isEditing) return false;
            //先從伺服器抓取最新的未使用id
            store.dispatch('ctrlProductData', {
                "method": "getNewId",
                "data": [],
            }).then(() => {
                let unusedID = store.state.productDataNewId;
                //開始新增
                editSave();
                productDetailVar.value.isCreateCopy = true;
                productDetailVar.value.tempData = JSON.parse(JSON.stringify(formData2.value));
                formData2.value = {
                    "id": unusedID,
                    "number": "",
                    "class": "黃金",
                    "name": "",
                    "series": "西施",
                    "jewelry": "",
                    "type": "",
                };
            }).catch(e => {
                notify({
                    group: "productData",
                    title: "獲取資料失敗，請稍後再試或連繫管理員",
                    type: "error",
                });
                console.error(e);
            });
        };
        //複製動作
        const copyProduct = () => {
            if (Object.keys(lastClickedRow.value).length === 0 || productDetailVar.value.isEditing) return false;
            //先從伺服器抓取最新的未使用id
            store.dispatch('ctrlProductData', {
                "method": "getNewId",
                "data": [],
            }).then(() => {
                let unusedID = store.state.productDataNewId;
                editSave();
                productDetailVar.value.isCreateCopy = true;
                productDetailVar.value.tempData = JSON.parse(JSON.stringify(formData2.value));
                formData2.value = JSON.parse(JSON.stringify(formData2.value));
                formData2.value.id = unusedID;
            }).catch(e => {
                notify({
                    group: "productData",
                    title: "獲取資料失敗，請稍後再試或連繫管理員",
                    type: "error",
                });
                console.error(e);
            });
        };
        //取消動作
        const cancellation = () => {
            if (!productDetailVar.value.isEditing) return false;
            productDetailVar.value.isCreateCopy = false;
            editSave();
            formData2.value = JSON.parse(JSON.stringify(productDetailVar.value.tempData));
        };
        //刪除動作***********
        const deletion = () => {
            if (productDetailVar.value.isEditing) return false;
            let moldIdSet = rowData2.value.map(x => x.id);
            if (!window.confirm(`確定要刪除 ${formData2.value.number} ${moldIdSet.length > 0 ? "及" + moldIdSet.length + "筆模具設定嗎?" : "嗎?"}`)) {
                return false
            }
            store.dispatch('ctrlProductData', {
                "method": "d",
                "data": formData2.value.id,
                "moldIdSet": rowData2.value.map(x => x.id),
            }).then(() => {
                formData2.value = {
                    "id": -1,
                    "number": "",
                    "name": "",
                    "class": "黃金",
                    "series": "西施",
                    "jewelry": "",
                    "type": "",
                };
                gridApi.value.setRowData(store.state.productDataSearchList);
                gridApi2.value.setRowData([]);
                moldDemandVar.value.selectedRow = -1;
                notify({
                    group: "productData",
                    title: "成功刪除資料",
                    type: "success",
                });
            }).catch(e => {
                notify({
                    group: "productData",
                    title: "獲取資料失敗，請稍後再試或連繫管理員",
                    type: "error",
                });
                console.error(e);
            });
        }

        ////////////////////////////////////////////模具需求設定////////////////////////////////////////////
        let rowData2 = ref([]);
        let columnDefs2 = reactive([
            {headerName: "模具ID", field: "id", editable: false, hide: true},
            {
                headerName: "模具編號", field: "number", suppressKeyboardEvent: () => {
                    return true
                }, cellEditor: autoCompleteEditor,
                cellEditorParams: {
                    'options': [],
                    'maxOptions': 8,
                },
            },
            {
                headerName: "模具名稱", field: "name", editable: false, valueGetter: params => {
                    if (params.data.number) {
                        if (columnDefs2[1].cellEditorParams.options.find(opt => opt.number === params.data.number) !== undefined) {
                            return columnDefs2[1].cellEditorParams.options.find(opt => opt.number === params.data.number).name
                        } else {
                            return ""
                        }
                    } else {
                        return ""
                    }
                    // return params.data.number?columnDefs2[1].cellEditorParams.options.find(opt => opt.number === params.data.number).name:""
                },
            },
            {
                headerName: "需要數量", field: "demands", suppressKeyboardEvent: () => {
                    return true
                }, cellEditor: numberCellEditor,
                cellEditorParams: {
                    'suppressCellFocus': true,
                }
            },
            {
                headerName: "模具圖片", field: "image", editable: false, suppressKeyboardEvent: () => {
                    return true
                }, cellRenderer: imageGrid, onCellClicked: (e) => {
                    if (!e.value) {
                        return false;
                    }
                    imageInLightBox.value = e.value;
                    toggleBox();
                },
            },
        ]);
        let gridApi2 = ref(null);
        let columnApi2 = ref(null);
        const getRowId2 = params => {
            return params.data.id
        };
        const onGridReady2 = params => {
            gridApi2.value = params.api;
            columnApi2.value = params.columnApi;
        };
        const onFirstDataRendered2 = params => {
            setTimeout(() => {
                params.api.sizeColumnsToFit();
            }, 0);
        };
        const onGridSizeChanged2 = () => {
            setTimeout(() => {
                gridApi2.value.sizeColumnsToFit();
            }, 0);
        };
        const onRowClicked2 = params => {
            if (moldDemandVar.value.isEditing) return false;
            moldDemandVar.value.selectedRow = params.rowIndex;
            moldDemandVar.value.oldValue = JSON.parse(JSON.stringify(params.data));
        };
        //當還在編輯卻誤點到其它row時，會再次開啟編輯
        const onRowEditingStopped2 = params => {
            if (moldDemandVar.value.isEditing) {
                gridApi2.value.startEditingCell({
                    rowIndex: params.rowIndex,
                    colKey: 'number',
                })
            }
        };

        let moldDemandVar = ref({
            "hasLoaded": true,
            "isEditing": false,
            "isAdding": false,
            "selectedRow": -1,
            "oldValue": {},
            "newValue": {},
            "options": [],
        });
        const getRowData2 = () => {
            let arr = [];
            gridApi2.value.forEachNode((rowNode) => {
                arr.push(rowNode.data);
            });
            return JSON.parse(JSON.stringify(arr));
        };
        //按鈕disable屬性控制器，true時按鈕不可按
        const btDisableCtrl2 = computed(() => {
            return {
                "add": productDetailVar.value.isEditing || moldDemandVar.value.isEditing || formData2.value.id < 1,
                "edit": productDetailVar.value.isEditing || rowData2.value.length < 1 || moldDemandVar.value.selectedRow < 0,
                "cancel": productDetailVar.value.isEditing || rowData2.value.length < 1 || moldDemandVar.value.selectedRow < 0 || !moldDemandVar.value.isEditing,
                "delete": productDetailVar.value.isEditing || rowData2.value.length < 1 || moldDemandVar.value.selectedRow < 0 || moldDemandVar.value.isEditing,
            }
        });
        //編輯狀態切換
        const editSave2 = () => {
            moldDemandVar.value.isEditing = !moldDemandVar.value.isEditing;
        };
        //編輯按鈕樣式切換
        const editSaveStyle2 = computed(() => {
            return {
                'w3-amber': !moldDemandVar.value.isEditing,
                'w3-hover-pale-yellow': !moldDemandVar.value.isEditing,
                'w3-deep-orange': moldDemandVar.value.isEditing,
                'w3-hover-pale-red': moldDemandVar.value.isEditing,
            }
        });
        //編輯儲存動作
        const modification2 = () => {
            if (moldDemandVar.value.selectedRow < 0) return false;
            if (!moldDemandVar.value.isEditing) {
                //開始編輯
                editSave2();
                moldDemandVar.value.oldValue = JSON.parse(JSON.stringify(gridApi2.value.getSelectedRows()[0]));
                gridApi2.value.startEditingCell({
                    rowIndex: moldDemandVar.value.selectedRow,
                    colKey: 'number',
                })
                moldDemandVar.value.isAdding = false;
                rowData2.value = getRowData2();
            } else {
                //結束並儲存，客戶端先檢查模具編號是否可用
                gridApi2.value.stopEditing();
                const newData = gridApi2.value.getSelectedRows()[0];
                //沒有改動，直接結束編輯，不上傳資料
                if (moldDemandVar.value.oldValue.number === newData.number && moldDemandVar.value.oldValue.demands === newData.demands) {
                    moldDemandVar.value.isAdding = false;
                    editSave2();
                    notify({
                        group: "productData",
                        title: "沒有任何修改",
                        type: "success",
                    });
                    return false;
                }
                const moldList = computed(() => store.state.moldList).value;
                //檢查輸入的模具編號是否存在，若無，停止儲存動作，但不會結束編輯
                if (moldList.length < 1 || moldList.findIndex(x => x.number === newData.number) < 0) {
                    notify({
                        group: "productData",
                        title: "模具編號設定錯誤，請再次檢查",
                        type: "error",
                    });
                    return false
                }
                //伺服器處理************
                store.dispatch("ctrlProductDataMoldDemands", {
                    "method": "u",
                    "data": {
                        "id": newData.id,
                        "pNum": formData2.value.number,
                        "mNum": newData.number,
                        "demands": newData.demands,
                    },
                }).then(() => {
                    //成功，更新客戶端資料
                    moldDemandVar.value.oldValue = gridApi2.value.getDisplayedRowAtIndex(moldDemandVar.value.selectedRow).data;
                    moldDemandVar.value.isAdding = false;
                    rowData2.value = getRowData2();
                    gridApi2.value.stopEditing();
                    editSave2();
                    notify({
                        group: "productData",
                        title: "成功更新模具需求",
                        type: "success",
                    });
                }).catch(e => {
                    notify({
                        group: "productData",
                        title: "獲取資料失敗，請稍後再試或連繫管理員",
                        type: "error",
                    });
                    console.error(e);
                });
            }
        };
        //新增動作
        const addMoldDemand = () => {
            if (moldDemandVar.value.isEditing) return false;
            //跟伺服器請求最新尚未使用的product_mold_association ID
            store.dispatch("ctrlProductDataMoldDemands", {
                "method": "getNewId",
                "data": []
            }).then(() => {
                let unusedID = store.state.productDataMoldDemandNewId;
                //開始新增
                if (unusedID > -1) {
                    editSave2();
                    const newRowData = {
                        "id": unusedID,
                        "number": "",
                        "name": "",
                        "demands": 1
                    };
                    const res = gridApi2.value.applyTransaction({add: [newRowData], addIndex: undefined});
                    moldDemandVar.value.newValue = newRowData;
                    res.add[0].setSelected(true, true);
                    gridApi2.value.startEditingCell({
                        rowIndex: res.add[0].rowIndex,
                        colKey: 'number',
                    })
                    moldDemandVar.value.selectedRow = res.add[0].rowIndex;
                    moldDemandVar.value.isAdding = true;
                    rowData2.value = getRowData2();
                } else {
                    notify({
                        group: "productData",
                        title: "獲取資料失敗，請稍後再試或連繫管理員",
                        type: "error",
                    });
                }
            }).catch(e => {
                notify({
                    group: "productData",
                    title: "獲取資料失敗，請稍後再試或連繫管理員",
                    type: "error",
                });
                console.error(e);
            });
        };
        //取消動作
        const cancellation2 = () => {
            if (!moldDemandVar.value.isEditing) return false;
            editSave2();
            gridApi2.value.stopEditing();
            //新增時取消
            if (moldDemandVar.value.isAdding) {
                moldDemandVar.value.isAdding = false;
                gridApi2.value.applyTransaction({remove: [moldDemandVar.value.newValue]});
                moldDemandVar.value.selectedRow = -1;
                rowData2.value = getRowData2();
            } else {
                //編輯時取消
                let targetRow = gridApi2.value.getDisplayedRowAtIndex(moldDemandVar.value.selectedRow);
                if (targetRow) {
                    gridApi2.value.applyTransaction({update: [moldDemandVar.value.oldValue]});
                }
            }

        };
        //刪除動作
        const deletion2 = () => {
            if (moldDemandVar.value.isEditing) return false;
            const selectedRow = gridApi2.value.getSelectedRows()[0];
            if (!window.confirm(`確定要刪除 ${selectedRow.number} 嗎?`)) {
                return false
            }
            store.dispatch("ctrlProductDataMoldDemands", {
                "method": "d",
                "data": selectedRow.id,
            }).then(() => {
                gridApi2.value.applyTransaction({remove: [selectedRow]});
                moldDemandVar.value.selectedRow = -1;
                rowData2.value = getRowData2();
                notify({
                    group: "productData",
                    title: "成功刪除模具設定",
                    type: "success",
                });
            }).catch(e => {
                notify({
                    group: "productData",
                    title: "獲取資料失敗，請稍後再試或連繫管理員",
                    type: "error",
                });
                console.error(e);
            });
        }
        //掛載後匯入模具資訊
        onMounted(() => {
            const getMoldList = computed(() => store.getters.moldListNotParent).value
            columnDefs2[columnDefs2.findIndex(x => x.field === "number")].cellEditorParams.options = getMoldList;
            moldDemandVar.value.options = getMoldList;
        });
        //檢查有沒有儲存
        onBeforeRouteLeave(() => {
            if (productDetailVar.value.isEditing) {
                if (!window.confirm("正在編輯資料中，若現在離開本頁面將遺失尚未儲存的資料，確定要離開嗎?")) {
                    return false
                }
            }
        });

        const imageInLightBox = ref("");
        const lightBoxElement = ref(null);
        const toggleBox = () => {
            lightBoxElement.value.toggleBox();
        };
        const clickImageBox = () => {
            if (!formData2.value.image) {
                return false;
            }
            imageInLightBox.value = formData2.value.image;
            toggleBox();
        };

        return {
            /////全局變數/////
            localeText: computed(() => store.state.AG_GRID_LOCALE_CH),
            classOptions: [
                {label: "黃金", value: "黃金"},
                {label: "銀", value: "銀"},
                {label: "銅", value: "銅"},
            ],
            classOptions2: [
                {label: "", value: ""},
                {label: "黃金", value: "黃金"},
                {label: "銀", value: "銀"},
                {label: "銅", value: "銅"},
            ],
            seriesOptions: [
                {label: "西施", value: "西施"},
                {label: "西施隱藏版", value: "西施隱藏版"},
                {label: "詩雅", value: "詩雅"},
                {label: "詩雅隱藏版", value: "詩雅隱藏版"},
                {label: "水火", value: "水火"},
                {label: "水火隱藏版", value: "水火隱藏版"},
                {label: "其它", value: "其它"},
            ],
            seriesOptions2: [
                {label: "", value: ""},
                {label: "西施", value: "西施"},
                {label: "西施隱藏版", value: "西施隱藏版"},
                {label: "詩雅", value: "詩雅"},
                {label: "詩雅隱藏版", value: "詩雅隱藏版"},
                {label: "水火", value: "水火"},
                {label: "水火隱藏版", value: "水火隱藏版"},
                {label: "其它", value: "其它"},
            ],
            typeOptions: [
                {label: "", value: ""},
                {label: "手鍊", value: "手鍊"},
                {label: "項鍊", value: "項鍊"},
                {label: "腳鍊", value: "腳鍊"},
                {label: "墜子", value: "墜子"},
                {label: "戒指", value: "戒指"},
                {label: "耳環", value: "耳環"},
                {label: "吊飾", value: "吊飾"},
                {label: "配件", value: "配件"},
                {label: "手環", value: "手環"},
                {label: "後束", value: "後束"},
                {label: "螺絲", value: "螺絲"},
                {label: "胸針", value: "胸針"},
                {label: "擺件", value: "擺件"},
            ],
            jewelryOptions: [
                {label: "", value: ""},
                {label: "鋯石", value: "鋯石"},
                {label: "水晶", value: "水晶"},
                {label: "水晶珍珠", value: "水晶珍珠"},
                {label: "養殖珍珠", value: "養殖珍珠"},
                {label: "雷射玉", value: "雷射玉"},
                {label: "玉石", value: "玉石"},
                {label: "剛玉", value: "剛玉"},
                {label: "尖晶石", value: "尖晶石"},
                {label: "紫水晶", value: "紫水晶"},
                {label: "虎眼石", value: "虎眼石"},
                {label: "藍砂石", value: "藍砂石"},
                {label: "黑膽石", value: "黑膽石"},
                {label: "瑪瑙", value: "瑪瑙"},
                {label: "土耳其石", value: "土耳其石"},
                {label: "貓眼石", value: "貓眼石"},
            ],

            /////搜尋區/////
            formData,
            submitSearch,
            cleanForm,

            /////產品搜尋結果/////
            columnDefs,
            defaultColDef: {
                editable: false,
                filter: false,
                sortable: false,
                lockPosition: true,
            },
            columnTypes: {
                rightAligned: {
                    headerClass: 'ag-right-aligned-header',
                    cellClass: 'ag-right-aligned-cell'
                },
            },
            rowData,
            onGridReady,
            onFirstDataRendered,
            onGridSizeChanged,
            onRowClicked,
            lastClickedRow,

            /////產品資訊區/////
            formData2,
            // isErrorItem: formData2.value.id<0||formData2.value.id===null||formData2.value.id==='',
            productDetailVar,
            btDisableCtrl,
            editSave,
            editSaveStyle,
            productChecker,
            productRuler,
            modification,
            createNewProduct,
            copyProduct,
            cancellation,
            deletion,

            /////模具需求設定/////
            columnDefs2,
            defaultColDef2: {
                editable: true,
                filter: false,
                sortable: false,
                lockPosition: true,
            },
            columnTypes2: {
                rightAligned: {
                    headerClass: 'ag-right-aligned-header',
                    cellClass: 'ag-right-aligned-cell'
                },
            },
            rowData2,
            onGridReady2,
            onFirstDataRendered2,
            onGridSizeChanged2,
            getRowId2,
            onRowClicked2,
            onRowEditingStopped2,
            // lastClickedRow2,
            moldDemandVar,
            btDisableCtrl2,
            editSave2,
            editSaveStyle2,
            modification2,
            addMoldDemand,
            cancellation2,
            deletion2,

            lightBoxElement,
            imageInLightBox,
            clickImageBox,
        }
    }
}
</script>

<style scoped lang="scss">
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
@import "~@formkit/themes/dist/genesis/theme.css";

.container {
  width: 98%;
  margin: 0 auto;
  display: inline-flex;

  hr {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .button-bar-btn-margin {
    margin: 2px 5px;
  }

  .button-bar {
    margin-bottom: 5px;

    button {
      margin: 5px 2px 0 2px !important;
    }
  }

  .left-panel {
    width: 25%;
    min-width: 250px;

    .search-box {
      border-radius: 2px;
      border: 3px solid #56dc8e;
      height: 128.5px;
      //width: 25%;
      //min-width: 250px;
      z-index: 50;
      overflow-y: hidden;
      padding: 5px 5px;
      margin-right: 5px;
      transition: height .5s ease-out;
      background-color: #ffffff;

      &:hover, &:focus-within {
        height: 508.5px;
        transition: height .2s ease-in;
      }
    }

    .search-result-table {
      //position: absolute;
      //width: 25%;
      //min-width: 250px;
      z-index: 49;

      ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #F5F5F5;
      }

      ::-webkit-scrollbar {
        width: 15px;
        background-color: #F5F5F5;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
        background-color: #56dc8e;

        &:hover {
          background-color: #44b173;
        }
      }
    }
  }

  .right-panel {
    width: 75%;
    min-width: 250px;
    height: 518px;
    background-color: #ffffff;

    .product-detail-table {

      .triple {
        display: flex;
        justify-content: space-evenly;
      }

      .triple > ::v-deep(.formkit-outer) {
        width: calc(33% - 0.5em)
      }

      ::v-deep(.formkit-outer[data-type="checkbox"]) {
        display: flex;
        align-items: center;
      }

      //height: 346px;
      overflow-y: hidden;
    }

    .mold-demand-table {
      z-index: 50;

      ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #F5F5F5;
      }

      ::-webkit-scrollbar {
        width: 15px;
        background-color: #F5F5F5;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
        background-color: #56dc8e;

        &:hover {
          background-color: #44b173;
        }
      }

    }
  }

  .img-box {
    width: 100%;
    height: 160px;
    border: solid;
    display: flex;
    justify-content: center;
    align-items: center;

    .img {
      max-width: 100%;
      height: auto;
      max-height: 154px;
      margin: 0;
    }
  }

}

.table-title {
  margin: 20px auto 0;
  font-size: 2em;
}

::v-deep(.ag-header-cell-label) {
  justify-content: center !important;
}

::v-deep(.formkit-label) {
  text-align: left;
}

::v-deep(.formkit-wrapper) {
  margin: 0 auto;
}

::v-deep(.formkit-label) {
  color: #888888;
}

::v-deep(.formkit-actions) {
  display: none;
}
</style>
<style lang="scss">
.number-input {
  height: 40px;
  border-radius: 3px;
  border: none;
  outline: 1px solid silver;
  background-color: #ffffff;
  text-align: center;
  display: inline-flex;
  font-size: 15px;

  &:focus {
    border: none;
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.auto-complete {
  //height: 40px;
  border-radius: 5px;
  border: 1px solid grey;
  background-color: #eeeeee;
  padding: 2px;
  text-align: center;
  //display: inline-flex;
  outline: none;

  input {
    //margin-bottom: 5px;
    height: 34px;
  }

  .options {
    margin-top: 5px;
    overflow-y: scroll;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }

    .selected {
      border-radius: 2px;
      padding: 4px;
      background-color: #b8b8b8;
      cursor: pointer;

      &:hover {
        background-color: #d9d9d9;
        -webkit-transition: background-color 0.3s ease;
        -ms-transition: background-color 0.3s ease;
        transition: background-color 0.3s ease;
      }
    }

    .default {
      border-radius: 2px;
      padding: 4px;
      background-color: #eeeeee;
      cursor: pointer;

      &:hover {
        background-color: #d9d9d9;
        -webkit-transition: background-color 0.3s ease;
        -ms-transition: background-color 0.3s ease;
        transition: background-color 0.3s ease;
      }
    }
  }
}

.img-box2 {
  width: 100%;
  //border: solid;
  display: flex;
  justify-content: center;
  align-items: center;

  .img {
    max-width: 100%;
    height: auto;
    margin: 0;
  }
}
</style>
