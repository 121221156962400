<template>
  <tr :key="productItem.id" class="dropzone" draggable="true"
      @dragstart.self="dragRow($event)"
      @dragover="allowRowDrop($event)"
      @drop="dropRow($event)"
      @dragend="dragEnd($event)"
  >
    <!--拖曳把手-->
    <td class="handler w3-center">
      <i class="fa fa-align-justify fa-lg"></i></td>
    <!--產品編號-->
    <td @dblclick.stop="$refs.dropdownElement.keyMonitor();saveData(false,$event)">
      <!--      <input type="text" v-model="itemName" @keydown.enter.prevent="saveData(false)"-->
      <!--             @keydown.esc.prevent="recoverData" v-show="productItem.editing">-->
      <Dropdown
          v-show="productItem.editing"
          v-model="itemName"
          @keyup.enter="saveData(false);jump2NextRow($event)"
          @keyup.esc="recoverData"
          ref="dropdownElement"
          :productName="name"
          :options="options"
          :disabled="false"
          :max-item="12"
          :is-product-exist="itemName && options.includes(itemName)"
          v-on:selected="validateSelection"
          v-on:filter="getDropdownValues"
          v-on:next="nextInput"
          @pasteQuantity="pasteQuantity"
          placeholder="請輸入產品編號">
      </Dropdown>
      <span v-show="!productItem.editing">{{ productItem.name }}</span>
    </td>
    <!--數量-->
    <td class="w3-center" @dblclick.stop="$refs.dropdownElement.keyMonitor();saveData(false)">
      <div :class="{active:interval}" style="width: 20%;" v-show="productItem.editing"
           @dblclick.stop @mousedown.stop="minus" @mouseleave="stop" @mouseup="stop">
        <i class="fa fa-minus w3-center"></i></div>
      <input type="number" v-model="itemAmount" min="0" @change="set0" autocomplete="off"
             @keyup.enter.prevent="saveData(false);jump2NextRow($event)"
             @keydown.tab.prevent="saveData(false);jump2NextRow($event)"
             @keydown.esc="$refs.dropdownElement.escMonitor();recoverData()"
             v-show="productItem.editing" style="width: 60%;">
      <div :class="{active:interval}" style="width: 20%;" v-show="productItem.editing"
           @dblclick.stop @mousedown.stop="plus" @mouseleave="stop" @mouseup="stop">
        <i class="fa fa-plus w3-center"></i></div>
      <span v-show="!productItem.editing">{{ productItem.quantity }}</span>
    </td>
    <!--優先-->
    <td class="w3-center" @mousedown.stop.prevent>
      <input class="w3-check" type="checkbox" v-model="productItem.prior">
    </td>
    <!--編輯儲存-->
    <td class="w3-center save-button"
        @click.stop="$refs.dropdownElement.keyMonitor();saveData(false,$event)"
        @mousedown.stop.prevent>
      <i :class="[editSaveClass,saveNoticeAnimation]"></i>
    </td>
    <!--刪除-->
    <td class="w3-center delete-button" :class="{active:deleteRowTimeout}"
        @mousedown.stop.prevent="deleteRow($event)"
        @mouseleave="stop"
        @mouseup="stop"
        @touchstart="deleteRow($event)"
        @touchend="stop"
    >
      <i class="far fa-trash-alt fa-lg"></i></td>
  </tr>
</template>

<script>
import Dropdown from '../components/Dropdown';
import {mapState} from "vuex";

export default {
  name: "EditableRow",
  template: 'EditableRow',
  components: {Dropdown},
  data() {
    return {
      //儲存資料
      productItem: {
        id: this.id,
        name: this.name,
        quantity: this.quantity,
        prior: this.prior,
        editing: this.editing,
      },
      //暫存修改資料
      itemName: this.name,
      itemAmount: this.quantity,

      plusIntervalTime: 150,
      interval: false,
      deleteRowTimeout: false,

      saveNotice: false,
      saveNoticeTimeout: false,

      selected: null,
    }
  },
  props: {
    "index": {type: Number},
    "id": {type: Number, required: true},
    "name": {type: String},
    "quantity": {type: [Number, String], default: 1},
    "prior": {type: Boolean, default: false},
    "editing": {type: Boolean, default: false},
  },
  methods: {
    //資料列操作功能
    plus() {
      this.itemAmount++;
      this.set0();
      if (!this.interval) {
        this.interval = setInterval(() => {
          this.itemAmount++;
          this.set0();
        }, this.plusIntervalTime)
      }
    },
    minus() {
      this.itemAmount--;
      this.set0();
      if (!this.interval) {
        this.interval = setInterval(() => {
          this.itemAmount--;
          this.set0();
        }, this.plusIntervalTime)
      }
    },
    set0() {
      if (this.itemAmount < 0) this.itemAmount = 0;
    },
    stop() {
      clearInterval(this.interval);
      clearTimeout(this.deleteRowTimeout)
      this.interval = false;
      this.deleteRowTimeout = false;
    },
    saveData(allSave = false,event) {
      // console.log("save!");
      this.productItem.name = this.selected;
      this.productItem.quantity = this.itemAmount;
      if (!allSave) {
        this.editSwitch();
        this.$nextTick(()=>{
          // console.log("nextTick!");
          if(event){
            event.target.closest("tr").querySelector("input.dropdown-input").focus();
          }
        })
      } else {
        this.productItem.editing = false;
      }
    },
    jump2NextRow(event) {
      let nextElement = event.target.closest("tr").nextElementSibling;
      if(nextElement) {
        if (nextElement.querySelector("i.fa-pencil")){
          // console.log("jump&click!");
          nextElement.querySelector("td.save-button").click();
        } else {
          // console.log("jump&focus");
          nextElement.querySelector("input.dropdown-input").focus();
        }
      }
    },
    nextInput(event){
      let nextElement = event.target.closest("td").nextElementSibling;
      nextElement.querySelector("input[type=number]").focus();
    },
    recoverData() {
      // console.log("recover");
      this.itemName = this.productItem.name;
      this.itemAmount = this.productItem.quantity;
      this.editSwitch();
    },
    editSwitch() {
      this.productItem.editing = !this.productItem.editing;
    },
    deleteRow() {
      console.log(this.index);
      if (this.productItem.editing) {
        if (!this.saveNoticeTimeout) {
          this.saveNotice = true;
          this.saveNoticeTimeout = setTimeout(() => {
            this.saveNotice = false;
            this.saveNoticeTimeout = false;
          }, 1000);
        }
        return;
      }
      if (!this.deleteRowTimeout) {
        this.deleteRowTimeout = setTimeout(() => {
          this.$emit('delete-row', this.index);
        }, 650)
      }
    },
    //產品編號自動填入功能
    validateSelection(selection) {
      this.selected = selection;
      // console.log(selection + ' has been selected');
    },
    getDropdownValues(keyword) {
      // console.log('You could refresh options by querying the API with ' + keyword);
      this.itemName = keyword;
    },
    //拖曳功能
    //開始拖曳，必須
    dragRow(event) {
      event.stopPropagation();
      event.target.classList.toggle("dragging");

      event.dataTransfer.setData('text/plain', String(this.index));
      this.$emit('drag-row', this.index);
    },
    dropRow(event) {
      event.preventDefault();
      let data = event.dataTransfer.getData('text/plain');
      this.$emit('drop-row', data, this.index);
    },
    allowRowDrop(allowdropevent) {
      allowdropevent.preventDefault();
      this.$emit('drag-enter', this.index);
    },
    // dragEnter(event) {
    //   event.preventDefault();
    //   this.$emit('drag-enter', this.index);
    // },
    // dragLeave() {
    //   console.log(this.index);
    // },
    dragEnd(event) {
      event.dataTransfer.clearData('text/plain');
      event.target.classList.toggle("dragging");
    },
    pasteQuantity(q){
      this.itemAmount = q;
    }
  },
  computed: {
    //編輯/儲存圖標切換
    editSaveClass() {
      return {
        far: true,
        'fa-edit': !this.productItem.editing,
        'fa-save': this.productItem.editing,
        'fa-lg': true,
      }
    },
    //尚未儲存提醒動畫
    saveNoticeAnimation() {
      return {
        'animate__animated': this.saveNotice,
        'animate__heartBeat': this.saveNotice,
      }
    },
    ...mapState({
      options: 'productList',//獲取所有產品編號資料，並利用Dropdown元件的v-bind屬性傳遞到元件中
      listIsLoaded: 'productListIsLoaded',//是否成功獲取
    }),
  },
  watch: {
    //偵測元件主要資料狀態，內容一旦更新，就傳送整份資料和當前index到母元件
    productItem: {
      deep: true,
      handler(val) {
        this.$emit('update', val, this.index);
      }
    },
  },
}
</script>

<style lang="scss" scoped>
$color-button: #303366;
@mixin hover-td {
  &:hover {
    background-color: $color-button;

    i {
      color: white;
    }
  }
}

tr {
  position: relative;

  &.remove-row {
    td {
      transform: translateX(80vw);
      opacity: 0 !important;
    }
  }

  opacity: 1;
  box-shadow: none;
  transform: translate(0);

  &.dragging {
    position: relative;
    opacity: 0.8 !important;
    box-shadow: 4px 4px 2px 0 rgba(127, 127, 127, 0.86) !important;
    transform: translate(5px, 3px) !important;
    transition: all 300ms ease-in-out;
    z-index: 10 !important;

    td {
      background-color: #3f51b5;
    }
  }

  //能看的表格樣式
  td {
    table-layout: fixed;
    margin: 0 !important;
    padding: 5px 0 !important;
    vertical-align: middle !important;
    max-height: 40px;
    min-height: 0;
    height: 40px;
    transform: translateX(0vw);
    transition: all 250ms ease;

    input {
      background: rgba(255, 255, 255, 0.7);
      border-radius: 3px;
    }

    div {
      display: inline-block;
      width: 10% !important;
      border-radius: 5px;
      cursor: pointer;

      transition: all 300ms ease;
      @include hover-td;
    }

    &:nth-child(1) {
      width: 4%;
      position: relative;

      i {
        cursor: pointer;
      }
    }

    &:nth-child(2) {
      width: 31%;
      padding: 5px 0 5px 7px !important;
      z-index: 10 !important;

      input {
        //margin-left: -4px !important;
        //width: 90%;
      }
    }

    &:nth-child(3) {
      width: 25%;
      position: relative;

      input {
        margin: 0 5% !important;
      }
    }

    &:nth-child(4) {
      width: 5%;

      input {
        transform: translateY(-2px);
        vertical-align: center;
      }
    }

    &:nth-child(5) {
      user-select: none;
      width: 15%;
      cursor: pointer;

      transition: all 300ms ease;
      @include hover-td;
    }

    &:nth-child(6) {
      user-select: none;
      width: 5%;
      cursor: pointer;

      background-size: 220% !important;
      background: linear-gradient(to left, rgba(255, 255, 255, 0) 50%, red 50%) right;

      &:hover {
        background-color: $color-button;
        transition: all 100ms ease-in;

        i {
          color: white;
        }
      }

      &.active {
        background-position: left;
        transition: all 600ms ease-out;
      }
    }
  }


}
</style>
