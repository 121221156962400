<template>
  <div class="lightbox">
    <transition name="lightbox">
      <div class="modal-mask" v-show="isShow">
        <div class="modal-container" @click.self="toggleBox();leaveEvent($event)" :style="{'background-color': `rgba(0,0,0,${opacity})`}">
          <div id="modal-head" class="modal-head w3-center">
            <slot name="header"></slot>
          </div>
          <div id="modal-body" class="modal-body w3-center" :style="{'width':width,'height':height}">
            <slot name="main"></slot>
          </div>
          <div id="modal-foot" class="modal-foot w3-center">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "LightBox",
  template: "LightBox",
  data() {
    return {
      isShow: false,
    }
  },
  props:{
    opacity: {
      type: Number,
      required: false,
      default: 0.5,
      note: 'Opacity of mask.'
    },
    width:{
      type: String,
      required: false,
      default: 'fit-content',
      note: 'Setting of width.'
    },
    height:{
      type: String,
      required: false,
      default: 'fit-content',
      note: 'Setting of height.'
    },
    cancelable:{
      type:Boolean,
      required: false,
      default: true,
      note: 'Can we cancelled box manually?'
    },
    timeSet:{
      type: Number,
      required: false,
      default: 1000,
      note: 'TimeInterval.'
    }
  },
  computed: {  },
  methods: {
    toggleBox() {
      if (this.isShow&&!this.cancelable)return false;
      this.isShow = !this.isShow;
    },
    leaveEvent(event){
      this.$emit('leave',event);
    }
  },
}
</script>

<style lang="scss" scoped>
.lightbox-enter-to,
.lightbox-leave-from {
  opacity: 1;
}

.lightbox-enter-from,
.lightbox-leave-to {
  opacity: 0;
}

.lightbox-enter-active,
.lightbox-leave-active {
  transition: opacity 0.35s ease;
}

.modal-mask {
  display: table;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100vh;


  .modal-container {
    cursor: pointer;
    display: table-cell;
    vertical-align: middle;
    padding: 10px 10px;

    .modal-head {
      cursor: default;
      left: 20px;
      //position: relative;
      width: fit-content;
      max-width: 75%;
      height: fit-content;
      margin: 0 auto;
      background-color: #eeeeee;
    }
    .modal-body {
      border: 2px solid #555555;
      left: 25px;
      cursor: default;
      width: fit-content;
      max-width: 75%;
      //height: fit-content;
      min-height: 100px;
      max-height: 75vh;
      //position: relative;
      background-color: #eeeeee;
      margin: 0 auto;
      overflow-y: scroll;
      //transition: width 0.7s ease;

      .toggle {
        float: left;
        margin: 5px 0;
      }
    }
  }
}
</style>
