<template>
    <div class="w3-container">
        <h1>鑄造零件系統</h1>
        <hr/>
        <ul class="w3-bar w3-border-0 w3-light-grey">
            <router-link to="/casting/new" replace custom v-slot="{ navigate, isActive }">
                <li @click="navigate" role="link" :class="{'active': isActive}">
                    <span>新增鑄造單</span>
                </li>
            </router-link>
            <router-link to="/casting/list" replace custom v-slot="{ navigate, isActive }">
                <li @click="navigate" role="link" :class="{'active': isActive}">
                    <span>檢視鑄造單</span>
                </li>
            </router-link>
            <router-link to="/casting/searchMold" replace custom v-slot="{ navigate, isActive }">
                <li @click="navigate" role="link" :class="{'active': isActive}">
                    <span>搜尋模具</span>
                </li>
            </router-link>
            <router-link to="/casting/mutate" replace custom v-slot="{ navigate, isActive }">
                <li @click="navigate" role="link" :class="{'active': isActive}">
                    <span>模具文字替換</span>
                </li>
            </router-link>
            <router-link to="/casting/product_dat" replace custom v-slot="{ navigate, isActive }">
                <li @click="navigate" role="link" :class="{'active': isActive}">
                    <span>產品資料</span>
                </li>
            </router-link>
            <router-link to="/casting/mold_dat" replace custom v-slot="{ navigate, isActive }">
                <li @click="navigate" role="link" :class="{'active': isActive}">
                    <span>模具資料</span>
                </li>
            </router-link>
        </ul>
        <hr/>
    </div>
    <router-view></router-view>
    <DraggableDiv ref="dragSearchMold" :btnName="'重製查詢窗位置'" :resetButton=false>
        <template #header><span style="margin-left: 5px">快速查詢</span><i :class="toggleIcon"
                                                                               @click.stop="switchType"></i>
        </template>
        <template #main>
            <div v-show="searchType">
                <SearchProductMoldAssociationRealtime></SearchProductMoldAssociationRealtime>
            </div>
            <div v-show="!searchType">
                <SearchMoldRealtime></SearchMoldRealtime>
            </div>
        </template>
    </DraggableDiv>
    <Keypress key-event="keyup" :multiple-keys="multiple" @success="resetDraggableWindow"></Keypress>
</template>

<script>
import DraggableDiv from "../components/DraggableFloatingWindow";
import SearchProductMoldAssociationRealtime from "../components/SearchProductMoldAssociationRealtime";
import Keypress from "vue-keypress";
import SearchMoldRealtime from "../components/SearchMoldRealtime.vue";

export default {
    name: 'Casting',
    template: 'Casting',
    components: {
        SearchMoldRealtime,
        DraggableDiv, SearchProductMoldAssociationRealtime, Keypress,
    },
    data() {
        return {
            multiple: [
                {
                    keyCode: 82, // A
                    modifiers: ['altKey'],
                    preventDefault: true,
                },
            ],
            searchType: true,
        }
    },
    methods: {
        resetDraggableWindow() {
            this.$refs.dragSearchMold.resetInitialPos();
        },
        switchType() {
            this.searchType = !this.searchType;
        },
    },
    computed: {
        toggleIcon() {
            return {
                'fa': true,
                'fa-gift': this.searchType,
                'fa-cogs': !this.searchType,
            }
        },
    },
    //當進入新增鑄造單頁面時，向伺服器請求產品清單
    created() {
        const load = localStorage.getItem('rememberMe');
        if (load) {
            this.$store.dispatch('fetchProductList').catch(e => console.error(e));
            this.$store.dispatch('fetchMoldList').catch(e => console.error(e));
            this.$store.dispatch('fetchMoldTextMutationTable', JSON.parse(load).username).catch(e => console.error(e));
        }
    },
};
</script>

<style lang="scss" scoped>
$text-color-default: #1c1c1c;
$navibar-bg-color: #fff;
$navibar-text-color: #8a8a8a;
$navibar-text-color-hover: #fff;
$navibar-link-color-hover: #00bbbb;
$navibar-link-color-active: #00bb80;
$navibar-height: 40px;
$navibar-icon-width: 55px;
$navibar-font-size: 1.1em;
.w3-hover-teal, .w3-hover-text-white {
  -webkit-transition: all .14s linear;
  transition: all .14s linear;
}

ul {
  padding: 0;
  margin: 0 0;
  list-style-type: none;
  text-decoration: none;

  li {
    padding: 0 15px;
    position: relative;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    height: $navibar-height;
    font-size: $navibar-font-size;
    cursor: pointer;
    transition: all .14s linear;
    -webkit-transition: all .14s linear;

    &:hover {
      color: $navibar-text-color-hover;
      background-color: $navibar-link-color-hover;
    }

    &.active {
      color: $navibar-text-color-hover;
      background-color: $navibar-link-color-active;
    }
  }
}
</style>
