<template>
  <div class="dropdown" v-if="options || alwaysShow">

    <!-- Dropdown Input -->
    <input class="dropdown-input"
           autocomplete="off"
           :name="name"
           @focus="showOptions()"
           @blur.capture="exit()"
           @keydown.enter.capture="keyMonitor"
           @keydown.tab.capture="keyMonitor"
           @keydown.esc.capture="escMonitor"
           v-model="searchFilter"
           :disabled="disabled"
           :placeholder="placeholder"
    />

    <!-- Dropdown Menu -->
    <div class="dropdown-content" :style="{'max-height':maxHeight+'px'}"
         v-show="optionsShown">
      <div
          class="dropdown-item"
          @mousedown.prevent.stop="selectOption(option);jump2NextInput($event)"
          v-for="(option, index) in filteredOptions"
          :key="index">
        <span style="font-weight: bold">
          {{ option.match(new RegExp('^' + searchFilter, 'ig'))[0] ?? null }}
        </span>
        <span>
          {{
            option.split(new RegExp('^' + searchFilter, 'ig'))[1] ?? option.split(new RegExp('^' + searchFilter, 'ig'))[0]
          }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dropdown',
  template: 'Dropdown',
  props: {
    name: {
      type: String,
      required: false,
      default: 'dropdown',
      note: 'Input name'
    },
    options: {
      type: Array,
      required: true,
      default: () => {
      },
      note: 'Options of dropdown. An array of options with id and name',
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Please select an option',
      note: 'Placeholder of dropdown'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Disable the dropdown'
    },
    maxItem: {
      type: Number,
      required: false,
      default: 6,
      note: 'Max items showing'
    },
    maxHeight: {
      type: Number,
      required: false,
      default: 248,
      note: 'Max height of Content'
    },
    initialValue: {
      type: String,
      required: false,
      default: "",
      note: 'the default value in input field when the element mount.'
    },
    alwaysShow: {
      type: Boolean,
      required: false,
      default: false,
      note: 'always show search bar even without options.'
    }
  },
  data() {
    return {
      selected: this.initialValue,
      optionsShown: false,
      searchFilter: this.initialValue,
      cache: this.initialValue,
      timeout4Typing: false,
    }
  },
  created() {
    this.$emit('selected', this.selected);
  },
  computed: {
    // filteredOptions() {
    //   const filtered = [];
    //   const regOption = new RegExp('^' + this.searchFilter, 'ig'); //+ '|^[a-zA-Z]+' + (this.searchFilter) + ''
    //   for (const option of this.options) {
    //     if (this.searchFilter.length < 1 || option.match(regOption)) {
    //       if (filtered.length < this.maxItem) filtered.push(option);
    //     }
    //   }
    //   // return filtered;
    //   return (this.searchFilter !== '') ? filtered : [];
    // },
    filteredOptions() {
      const regOption = new RegExp('^' + this.searchFilter, 'ig'); //+ '|^[a-zA-Z]+' + (this.searchFilter) + ''
      return (this.searchFilter !== '') ? this.options.filter(x => this.searchFilter.length < 1 || x.match(regOption)).slice(0, this.maxItem) : [];
    },
  },
  methods: {
    selectOption(option) {
      this.selected = option;
      this.optionsShown = false;
      this.searchFilter = this.selected;
      // this.$emit('selected', this.selected);
    },
    cancelOption() {
      this.selected = this.cache;
      this.optionsShown = false;
      this.searchFilter = this.cache;
      // this.$emit('selected', this.cache)
    },
    showOptions() {
      if (!this.disabled) {
        // this.searchFilter = '';
        this.optionsShown = true;
        this.cache = this.selected;
      }
    },
    exit() {
      if (!this.selected && this.cache !== this.searchFilter) {
        this.selected = '';
        this.searchFilter = '';
      } else {
        this.searchFilter = this.selected;
      }
      this.$emit('selected', this.selected);
      this.optionsShown = false;
    },
    // Selecting when pressing Enter
    keyMonitor() {
      this.selectOption(this.filteredOptions[0]);
      this.exit();
      if (this.filteredOptions[0] && (this.searchFilter !== null || this.searchFilter !== "")) {
        if (!this.optionsShown) {
          this.selectOption(this.selected);
        } else {
          this.selectOption(this.filteredOptions[0]);
        }
      }
    },
    escMonitor() {
      if (this.searchFilter !== null || this.searchFilter !== "") {
        this.cancelOption();
      }
    },
    jump2NextInput(event) {
      this.$emit('next', event);
    },
  },
  watch: {
    searchFilter() {
      clearTimeout(this.timeout4Typing);
      this.timeout4Typing = setTimeout(() => {
        // console.log("ready!")
        this.$emit('finishType', this.searchFilter);
      }, 1000); // 1 sec delay
    }
  }
};
</script>


<style lang="scss" scoped>
.dropdown {
  z-index: 2 !important;
  position: relative;
  //display: block;
  //min-width: 80%;
  //margin-left: -4px !important;

  .dropdown-input {
    background: rgba(255, 255, 255, 0.7);
    //border: 1px solid #e7ecf5;
    border-radius: 3px;
    color: #333;
    display: block;
    //font-size: .8em;
    //padding: 6px;

    min-width: 100%;

    &:hover {
      background: #f8f8fa;
    }
  }

  .dropdown-content {
    position: absolute;
    float: bottom;
    display: block;
    overflow-y: scroll;
    z-index: 99 !important;
    background-color: #fff;
    border: 2px solid #888888;
    min-width: 100%;
    max-height: 248px;
    box-shadow: 0px -8px 34px 0px rgba(0, 0, 0, 0.05);

    .dropdown-item {
      position: relative;
      color: black;
      font-size: .9em;
      line-height: 1em;
      padding: 8px;
      text-decoration: none;
      border: 1px solid #e0e0e0;
      cursor: pointer;

      &:hover {
        background-color: #e7ecf5;
      }
    }
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }
}
</style>
