<template>
    <div class="container w3-container w3-center">
        <Dropdown
                ref="dropdownElement"
                :options="options"
                :max-item="12"
                :max-height="190"
                :always-show="true"
                placeholder="請輸入模具編號"
                @click.prevent
                @finish-type="getMoldList"
                @selected="getMoldRelation"
        >
        </Dropdown>
        <div v-if="true">
            <div class="table-title"><span>模具資料</span></div>
            <div class="button-bar">
                <button class="w3-button w3-teal w3-hover-pale-green button-bar-btn-margin" @click="updateBunchMold"
                        :disabled="!moldHasEdited">儲存
                </button>
                <button class="w3-button w3-blue w3-hover-pale-blue button-bar-btn-margin" @click="restoreMold"
                        :disabled="!moldHasEdited">重設
                </button>
            </div>
            <ag-grid-vue style="padding-bottom: 5px"
                         class="ag-theme-alpine"
                         :localeText="localeText"
                         domLayout='autoHeight'
                         :columnDefs="columnDefs"
                         :defaultColDef="defaultColDef"
                         :columnTypes="columnTypes"
                         :rowData="rowData"
                         rowSelection="single"
                         @grid-ready="onGridReady"
                         @first-data-rendered="onFirstDataRendered"
                         @gridSizeChanged="onGridSizeChanged"
                         @cellValueChanged="onCellValueChanged">
            </ag-grid-vue>
        </div>
        <div v-if="true">
            <div class="table-title"><span>相關產品</span></div>
            <div class="button-bar">
                <button class="w3-button w3-teal w3-hover-pale-green button-bar-btn-margin" @click="updateMoldDemand"
                        :disabled="!mdHasEdited">儲存
                </button>
                <button class="w3-button w3-blue w3-hover-pale-blue button-bar-btn-margin" @click="restoreMoldDemand"
                        :disabled="!mdHasEdited">重設
                </button>
            </div>
            <div class="grid-settings">
                每頁筆數：
                <select v-model="pageSize" @change="onPageSizeChanged()" id="page-size">
                    <option value="5" selected="">5</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </select>
            </div>
            <ag-grid-vue style="padding-bottom: 20px"
                         class="ag-theme-alpine"
                         :localeText="localeText"
                         domLayout='autoHeight'
                         :pagination="true"
                         :paginationPageSize="5"
                         :columnDefs="columnDefs2"
                         :defaultColDef="defaultColDef2"
                         :rowData="rowData2"
                         rowSelection="single"
                         :enableCellChangeFlash="true"
                         @grid-ready="onGridReady2"
                         @first-data-rendered="onFirstDataRendered2"
                         @gridSizeChanged="onGridSizeChanged2"
                         @cellValueChanged="onCellValueChanged2">

            </ag-grid-vue>
        </div>
    </div>
    <teleport to="body">
        <notifications group="moldSearch" position="bottom right" :max="8" :speed="500" :ignore-duplicates="true"/>
        <light-box ref="lightBoxElement" :opacity="0.5">
            <template #main>
                <div class="img-box2">
                    <img class="img" :src="imageInLightBox" alt="沒有圖片"/>
                </div>
            </template>
        </light-box>
    </teleport>
</template>

<script>
import {computed, reactive, ref} from "vue";
import {useStore} from 'vuex'
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {AgGridVue} from "ag-grid-vue3";
import _ from 'lodash';
import Dropdown from "@/components/DropdownTrue";
import {onBeforeRouteLeave} from "vue-router";
import {notify} from "@kyvg/vue3-notification";
import imageGrid from "../components/imageGrid.vue";
import LightBox from "../components/LightBoxTrue.vue";

export default {
    name: "SearchMoldRelation2",
    components: {LightBox, Dropdown, AgGridVue},
    setup() {
        const store = useStore();

        //模具搜尋框動作
        const getMoldList = (m) => {
            if (m !== "") {
                store.dispatch('fetchMoldSearchList', m)
                    .catch(e => console.error(e));
            }
        };
        const getMoldRelation = (s) => {
            if (s !== "") {
                store.dispatch('fetchMoldRelation', s)
                    .then(() => {
                        rowData.value = JSON.parse(JSON.stringify(computed(() => store.state.moldRelationTable).value));
                        backup.value = JSON.parse(JSON.stringify(computed(() => store.state.moldRelationTable).value));
                        rowData2.value = JSON.parse(JSON.stringify(computed(() => store.state.moldProductTable).value));
                        backup2.value = JSON.parse(JSON.stringify(computed(() => store.state.moldProductTable).value));
                        rowData2.value.forEach(el => el.edited = false);//擴增一欄，紀錄是否有修改
                        backup2.value.forEach(el => el.edited = false);//擴增一欄，紀錄是否有修改
                    })
                    .catch(e => console.error(e));
            }
        };

        ////////////模具資料///////////
        let rowData = ref([]);
        let backup = ref([]);
        let columnDefs = reactive([
            {headerName: "模具編號", field: "mNum", editable: false},
            {headerName: "模具名稱", field: "name"},
            {
                headerName: "分支數量", field: "comp",
                valueParser: params => {
                    const len = rowData.value.length;
                    if (params.data.parent === null && len > 1) {
                        return params.oldValue;
                    } else if (params.data.parent && len > 1 || len === 1) {
                        return Number.isInteger(parseFloat(params.newValue)) && params.newValue > 0 ? params.newValue : params.oldValue;
                    } else {
                        return params.oldValue;
                    }
                },
                onCellValueChanged: () => {
                    const len = rowData.value.length;
                    if (len === 1) return false;
                    let rData = getRowData();
                    let nonParentRows = rData.filter(x => x.parent);
                    rData[rData.findIndex(x => x.parent === null)].comp = _.sum(nonParentRows.map(x => _.toNumber(x.comp)));
                    rowData.value = rData;
                },
            },
            {
                headerName: "鑄造廠",
                field: "fac",
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {values: ["A", "B"]},
                onCellValueChanged: params => {
                    let rData = getRowData();
                    rData.forEach(x => x.fac = params.newValue);
                    rowData.value = rData;
                },
            },
            {
                headerName: "為母模",
                field: "parent",
                editable: false,
                valueFormatter: params => {
                    return params.value === null ? "是" : "否";
                }
            },
            {
                headerName: "模具圖片", field: "image", editable: false, suppressKeyboardEvent: () => {
                    return true
                }, cellRenderer: imageGrid, onCellClicked: (e) => {
                    if (!e.value) {
                        return false;
                    }
                    imageInLightBox.value = e.value;
                    toggleBox();
                },
            },
        ]);
        let gridApi = ref(null);
        let columnApi = ref(null);
        let moldHasEdited = ref(false);
        let moldHasSaved = ref(true);

        const onGridReady = params => {
            gridApi.value = params.api;
            columnApi.value = params.columnApi;
        };
        const onFirstDataRendered = params => {
            params.api.sizeColumnsToFit();
        };
        //自動調整欄位寬度
        const onGridSizeChanged = params => {
            if (params.clientWidth > 600) {
                gridApi.value.sizeColumnsToFit();
            } else {
                columnDefs.forEach(x => {
                    x.width = 120;
                });
                gridApi.value.setColumnDefs(columnDefs);
            }
        };
        const onCellValueChanged = () => {
            gridApi.value.setRowData(rowData.value);
            moldHasSaved.value = false;
            moldHasEdited.value = true;
        };

        //回傳當前表格呈現的data狀態與順序
        const getRowData = () => {
            let arr = [];
            gridApi.value.forEachNode((rowNode) => {
                arr.push(rowNode.data);
            });
            return JSON.parse(JSON.stringify(arr));
        };
        //更新模具資料
        const updateBunchMold = () => {
            moldHasEdited.value = false;
            let payload = {molds: getRowData()};
            store.dispatch('updateBunchMold', payload).then(() => {
                notify({
                    group: "moldSearch",
                    title: "成功儲存。",
                    type: "success",
                });
                moldHasSaved.value = true;
            }).catch(e => {
                notify({
                    group: "moldSearch",
                    title: "儲存失敗，請檢察後再嘗試。",
                    type: "error",
                });
                console.error(e);
                moldHasEdited.value = true;
            });
        };
        const restoreMold = () => {
            if (moldHasEdited.value) {
                const answer = window.confirm('確定要恢復初始設定嗎?')
                if (!answer) return false;
                rowData.value = JSON.parse(JSON.stringify(backup.value));
                gridApi.value.setRowData(JSON.parse(JSON.stringify(backup.value)));
                moldHasEdited.value = false;
                moldHasSaved.value = true;
            }
        };

        ///////////相關產品///////////
        let rowData2 = ref([]);
        let backup2 = ref([]);
        let columnDefs2 = reactive([
            {headerName: "產品編號", field: "pNum", editable: false},
            {headerName: "產品名稱", field: "name", editable: false},
            {headerName: "模具編號", field: "mNum", editable: false},
            {
                headerName: "需要個數", field: "demands",
                valueParser: params => {
                    return Number.isInteger(parseFloat(params.newValue)) && params.newValue > 0 ? params.newValue : params.oldValue;
                }
            },
            {
                headerName: "產品圖片", field: "image", editable: false, suppressKeyboardEvent: () => {
                    return true
                }, cellRenderer: imageGrid, onCellClicked: (e) => {
                    if (!e.value) {
                        return false;
                    }
                    imageInLightBox.value = e.value;
                    toggleBox();
                },
            },
        ]);
        let gridApi2 = ref(null);
        let columnApi2 = ref(null);
        let pageSize = ref(5);
        let mdHasEdited = ref(false);
        let mdHasSaved = ref(true);

        const onGridReady2 = params => {
            gridApi2.value = params.api;
            columnApi2.value = params.columnApi;
        };
        const onFirstDataRendered2 = params => {
            params.api.sizeColumnsToFit();
        };
        //自動調整欄位寬度
        const onGridSizeChanged2 = params => {
            if (params.clientWidth > 600) {
                gridApi2.value.sizeColumnsToFit();
            } else {
                columnDefs2.forEach(x => {
                    x.width = 200;
                });
                gridApi2.value.setColumnDefs(columnDefs2);
            }
        };
        //切換每頁顯示筆數
        const onPageSizeChanged = () => {
            gridApi2.value.paginationSetPageSize(Number(pageSize.value));
        };
        const onCellValueChanged2 = params => {
            mdHasSaved.value = false;
            mdHasEdited.value = true;
            rowData2.value[params.rowIndex][params.colDef.field] = params.newValue;
            rowData2.value[params.rowIndex]["edited"] = true;
        };
        //回傳當前表格呈現的data狀態與順序
        const getRowData2 = () => {
            let arr = [];
            gridApi2.value.forEachNode((rowNode) => {
                arr.push(rowNode.data);
            });
            return JSON.parse(JSON.stringify(arr));
        };
        //更新模具資料
        const updateMoldDemand = () => {
            mdHasEdited.value = false;
            let payload = {md: getRowData2().filter(el => el.edited)};
            store.dispatch('updateMoldDemand', payload).then(() => {
                notify({
                    group: "moldSearch",
                    title: "成功儲存。",
                    type: "success",
                });
                mdHasSaved.value = true;
            }).catch(e => {
                notify({
                    group: "moldSearch",
                    title: "儲存失敗，請檢察後再嘗試。",
                    type: "error",
                });
                console.error(e);
                mdHasEdited.value = true;
            });
        };
        const restoreMoldDemand = () => {
            if (mdHasEdited.value) {
                const answer = window.confirm('確定要恢復初始設定嗎?')
                if (!answer) return false;
                rowData2.value = JSON.parse(JSON.stringify(backup2.value));
                gridApi2.value.setRowData(JSON.parse(JSON.stringify(backup2.value)));
                mdHasEdited.value = false;
                mdHasSaved.value = true;
            }
        };

        //檢查有沒有儲存
        onBeforeRouteLeave(() => {
            if (!moldHasSaved.value || !mdHasSaved.value) {
                const answer = window.confirm('已修改模具但尚未儲存，確定要離開嗎?')
                if (!answer) return false;
                moldHasSaved.value = true;
            }
        });

        const imageInLightBox = ref("");
        const lightBoxElement = ref(null);
        const toggleBox = () => {
            lightBoxElement.value.toggleBox();
        };

        //////送出
        return {
            //全局變數
            localeText: computed(() => store.state.AG_GRID_LOCALE_CH),
            //模具搜尋動作與結果
            getMoldList,
            getMoldRelation,
            options: computed(() => store.getters.treatedOptions),
            moldSearchIsLoaded: computed(() => store.state.moldSearchIsLoaded),

            //模具資料
            columnDefs,
            defaultColDef: {
                editable: true,
                filter: true,
                sortable: true,
                lockPosition: true,
            },
            columnTypes: {
                rightAligned: {
                    headerClass: 'ag-right-aligned-header',
                    cellClass: 'ag-right-aligned-cell'
                },
            },
            onGridReady,
            onFirstDataRendered,
            onGridSizeChanged,
            onCellValueChanged,
            moldRelationIsLoaded: computed(() => store.state.moldRelationIsLoaded),
            rowData,
            moldHasEdited,
            updateBunchMold,
            restoreMold,

            //相關產品
            columnDefs2,
            defaultColDef2: {
                editable: true,
                filter: true,
                sortable: true,
                lockPosition: true,
                floatingFilter: true,
            },
            pageSize,
            onPageSizeChanged,
            onGridReady2,
            onFirstDataRendered2,
            onGridSizeChanged2,
            onCellValueChanged2,
            rowData2,
            mdHasEdited,
            updateMoldDemand,
            restoreMoldDemand,

            lightBoxElement,
            imageInLightBox,
        };
    },
}
</script>

<style scoped lang="scss">
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";

.container {
  width: 90%;
  margin: 0 auto;

  .button-bar-btn-margin {
    margin: 2px 5px;
  }

  .img-box {
    width: 100%;
    height: 160px;
    border: solid;
    display: flex;
    justify-content: center;
    align-items: center;

    .img {
      max-width: 100%;
      height: auto;
      max-height: 154px;
      margin: 0;
    }
  }
}

.table-title {
  margin: 20px auto 0;
  font-size: 2em;
}

::v-deep(.ag-header-cell-label) {
  justify-content: center !important;
}
</style>
<style lang="scss">
.img-box2 {
  width: 100%;
  //border: solid;
  display: flex;
  justify-content: center;
  align-items: center;

  .img {
    max-width: 100%;
    height: auto;
    margin: 0;
  }
}
</style>
