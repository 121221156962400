<script>
export default {
    name: 'imageGrid',
    data() {
        return {
            img: '',
            toggle: false,
        };
    },
    methods: {

    },

    beforeMount() {
        this.img = this.params.value;
    },
}
</script>

<template>
    <div class="img-grid">
        <img class="img" :src="img" alt="沒有圖片" v-if="img"/>
    </div>
</template>

<style lang="scss">
.img-grid {
  width: 100%;
  height: 40px;
  //border: solid;
  display: flex;
  justify-content: center;
  align-items: center;

  .img {
    max-width: 100%;
    height: auto;
    max-height: 40px;
    margin: 0;
  }
}
</style>
