<template>
  <div class="w3-container w3-center button-bar">
    <input class="w3-input w3-left" style="width: 40%" v-model="searchFilter"
           :placeholder="'常規: '+castOrder.length+'項，客製: '+customized.length+'項'"/>
    <button class="w3-button w3-right w3-teal w3-hover-pale-green" v-print="'#printCastOrder'"><i
        class="fa fa-print fa-lg"></i></button>
    <button class="w3-button w3-right w3-indigo w3-hover-pale-blue" @click.self.prevent="printImage('#printCastOrder')">
      <i class="far fa-image fa-lg"></i></button>
    <button class="w3-button w3-right w3-deep-orange w3-hover-pale-yellow" @click="showEditWindow"
            :disabled="!selectRow">
      <i class="fa fa-edit fa-lg"></i></button>
    <button class="w3-button w3-right w3-purple w3-hover-pale-red" @click="isRegularShow = !isRegularShow">
      {{ isRegularShow ? "常規品項" : "客製品項" }}
    </button>
  </div>
  <div id="printCastOrder" class="castOrderContainer w3-container w3-center">
    <table id="castOrderDisplayTable" class="w3-table-all" v-show="isRegularShow">
      <thead>
      <tr>
        <th class="w3-center"></th>
        <th class="w3-center">產品編號</th>
        <th class="w3-center">數量</th>
        <th class="w3-center">優先</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in filteredOrder" :key="item.product">
        <td class="w3-center" @click.stop><input class="w3-radio" type="radio" :value="item.product"
                                                 v-model="selectRow"></td>

        <td class="w3-center">
          <div v-show="!item.editProduct.isEditing">
            {{ item.product }}
          </div>
          <input class="w3-center" type="text"
                 v-model="item.editProduct.product" v-show="item.editProduct.isEditing" @blur="saveCell(item)"/>
        </td>

        <td class="w3-center">
          <div v-show="!item.editQuantity.isEditing" @click="editCell(item,$event,'q')">
            {{ item.quantity }}
          </div>
          <input class="w3-center" type="number" min="0"
                 v-model="item.editQuantity.quantity" v-show="item.editQuantity.isEditing"
                 @blur="saveCell(item,'q')"/>
        </td>

        <td class="w3-center"><i :class="priorityIcon((item.priority))"></i></td>
      </tr>
      </tbody>
    </table>
    <table id="customizedDisplayTable" class="w3-table-all" v-show="!isRegularShow">
      <thead>
      <tr>
        <th class="w3-center"></th>
        <th class="w3-center">客製品名</th>
        <th class="w3-center">數量</th>
        <th class="w3-center">優先</th>
        <th class="w3-center">備註</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) in customized" :key="index" @click="showCustomizedMolds(index)">
        <td class="w3-center" @click.stop><input class="w3-check" type="checkbox"></td>
        <td class="w3-center">{{ item.cus_abbrev }}</td>
        <td class="w3-center">{{ item.cus_needs }}</td>
        <td class="w3-center"><i :class="priorityIcon((item.cus_priority))"></i></td>
        <td class="w3-center" @click.prevent.stop="showCustomizedNote(index)"><i class="fa fa-list fa-lg"></i></td>
      </tr>
      </tbody>
    </table>
  </div>
  <teleport to="body">
    <LightBoxTrue ref="lightBox4Note">
      <template #main>
        <div>客製品備註</div>
        <textarea class="customized-note"
                  v-model="currentEditingNote"
                  placeholder="沒有客製品內容說明" readonly></textarea>
      </template>
    </LightBoxTrue>
    <LightBoxTrue ref="lightBox4Molds">
      <template #main>
        <div>模具需求</div>
        <table id="customizedMoldDisplayTable" class="w3-table-all">
          <thead>
          <tr>
            <th class="w3-center">模具編號</th>
            <th class="w3-center">零件數量</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,index) in molds" :key="index">
            <td class="w3-center">{{ item.mold }}</td>
            <td class="w3-center">{{ item.demands }}</td>
          </tr>
          </tbody>
        </table>
      </template>
    </LightBoxTrue>
    <LightBoxTrue ref="editOneRecord" @leave="leaveBox">
      <template #main>
        <div ref="div4loader">
          <div style="font-size: 1.6em;margin: 0 auto;clear: both">編輯品項資料</div>
          <table class="edit-container" v-if="selectRow">
            <tr class="row">
              <td>產品編號</td>
              <td>
                <div v-show="!selectRecord.editProduct.isEditing" @click="editOneRecord(selectRecord,$event,'p')"
                     :class="{alert: !isCorrect}">
                  {{ selectRecord.product }}
                </div>
                <input class="w3-input" type="text" v-model="selectRecord.editProduct.product"
                       v-show="selectRecord.editProduct.isEditing"
                       @blur="toggleOneRecord(selectRecord, 'p', 'save')"/>
              </td>
            </tr>
            <tr class="row">
              <td>數量</td>
              <td>
                <div v-show="!selectRecord.editQuantity.isEditing" @click="editOneRecord(selectRecord,$event,'q')">
                  {{ selectRecord.quantity }}
                </div>
                <input class="w3-input" type="number" min="0"
                       v-model="selectRecord.editQuantity.quantity" v-show="selectRecord.editQuantity.isEditing"
                       @blur="toggleOneRecord(selectRecord, 'q', 'save')"/>
              </td>
            </tr>
            <tr class="row">
              <td>優先</td>
              <td @click="selectRecord.priority=selectRecord.priority?0:1">
                <i :class="priorityIcon(selectRecord.priority)"></i>
              </td>
            </tr>
          </table>
          <button class="edit-record-button w3-button w3-center w3-teal w3-hover-pale-green w3-margin-bottom"
                  @click="updateRecord">
            <i class="fa fa-upload fa-lg"></i>&nbsp;更新品項
          </button>
          <button class="edit-record-button w3-button w3-center w3-red w3-hover-pale-red w3-margin-bottom"
                  @click="deleteRecord">
            <i class="fa fa-trash fa-lg"></i>&nbsp;刪除品項
          </button>
        </div>

      </template>
    </LightBoxTrue>
    <notifications group="actionNotice" position="bottom right" :max="8" :speed="500" :ignore-duplicates="true"/>
  </teleport>
</template>

<script>
import {mapState} from "vuex";
import print from 'vue3-print-nb'
import html2canvas from 'html2canvas';
import LightBoxTrue from "@/components/LightBoxTrue";
import {warn} from "vue";

export default {
  name: "SingleCastingOrderDisplay",
  template: "SingleCastingOrderDisplay",
  components: {LightBoxTrue},
  directives: {
    print
  },
  data() {
    return {
      isRegularShow: true,
      currentEditingNote: "",//把當下點擊的客製品暫存起來
      molds: [],
      castOrder: [],
      searchFilter: "",

      selectRow: "",
      selectRecord: {},
      isUpdate: false,
      isCorrect: true,
    }
  },
  computed: {
    ...mapState({
      castOrderOrigin: 'castOrder',
      isLoaded: 'castOrderIsLoaded',
      customized: 'customizedTable',
      customizedMold: 'customizedMoldTable',
      clickedOrder: 'clickedOrderId',

      productList: 'productList',
    }),
    filteredOrder() {
      const regOption = new RegExp('^' + this.searchFilter, 'ig'); //+ '|^[a-zA-Z]+' + (this.searchFilter) + ''
      return this.castOrder.filter(x => this.searchFilter.length < 1 || x.product.match(regOption))
    },
  },
  methods: {
    priorityIcon(a) {
      return parseInt(a) ? 'fa fa-check fa-lg' : '';
    },
    showCustomizedNote(index) {
      this.currentEditingNote = this.customized[index].cus_note;
      this.$refs.lightBox4Note.toggleBox();
    },
    showCustomizedMolds(index) {
      this.$store.dispatch('fetchCustomizedMoldTable', this.customized[index].cus_num)
          .then(() => {
            this.molds = this.customizedMold;
          })
          .catch(e => console.error(e));
      this.$refs.lightBox4Molds.toggleBox();
    },
    //輸出指定鑄造結果表格圖檔
    async printImage(selector) {
      await html2canvas(document.querySelector(selector)).then((canvas) => {
        const link = document.createElement("a");
        const filename = 'img_' + selector + '_' + new Date().toLocaleDateString() + '.png';
        link.href = canvas.toDataURL("image/png;base64");
        link.setAttribute("download", filename);
        link.click();
      })
    },
    editCell(item, event, target = "p") {
      this.toggleCell(item, target);
      // console.log(event.target.closest('td').querySelector('input[type=number]'));
      this.$nextTick(() => {
        event.target.closest('td').querySelector('input').focus();
      });
    },
    saveCell(item, target = "p") {
      this.toggleCell(item, target, 'save');
    },
    toggleCell(item, target = "p", state = "edit") {
      if (target === "p") {
        if (state === "edit") {
          console.log(this.clickedOrder);
          item.editProduct.product = item.product;
        } else if (state === "save" && item.editProduct.product !== item.product) {
          item.product = item.editProduct.product;
        }
        item.editProduct.isEditing = !item.editProduct.isEditing;
      } else if (target === "q") {
        if (state === "edit") {
          item.editQuantity.quantity = item.quantity;
        } else if (state === "save" && item.editQuantity.quantity !== item.quantity) {
          console.log("different!", this.clickedOrder);
          item.editQuantity.quantity = item.editQuantity.quantity > 0 ? item.editQuantity.quantity : 1;
          item.quantity = item.editQuantity.quantity;
          // let output = [this.clickedOrder, item.product, item.quantity];
          let output = {
            queryType: "update",
            uuid: this.clickedOrder,
            pNum: item.product,
            quantity: item.quantity,
          };
          this.$store.dispatch('updateCastingOrder', output)
              .catch(e => console.error(e));
        }
        item.editQuantity.isEditing = !item.editQuantity.isEditing;
      }
    },
    showEditWindow() {
      this.$refs.editOneRecord.toggleBox();
    },
    editOneRecord(item, event, target = "p") {
      this.toggleOneRecord(item, target);
      // console.log(event.target.closest('td').querySelector('input[type=number]'));
      this.$nextTick(() => {
        event.target.closest('td').querySelector('input').focus();
      });
    },
    toggleOneRecord(item, target = "p", state = "edit") {
      if (target === "p") {
        if (state === "edit") {
          item.editProduct.product = item.product;
          this.isCorrect = true;
        } else if (state === "save" && item.editProduct.product !== item.product) {
          item.product = item.editProduct.product;
        }
        item.editProduct.isEditing = !item.editProduct.isEditing;
      } else if (target === "q") {
        if (state === "edit") {
          item.editQuantity.quantity = item.quantity;
        } else if (state === "save" && item.editQuantity.quantity !== item.quantity) {
          item.editQuantity.quantity = item.editQuantity.quantity > 0 ? item.editQuantity.quantity : 1;
          item.quantity = item.editQuantity.quantity;
        }
        item.editQuantity.isEditing = !item.editQuantity.isEditing;
      }
    },
    updateRecord() {
      let arr_check = this.castOrder.find(x => x.product === this.selectRecord.product);
      //檢查想更改的編號是否與當前選擇的產品相同
      if (this.selectRow === this.selectRecord.product) {
        console.log("編號未更動");
        arr_check.quantity = this.selectRecord.quantity;
        arr_check.priority = this.selectRecord.priority;
        //送出update record的要求，
        let output = {
          queryType: "update",
          uuid: this.clickedOrder,
          pNum: arr_check.product,
          quantity: arr_check.quantity,
          priority: arr_check.priority,
        };
        this.$store.dispatch('updateCastingOrder', output)
            .then(() => this.$notify({
              group: "actionNotice",
              title: "成功更新品項!",
              type: "success",
            }))
            .catch(e => {
              console.error(e);
              this.$notify({
                group: "actionNotice",
                title: "更新品項失敗!",
                type: "error",
              });
            });
        this.isUpdate = true;
        this.$refs.editOneRecord.toggleBox();
        return false;
      }
      ///檢查產編是否存在資料庫中
      if (!this.productList.includes(this.selectRecord.product)) {
        this.isCorrect = false;
        this.$notify({
          group: "actionNotice",
          title: "產品編號不存在!",
          type: "warn",
        });
        return warn("產品編號不存在");
      }
      ///檢查產編是否存在當前訂單中
      // let newRecord = {};
      let arr_origin = this.castOrder.find(x => x.product === this.selectRow);
      if (!arr_check) {
        console.log("沒有該產品!");//
        arr_origin.product = this.selectRecord.product;
        arr_origin.quantity = this.selectRecord.quantity;
        arr_origin.priority = this.selectRecord.priority;
        //送出update record的要求，
        let output = {
          queryType: "update",
          uuid: this.clickedOrder,
          pNum: arr_origin.product,//new
          quantity: arr_origin.quantity,
          priority: arr_origin.priority,
          pNumOld: this.selectRow,
        };
        this.$store.dispatch('updateCastingOrder', output)
            .then(() => this.$notify({
              group: "actionNotice",
              title: "成功更新品項!",
              type: "success",
            }))
            .catch(e => {
              console.error(e);
              this.$notify({
                group: "actionNotice",
                title: "更新品項失敗!",
                type: "error",
              });
            });
        this.isUpdate = true;
      } else {
        console.log("已存在!");
        let newQuantity = arr_check.quantity + this.selectRecord.quantity;
        let newPriority = this.selectRecord.priority + arr_check.quantity ? '1' : '0';
        console.log(newQuantity, newPriority);//
        this.selectRecord.quantity = newQuantity;
        arr_origin.quantity = newQuantity;
        this.selectRecord.priority = newPriority;
        arr_origin.priority = newPriority;
        let index = this.castOrder.findIndex(x => x.product === this.selectRecord.product);
        if (index > -1) this.castOrder.splice(index, 1);
        arr_origin.product = this.selectRecord.product;//需要最後再改產編，不然可能會被findIndex誤掃到
        console.log("complete!");//
        let output = {
          queryType: "delete&update",
          uuid: this.clickedOrder,
          pNum: arr_origin.product,//new
          quantity: arr_origin.quantity,
          priority: arr_origin.priority,
          pNumOld: this.selectRow,
        };
        this.$store.dispatch('updateCastingOrder', output)
            .then(() => this.$notify({
              group: "actionNotice",
              title: "成功更新品項!",
              type: "success",
            }))
            .catch(e => {
              console.error(e);
              this.$notify({
                group: "actionNotice",
                title: "更新品項失敗!",
                type: "error",
              });
            });
        this.isUpdate = true;
      }
      this.$refs.editOneRecord.toggleBox();
    },
    deleteRecord() {
      let output = {
        queryType: "delete",
        uuid: this.clickedOrder,
        pNum: this.selectRecord.product,
      };
      this.$store.dispatch('updateCastingOrder', output)
          .then(() => {
            let index = this.castOrder.findIndex(x => x.product === this.selectRecord.product);
            if (index > -1) this.castOrder.splice(index, 1);
            this.selectRow = "";
            this.selectRecord = {};
            this.$notify({
              group: "actionNotice",
              title: "成功更新品項!",
              type: "success",
            });
          })
          .catch(e => {
            console.error(e);
            this.$notify({
              group: "actionNotice",
              title: "刪除品項失敗。",
              type: "error",
            });
          });
      this.$refs.editOneRecord.toggleBox();
    },
    leaveBox() {
      if (!this.isUpdate) {
        this.selectRecord = JSON.parse(JSON.stringify(this.castOrder.find(x => x.product === this.selectRow)));
      }
      this.isUpdate = false;
    },
  },
  watch: {
    castOrderOrigin() {
      this.castOrder = JSON.parse(JSON.stringify(this.castOrderOrigin));
      // this.castOrder.forEach(x => {
      //   x.isEditing1 = false;
      //   x.isEditing2 = false;
      // });
      this.castOrder.forEach(x => {
        x.editProduct = {'product': x.product, 'isEditing': false}
        x.editQuantity = {'quantity': x.quantity, 'isEditing': false}
      });
    },
    selectRow() {
      if (this.selectRow) this.selectRecord = JSON.parse(JSON.stringify(this.castOrder.find(x => x.product === this.selectRow)));
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/defaultStyle";
/* 移除input數字型別右側的增減按鈕 */
::v-deep(input::-webkit-outer-spin-button),
::v-deep(input::-webkit-inner-spin-button) {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
::v-deep(input[type=number]) {
  -moz-appearance: textfield;
}

.button-bar {
  button {
    margin: 5px 2px 0 2px !important;
  }
}

table#castOrderDisplayTable {
  user-select: text;
  margin-top: 10px;
  margin-bottom: 10px;
  overflow-y: scroll;

  thead tr th, tfoot tr th {
    height: 40px;
    background-color: $table-head-background-color;
  }

  tbody {
    tr {
      //cursor: pointer;
      td {
        height: $table-row-height;
        padding: 5px 0 !important;
        vertical-align: middle;
        max-height: 40px;
        min-height: 0;

        &:nth-child(1) {
          width: 10%;

          input {
            cursor: pointer;
          }
        }

        &:nth-child(2) {
          width: 30%;
        }

        &:nth-child(3) {
          width: 30%;
          cursor: pointer;
          padding-top: 0 !important;
          padding-bottom: 0 !important;

          input {
            width: 100%;
            height: 100%;
          }

          &:hover {
            border: 0.8px dashed #555555;
          }
        }

        &:nth-child(4) {
          width: 30%;
        }
      }

      &:hover td {
        background-color: #c1c1c1;
      }
    }
  }
}

table#customizedDisplayTable {
  user-select: text;
  margin-top: 10px;
  margin-bottom: 10px;
  overflow-y: scroll;

  thead tr th, tfoot tr th {
    height: 40px;
    background-color: $table-head-background-color;
  }

  tbody {
    tr {
      //cursor: pointer;
      td {
        height: $table-row-height;
        padding: 5px 0 !important;
        vertical-align: middle;
        max-height: 40px;
        min-height: 0;
        cursor: pointer;

        &:nth-child(1) {
          width: 10%;
        }

        &:nth-child(2) {
          width: 30%;
        }

        &:nth-child(3) {
          width: 20%;
        }

        &:nth-child(4) {
          width: 20%;
        }

        &:nth-child(5) {
          width: 20%;
        }
      }

      &:hover td {
        background-color: #c1c1c1;
      }
    }
  }
}

table#customizedMoldDisplayTable {
  min-width: 300px;
  max-height: 75vh;
  user-select: text;
  margin-top: 10px;
  margin-bottom: 10px;
  overflow-y: scroll;

  thead tr th, tfoot tr th {
    height: 40px;
    background-color: $table-head-background-color;
  }

  tbody {
    tr {
      //cursor: pointer;
      td {
        height: $table-row-height;
        padding: 5px 0 !important;
        vertical-align: middle;
        max-height: 40px;
        min-height: 0;

        &:nth-child(1) {
          width: 50%;
        }

        &:nth-child(2) {
          width: 50%;
        }
      }

      &:hover td {
        background-color: #c1c1c1;
      }
    }
  }
}

textarea.customized-note {
  width: 300px;
  height: 300px;
  resize: none;
}

.edit-container {
  width: 550px;
  padding: 1px 5px;

  .row td {
    width: 45%;
    height: 2em !important;
    font-size: 1.5em;
    border: 1px solid #888888;

    &:nth-child(2) {
      cursor: pointer;
      transition: background-color 0.25s ease;

      &:hover {
        background-color: #3f51b5;
      }

      div.alert {
        border: 2px solid red;
        height: 100%;
      }
    }
  }
}

button.edit-record-button {
  margin: 0 5px;
}

@media print {
  table thead tr th:nth-child(1),
  table tbody tr td:nth-child(1) {
    display: none;
  }
;
}
</style>
