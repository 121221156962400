<template>
    <div class="customized-column">
        <table class="w3-table-all">
            <thead>
            <tr>
                <th class="w3-center">客製品名</th>
                <th class="w3-center">數量</th>
                <th class="w3-center">優先</th>
                <th class="w3-center">備註</th>
                <th class="w3-center">刪除</th>
                <th class="w3-center"></th>
            </tr>
            </thead>
            <transition-group name="slide" tag="TBODY">
                <tr v-for="(item,index) in customized" :key="index" :class="{currentCustomized: index===currentIndex}">
                    <td class="w3-center"><input class="w3-center" type="text" v-model="item.name" autocomplete="off"/>
                    </td>
                    <td class="w3-center"><input class="w3-center" type="number" v-model="item.quantity"
                                                 autocomplete="off"/></td>
                    <td class="w3-center"><input class="w3-check" type="checkbox" v-model="item.priority"></td>
                    <td class="w3-center" @click.stop="showCustomizedNote(index)"><i class="fa fa-list fa-lg"></i></td>
                    <td class="w3-center" @click.stop="deleteCustomized(index)"><i class="fa fa-trash fa-lg"></i></td>
                    <td class="w3-center" @click.stop="currentIndex = index"><i class="fa fa-arrow-right fa-lg"></i>
                    </td>
                </tr>
            </transition-group>
            <tfoot>
            <tr class="button-row">
                <td class="new-row w3-center" colspan="1" @click="addNewRow">
                    <i class="fa fa-plus-square fa-lg"></i>
                </td>
                <td class="copy-row w3-center" colspan="1" @click="copyRow">
                    <i class="fa fa-clipboard fa-lg"></i>
                </td>
                <td class="copy-row w3-center" colspan="4" @click="recoverRow">
                    <i class="fa fa-undo fa-lg"></i>
                </td>
            </tr>
            </tfoot>
        </table>
    </div>

    <div class="customized-column">
        <table class="w3-table-all">
            <thead>
            <tr>
                <th class="w3-center">圖片</th>
                <th class="w3-center">編號</th>
                <th class="w3-center">數量</th>
                <th class="w3-center">刪除</th>
            </tr>
            </thead>
            <transition-group name="slide" tag="TBODY">
                <tr v-for="(item, index) in customized[currentIndex].moldAssociation" :key="index">
                    <td class="w3-center">
                        <div class="img-grid">
                            <img class="img" :src="item.image" v-if="item.image"
                                 alt="沒有圖片" @click="clickImageBox(item.image)"/>
                        </div>
                    </td>
                    <td>{{ item.moldNum }}</td>
                    <td class="w3-center"><input class="w3-center" type="number" v-model="item.demands"
                                                 autocomplete="off"/></td>
                    <td class="w3-center" @click.stop="deleteMold(index)"><i class="fa fa-trash fa-lg"></i></td>
                </tr>
            </transition-group>
        </table>
    </div>

    <div class="customized-column">
        <div>
            <input class="w3-input" placeholder="請輸入產品編號" autocomplete="off" v-model="searchProduct"/>
        </div>
        <div ref="loaderContainer">
            <table id="productMoldTable" class="w3-table-all" v-show="association">
                <thead>
                <tr>
                    <th class="w3-center"></th>
                    <th class="w3-center">圖片</th>
                    <th class="w3-center">編號</th>
                    <th class="w3-center">需要/分支</th>
                    <!--                    <th class="w3-center">廠</th>-->
                </tr>
                </thead>
                <transition-group name="slide" tag="TBODY">
                    <tr v-for="(item, index) in association" :key="index">
                        <td class="w3-center" @click.stop="addMold(item)">
                            <i class="fa fa-arrow-left fa-lg"></i>
                        </td>
                        <td class="w3-center">
                            <div class="img-grid">
                                <img class="img" :src="item.image" v-if="item.image"
                                     alt="沒有圖片" @click="clickImageBox(item.image)"/>
                            </div>
                        </td>
                        <td class="tooltip">
                            {{ item.parent ? item.parent + " " : "" }}
                            <i class="fa fa-angle-double-right" v-if="item.parent"></i>
                            {{ item.mold }}
                            <span class="tooltip-text">{{ item.name }}</span>
                        </td>
                        <td class="w3-center">{{ item.demands }} / {{ item.comp }}</td>
                        <!--                        <td class="w3-center">{{ item.factory }}</td>-->
                    </tr>
                </transition-group>
            </table>
        </div>
        <div class="message-box" v-show="!isExisted">
            <span>查無此產品</span>
        </div>
    </div>
    <teleport to="body">
        <LightBoxTrue ref="lightBoxElement" :opacity="0.5">
            <template #main>
                <div>客製品備註</div>
                <textarea class="customized-note"
                          maxlength="1000"
                          v-model="currentEditingNote"
                          @blur.capture="saveCurrentEditingNote"
                          placeholder="請說明客製品內容"></textarea>
            </template>
        </LightBoxTrue>
        <LightBoxTrue ref="lightBoxElement2" :opacity="0.5">
            <template #main>
                <div class="img-box2">
                    <img class="img" :src="imageInLightBox" alt="沒有圖片"/>
                </div>
            </template>
        </LightBoxTrue>
    </teleport>

</template>

<script>
import {mapState} from "vuex";
import LightBoxTrue from "./LightBoxTrue.vue";

export default {
    name: "SelectMoldOfCustomizedProduct",
    template: "SelectMoldOfCustomizedProduct",
    components: {LightBoxTrue},
    data() {
        return {
            customized: [
                {
                    name: "",
                    quantity: 1,
                    priority: false,
                    note: "",
                    moldAssociation: [],
                },
                {
                    name: "",
                    quantity: 1,
                    priority: false,
                    note: "",
                    moldAssociation: [],
                },
            ],
            trash: [],
            currentIndex: 0,//當前顯示的客製品

            currentEditingNote: "",//把當下點擊的客製品暫存起來
            currentEditingNoteIndex: null,//記錄當下點擊的客製品編號

            //搜尋產品所需模具
            searchProduct: '',
            association: this.MoldAssoc,
            isExisted: true,

            imageInLightBox: "",
        }
    },
    computed: {
        ...mapState({
            productList: 'productList',
            MoldAssoc: 'productMoldAssociationTable',
            IsLoaded: 'productMoldAssociationTableIsLoaded',
        }),

    },
    methods: {
        addNewRow() {
            this.customized.push({
                name: "",
                quantity: 1,
                priority: false,
                note: "",
                moldAssociation: [],
            });
        },
        copyRow() {
            let item = JSON.parse(JSON.stringify(this.customized[this.currentIndex]));
            this.customized.splice(this.currentIndex, 0, item);
        },
        recoverRow() {
            if (this.trash.length !== 0) {
                let item = this.trash.splice(this.trash.length - 1, 1)[0];
                this.customized.push(item);
            }
        },
        deleteMold(index) {
            // console.log(this.customized[this.currentIndex].moldAssociation[index]);
            this.customized[this.currentIndex].moldAssociation.splice(index, 1);
        },
        addMold(item) {
            let newMold = {moldNum: item.mold, demands: item.demands, image: item.image};
            let currentMoldAssoc = this.customized[this.currentIndex].moldAssociation;
            let index = currentMoldAssoc.findIndex(x => x.moldNum === item.mold);
            //如果已經存在，則增加需求數量
            if (index < 0) {
                currentMoldAssoc.push(newMold);
            } else {
                currentMoldAssoc[index].demands = parseInt(currentMoldAssoc[index].demands) + parseInt(item.demands);
            }
        },
        deleteCustomized(index) {
            let item = this.customized.splice(index, 1)[0];
            if (item.name) this.trash.push(item);
        },
        showCustomizedNote(index) {
            this.currentEditingNote = this.customized[index].note;
            this.currentEditingNoteIndex = index;
            this.$refs.lightBoxElement.toggleBox();
        },
        saveCurrentEditingNote() {
            this.customized[this.currentEditingNoteIndex].note = this.currentEditingNote;
        },
        //初始化資料，若有先前暫時存的資料則載入
        formDataInit() {
            if (localStorage.getItem('customizedTempData')) {
                let local = JSON.parse(localStorage.getItem('customizedTempData'));
                if (local.customized.length !== 0) {
                    console.log('已載入暫存資料');
                    this.customized = local.customized;
                }
            }
        },

        toggleBox() {
            this.$refs.lightBoxElement2.toggleBox();
        },
        clickImageBox(v) {
            if (!v) {
                return false;
            }
            this.imageInLightBox = v;
            this.toggleBox();
        },
    },
    watch: {
        searchProduct() {
            this.isExisted = true;
            if (this.productList.includes(this.searchProduct.toUpperCase())) {
                let loader = this.$loading.show({
                    container: this.$refs.loaderContainer,
                    canCancel: false,
                });
                this.$store.dispatch('fetchProductMoldAssociation', this.searchProduct)
                    .then(() => this.association = this.MoldAssoc)
                    .catch(e => console.error(e))
                    .finally(() => loader.hide());
            } else {
                this.isExisted = false;
            }
        },
        'customized': {
            handler(val) {
                if (val) {
                    const tempData = {customized: this.customized};
                    localStorage.setItem('customizedTempData', JSON.stringify(tempData));
                }
            },
            deep: true,
        },
    },
    beforeMount() {
        this.formDataInit();
    },
}
</script>

<style lang="scss" scoped>
@import "src/assets/defaultStyle";

$color-button: #303366;
@mixin hover-td {
  &:hover {
    background-color: $color-button !important;

    i {
      color: white;
    }
  }
}

.customized-column {
  height: 500px;
  overflow-y: scroll;

  position: relative;
  display: block;
  float: left;

  table tr {
    th {
      font-size: 0.7em;
      height: 25px;
    }

    td {
      vertical-align: middle !important;
    }
  }

  &:nth-child(1) {
    width: 45%;
    background-color: #c7efda;

    table tr {
      td {
        height: 40px;
        transition: all 0.4s ease-in-out;
        transition-duration: 0.5s;

        &:nth-child(1) {
          width: 35%;

          input[type=text] {
            margin: 0;
            width: 90%;
          }
        }

        &:nth-child(2) {
          width: 15%;

          input[type=number] {
            margin: 0;
            width: 90%;
          }
        }

        &:nth-child(3) {
          width: 10%;
        }

        &:nth-child(4) {
          width: 10%;
          @include hover-td;
        }

        &:nth-child(5) {
          width: 10%;
          @include hover-td;
        }

        &:nth-child(6) {
          width: 10%;
          @include hover-td;
        }
      }

      &:not(.button-row):hover td {
        background-color: #c1c1c1;
      }

      &.button-row {
        td.new-row, td.copy-row {
          width: 50% !important;

          &:hover {
            background-color: #c1c1c1;
          }
        }
      }

      &.currentCustomized td {
        height: 60px;
        background-color: #56b9ea !important;
      }
    }

    input[type=checkbox] {
      transform: translateY(-2px);
      vertical-align: center;
    }
  }

  &:nth-child(2) {
    width: 25%;
    background-color: #bee2cd;

    tbody {
      tr {
        td {
          transition-duration: 0.5s;

          &:nth-child(1) {
            width: 28%;
          }

          &:nth-child(2) {
            width: 34%;
          }

          &:nth-child(3) {
            width: 23%;

            input[type=number] {
              margin: 0;
              width: 90%;
            }
          }

          &:nth-child(4) {
            width: 15%;
            @include hover-td;
          }
        }

        &:hover td {
          background-color: #c1c1c1;
        }
      }
    }
  }

  &:nth-child(3) {
    width: 30%;
    background-color: #abcebc;

    tr {
      td {
        transition-duration: 0.5s;

        &:nth-child(1) {
          width: 10%;
          @include hover-td;

          i {
            transform: translateX(-0.2em);
          }
        }

        &:nth-child(2) {
          width: 34%;
        }

        &:nth-child(3) {
          width: 38%;
        }

        &:nth-child(4) {
          width: 18%;
        }

        //&:nth-child(5) {
        //  width: 10%;
        //}
      }

      &:hover td {
        background-color: #c1c1c1;
      }
    }
  }
}

input:not([type=checkbox]) {
  margin-top: 10px;
}

textarea.customized-note {
  width: 300px;
  height: 300px;
  resize: none;
}

.tooltip .tooltip-text {
  position: absolute;
  visibility: hidden;
  width: fit-content;
  background-color: black;
  color: #ffffff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  opacity: 0.7;
  transform: translate(5px, -5px);
}

.tooltip:hover .tooltip-text {
  visibility: visible;
}


.slide-enter-from {
  transform: rotate3d(1, 0, 0, -90deg);
}

.slide-leave-to {
  transform: rotate3d(0, 1, 0, 90deg);
}

.slide-enter-active,
.slide-leave-active {
  //position: relative;
  //z-index: -1 !important;
  transition: all 500ms ease;
}

.slide-enter-to,
.slide-leave-from {
  transform: rotate3d(1, 0, 0, 0);

}


</style>
