<template>
    <div class="container w3-container w3-center">
        <div class="left-panel">
            <div class="search-box">
                <div class="button-bar">
                    <button class="w3-button w3-teal w3-hover-pale-green button-bar-btn-margin" @click="submitSearch">查詢
                    </button>
                    <button class="w3-button w3-brown w3-hover-pale-red button-bar-btn-margin" @click="cleanForm">清空
                    </button>
                </div>
                <FormKit type="form" autocomplete="off" v-model="formData" :disabled="false"
                         @submit="()=>{return false}">
                    <FormKit type="text" autocomplete="off" name="number" label="模具編號" autofocus></FormKit>
                    <FormKit type="text" autocomplete="off" name="name" label="模具名稱"></FormKit>
                    <FormKit type="select" autocomplete="off" name="factory" label="鑄造廠"
                             :options="facOptions2"></FormKit>
                </FormKit>
            </div>
            <div class="search-result-table" v-if="true">
                <ag-grid-vue style="height: 540px; padding: 5px 5px 5px 0;"
                             class="ag-theme-alpine"
                             :localeText="localeText"
                             domLayout="normal"
                             :columnDefs="columnDefs"
                             :defaultColDef="defaultColDef"
                             :columnTypes="columnTypes"
                             :rowData="rowData"
                             rowSelection="single"
                             :suppressRowClickSelection="moldDetailVar.isEditing"
                             :suppressCellFocus="moldDetailVar.isEditing"
                             @grid-ready="onGridReady"
                             @first-data-rendered="onFirstDataRendered"
                             @gridSizeChanged="onGridSizeChanged"
                             @row-clicked="onRowClicked">
                </ag-grid-vue>
            </div>
        </div>
        <div class="right-panel">
            <div class="product-detail-table">
                <div class="button-bar">
                    <button class="w3-button w3-green w3-hover-pale-green button-bar-btn-margin" @click="createNewMold"
                            :disabled="moldDetailVar.isEditing">新增
                    </button>
                    <button class="w3-button button-bar-btn-margin" :class="[editSaveStyle]" @click="modification"
                            :disabled="moldDetailVar.isErrorItem||(Object.keys(lastClickedRow).length === 0 && !moldDetailVar.isEditing)">
                        {{ moldDetailVar.isEditing ? "儲存" : "編輯" }}
                    </button>
                    <button class="w3-button w3-cyan w3-hover-pale-blue button-bar-btn-margin" @click="copyMold"
                            :disabled="moldDetailVar.isEditing||moldDetailVar.isErrorItem||Object.keys(lastClickedRow).length === 0">
                        複製
                    </button>
                    <button class="w3-button w3-blue-grey w3-hover-pale-blue button-bar-btn-margin"
                            @click="cancellation"
                            :disabled="!moldDetailVar.isEditing||moldDetailVar.isErrorItem">取消
                    </button>
                    <button class="w3-button w3-red w3-hover-pale-red button-bar-btn-margin" @click="deletionCheck"
                            :disabled="moldDetailVar.isEditing||moldDetailVar.isErrorItem||Object.keys(lastClickedRow).length === 0">
                        刪除
                    </button>
                </div>
                <FormKit id="form2" type="form" autocomplete="off" v-model="formData2"
                         :disabled="!moldDetailVar.isEditing"
                         @submit="()=>{return false}">
                    <div class="w3-row">
                        <div class="w3-col m4 l3">
                            <div class="img-box">
                                <img class="img" :src="formData2.image" v-if="formData2.image"
                                     alt="沒有圖片" @click="clickImageBox"/>
                            </div>
                        </div>
                        <div class="w3-col m8 l9">
                            <div class="triple">
                                <FormKit type="text" name="number" label="模具編號" style="text-transform: uppercase;"
                                         oninput="this.value = this.value.toUpperCase()"
                                         validation="required|(500)moldChecker|moldRuler"
                                         :validation-rules="{moldChecker,moldRuler}"
                                         validation-visibility="live"
                                         :validation-messages="{required:'必填',moldChecker:'該編號已存在',moldRuler:'該編號不合規定'}"></FormKit>
                                <FormKit type="text" name="name" label="模具名稱"></FormKit>
                                <FormKit type="select" name="factory" label="鑄造廠" :options="facOptions"
                                         validation="required"></FormKit>

                            </div>
                            <div class="triple">
                                <FormKit type="number" name="comp" label="分支數量(個)"
                                         help="若為母模，分支數須為子模分支加總" validation="required"
                                         :disabled="compDis"
                                         @change="fCompParser"></FormKit>
                                <FormKit type="checkbox" style="{opacity: 30%!important;}" name="isP"
                                         label="本編號為母模" disabled></FormKit>
                                <FormKit type="text" name="parent" label="母模編號"
                                         help="若設定此欄位，當前編號將判定為子模，輸入的編號將判定為母模"
                                         style="text-transform: uppercase;"
                                         oninput="this.value = this.value.toUpperCase()"
                                         :disabled="parentDis" @change="fParentNullify"
                                         validation="(500)cMoldChecker||pMoldChecker||sMoldChecker"
                                         :validation-rules="{cMoldChecker, pMoldChecker, sMoldChecker}"
                                         validation-visibility="live"
                                         :validation-messages="{cMoldChecker: '該編號已設定為子模', pMoldChecker: '該編號不存在', sMoldChecker: '不可設為母模'}"></FormKit>
                            </div>
                        </div>
                    </div>
                </FormKit>
            </div>
            <hr/>
            <div class="mold-child-table" v-if="true">
                <div class="button-bar"></div>
                <ag-grid-vue style="height: 395px; padding: 5px 5px 5px 0;"
                             class="ag-theme-alpine"
                             :localeText="localeText"
                             domLayout="normal"
                             :columnDefs="columnDefs2"
                             :defaultColDef="defaultColDef2"
                             :columnTypes="columnTypes2"
                             :rowData="rowData2"
                             rowSelection="single"
                             :suppressRowClickSelection="false"
                             :suppressCellFocus="false"
                             :suppressClickEdit="true"
                             :editType="'fullRow'"
                             :getRowId="getRowId2"
                             @grid-ready="onGridReady2"
                             @first-data-rendered="onFirstDataRendered2"
                             @gridSizeChanged="onGridSizeChanged2"
                             @row-clicked="()=>{return false}" @row-editing-stopped="()=>{return false}">
                </ag-grid-vue>
            </div>
        </div>
    </div>
    <teleport to="body">
        <notifications group="moldData" position="bottom right" :max="8" :speed="500" :ignore-duplicates="true"/>
        <GDialog v-model="dialogConfig.state" max-width="500">
            <div class="wrapper w3-container">
                <div class="content">
                    <div class="title">注意</div>
                    <p class="text">{{ dialogConfig.content }}</p>
                </div>
                <div class="actions">
                    <button class="w3-button w3-red w3-hover-pale-red w3-border w3-border-deep-orange w3-round-medium"
                            @click="deletion">確認
                    </button>
                    <button class="w3-button w3-light-grey w3-hover-greyscale w3-border w3-border-gray w3-round-medium"
                            @click="dialogConfig.state = false">取消
                    </button>
                </div>
            </div>
        </GDialog>
        <light-box ref="lightBoxElement" :opacity="0.5">
            <template #main>
                <div class="img-box2">
                    <img class="img" :src="imageInLightBox" alt="沒有圖片"/>
                </div>
            </template>
        </light-box>
    </teleport>
</template>

<script>
import {computed, onMounted, reactive, ref} from "vue";
import {useStore} from 'vuex';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "@formkit/themes/dist/genesis/theme.css";
import {AgGridVue} from "ag-grid-vue3";
import {GDialog} from 'gitart-vue-dialog';
import {onBeforeRouteLeave} from "vue-router";
import FormKit from "@formkit/vue";
import {notify} from "@kyvg/vue3-notification";
import LightBox from "../components/LightBoxTrue.vue";
import imageGrid from "../components/imageGrid.vue";

export default {
    name: "MoldData",
    components: {LightBox, FormKit, AgGridVue, GDialog},//Dropdown
    setup() {
        const store = useStore();
        ////////////////////////////////////////////模具搜尋框////////////////////////////////////////////
        const formData = ref({
            "number": "",
            "name": "",
            "factory": "",
        });
        const submitSearch = () => {
            let data = JSON.parse(JSON.stringify(formData.value));
            if (data.name === undefined) data.name = "";
            if (data.number === undefined) data.number = "";
            if (data.number === "" && data.name === "" && data.factory === "") {
                notify({
                    group: "moldData",
                    title: "搜尋條件不可為空",
                    type: "error",
                });
                return false;
            }
            store.dispatch("ctrlMoldData", {
                "method": "searchMold",
                "data": formData.value,
            }).then(() => {
                console.log("成功查詢");
                gridApi.value.setRowData(store.state.moldDataSearchList);
            }).catch(e => {
                notify({
                    group: "moldData",
                    title: "查詢失敗，請稍後再試或連繫管理員",
                    type: "error",
                });
                console.error(e);
            });
        };
        const cleanForm = () => {
            formData.value = {};
        }

        ////////////////////////////////////////////模具搜尋結果////////////////////////////////////////////
        let rowData = ref([]);
        let columnDefs = reactive([
            {headerName: "模具ID", field: "id", editable: false, hide: true},
            {headerName: "搜尋結果", field: "number", editable: false}
        ]);
        const lastClickedRow = ref({});

        let gridApi = ref(null);
        let columnApi = ref(null);
        const onGridReady = params => {
            gridApi.value = params.api;
            columnApi.value = params.columnApi;
        };
        const onFirstDataRendered = params => {
            params.api.sizeColumnsToFit();
        };
        const onGridSizeChanged = () => {
            gridApi.value.sizeColumnsToFit();
        };
        const onRowClicked = params => {
            //若正在編輯，停止查詢
            if (moldDetailVar.value.isEditing) return false;
            //檢查清單狀態，判定所選編號是否相同，若通過，透過axios發送請求查詢模具資料，再送至右側表格formData2
            if (Object.keys(lastClickedRow.value).length === 0 || lastClickedRow.value.id !== params.data.id) {
                lastClickedRow.value = JSON.parse(JSON.stringify(params.data));
                store.dispatch("ctrlMoldData", {
                    "method": "r",
                    "data": JSON.parse(JSON.stringify(params.data)),
                }).then(() => {
                    formData2.value = store.state.moldData;
                    lastClickedRow.value = JSON.parse(JSON.stringify(params.data));
                    gridApi2.value.setRowData(store.state.moldDataChild);
                    rowData2.value = store.state.moldDataChild;
                    console.log("成功查詢");
                }).catch(e => {
                    notify({
                        group: "moldData",
                        title: "查詢失敗，請稍後再試或連繫管理員",
                        type: "error",
                    });
                    console.error(e);
                });
            } else if (lastClickedRow.value.id === params.data.id) {
                return false;
            }
        };

        ////////////////////////////////////////////模具資料區////////////////////////////////////////////
        const formData2 = ref({
            "id": -1,
            "number": "",
            "name": "",
            "factory": "A",
            "comp": 1,
            "isP": false,
            "parent": null,
            "image": "",
        });
        let moldDetailVar = ref({
            "isEditing": false,
            "isErrorItem": computed(() => formData2.value.id < 0 || formData2.value.id === null || formData2.value.id === ''),
            "tempData": {},
            "isCreateCopy": false,
        });
        //資料CRUD，動作代碼: 修改 mod，新增 add，複製 copy，刪除 del
        //編輯狀態切換
        const editSave = () => {
            moldDetailVar.value.isEditing = !moldDetailVar.value.isEditing;
        };
        //編輯按鈕樣式切換
        const editSaveStyle = computed(() => {
            return {
                'w3-amber': !moldDetailVar.value.isEditing,
                'w3-hover-pale-yellow': !moldDetailVar.value.isEditing,
                'w3-deep-orange': moldDetailVar.value.isEditing,
                'w3-hover-pale-red': moldDetailVar.value.isEditing,
            }
        });
        //表單規則-檢查產品編號是否存在
        const moldChecker = ({value}) => {
            return !store.getters.moldListSimple.includes(value) || (value === lastClickedRow.value.number && !moldDetailVar.value.isCreateCopy);
        };
        const moldRuler = ({value}) => {
            const regex = /^[A-Z]+[0-9]{3,}(?:-[A-Z]+)*$|^[A-Z]+[0-9]{3,}$/;
            return typeof value === "string" ? regex.test(value) : false;
        };
        const fCompParser = () => {
            let value = formData2.value.comp;
            if (value < 1) {
                formData2.value.comp = 1;
            }
        };
        const fParentNullify = () => {
            let p = formData2.value.parent;
            if (p === '' || p === undefined) {
                formData2.value.parent = null;
            }
        };
        const parentDis = computed(() => {
            return formData2.value.isP || !moldDetailVar.value.isEditing;
        });
        const compDis = computed(() => {
            return formData2.value.isP || !moldDetailVar.value.isEditing;
        });
        const pMoldChecker = ({value}) => {
            return store.getters.moldListNotChild.some(x => x.number === value);
        };
        const cMoldChecker = ({value}) => {
            return !store.getters.moldListOnlyChild.some(x => x.number === value);
        };
        const sMoldChecker = ({value}) => {
            return value !== formData2.value.number;
        };

        //動作管理器
        const modification = () => {
            //修改動作
            if (!moldDetailVar.value.isEditing) {
                //開始編輯
                editSave();
                moldDetailVar.value.tempData = JSON.parse(JSON.stringify(formData2.value));
            } else {
                //新增/複製
                if (moldDetailVar.value.isCreateCopy) {
                    if (formData2.value.number === "" || store.getters.moldListSimple.includes(formData2.value.number)) {
                        window.alert("產編設定有誤，請再次確認。")
                        return false;
                    }
                    moldDetailVar.value.isCreateCopy = false;
                    store.dispatch("ctrlMoldData", {
                        "method": "c",
                        "data": JSON.parse(JSON.stringify(formData2.value)),
                    }).then(() => {
                        gridApi.value.setRowData(store.state.moldDataSearchList);
                        editSave();
                        notify({
                            group: "moldData",
                            title: "成功新增模具資料",
                            type: "success",
                        });
                    }).catch(e => {
                        notify({
                            group: "moldData",
                            title: "查詢失敗，請稍後再試或連繫管理員",
                            type: "error",
                        });
                        console.error(e);
                    });
                } else {
                    //只是編輯儲存
                    if (formData2.value.number === "" || (store.getters.moldListSimple.includes(formData2.value.number) && formData2.value.number !== lastClickedRow.value.number) || formData2.value.number === formData2.value.parent) {
                        window.alert("產編設定有誤，請再次確認。")
                        return false;
                    }
                    store.dispatch("ctrlMoldData", {
                        "method": "u",
                        "data": {
                            "newData": JSON.parse(JSON.stringify(formData2.value)),
                            "oldData": JSON.parse(JSON.stringify(moldDetailVar.value.tempData))
                        },
                    }).then(() => {
                        gridApi.value.setRowData(store.state.moldDataSearchList);
                        editSave();
                        notify({
                            group: "moldData",
                            title: "成功更新模具資料",
                            type: "success",
                        });
                    }).catch(e => {
                        notify({
                            group: "moldData",
                            title: "查詢失敗，請稍後再試或連繫管理員",
                            type: "error",
                        });
                        console.error(e);
                    });
                }
            }
        };
        //新增動作
        const createNewMold = () => {
            if (moldDetailVar.value.isEditing) return false;
            //先從伺服器抓取最新的未使用id
            store.dispatch('ctrlMoldData', {
                "method": "getNewId",
                "data": [],
            }).then(() => {
                let unusedID = store.state.moldDataNewId;
                //開始新增
                moldDetailVar.value.isCreateCopy = true;
                moldDetailVar.value.tempData = JSON.parse(JSON.stringify(formData2.value));
                formData2.value = {
                    "id": unusedID,
                    "number": "",
                    "name": "",
                    "factory": "A",
                    "comp": 1,
                    "isP": false,
                    "parent": null,
                };
                editSave();
            }).catch(e => {
                notify({
                    group: "moldData",
                    title: "獲取資料失敗，請稍後再試或連繫管理員",
                    type: "error",
                });
                console.error(e);
            });
        };
        //複製動作
        const copyMold = () => {
            if (Object.keys(lastClickedRow.value).length === 0 || moldDetailVar.value.isEditing) return false;
            //先從伺服器抓取最新的未使用id
            store.dispatch('ctrlMoldData', {
                "method": "getNewId",
                "data": [],
            }).then(() => {
                let unusedID = store.state.moldDataNewId;
                //開始新增
                moldDetailVar.value.isCreateCopy = true;
                moldDetailVar.value.tempData = JSON.parse(JSON.stringify(formData2.value));
                formData2.value = JSON.parse(JSON.stringify(formData2.value));
                formData2.value.id = unusedID;
                editSave();
            }).catch(e => {
                notify({
                    group: "moldData",
                    title: "獲取資料失敗，請稍後再試或連繫管理員",
                    type: "error",
                });
                console.error(e);
            });
        };
        //取消動作
        const cancellation = () => {
            if (!moldDetailVar.value.isEditing) return false;
            moldDetailVar.value.isCreateCopy = false;
            editSave();
            formData2.value = JSON.parse(JSON.stringify(moldDetailVar.value.tempData));
        };
        //刪除動作***********
        const dialogConfig = ref({
            "state": false,
            "content": "",
        });
        const deletionCheck = () => {
            if (moldDetailVar.value.isEditing) return false;
            //送出請求檢查要刪除的編號是否為母模或已經被使用
            store.dispatch('ctrlMoldData', {
                "method": "delCheck",
                "data": formData2.value.number,
            }).then(() => {
                //判斷回傳數值，第一個是子模數量，第二個是已使用在產品模具設定的數量
                const [childCount, assocCount] = store.state.moldDataDelCheck;
                //要刪除的是母模
                dialogConfig.value.content = `${childCount > 0 ? `本模具為母模，底下子模有${childCount}個，\n` : ""}${assocCount > 0 ? `有${assocCount}件產品使用本模具，\n` : ""}確定要刪除模具嗎? 刪除後無法復原`;
                dialogConfig.value.state = true;
            }).catch(e => {
                notify({
                    group: "moldData",
                    title: "獲取資料失敗，請稍後再試或連繫管理員",
                    type: "error",
                });
                console.error(e);
            });
        };
        const deletion = () => {
            store.dispatch('ctrlMoldData', {
                "method": "d",
                "data": JSON.parse(JSON.stringify(formData2.value)),
            }).then(() => {
                formData2.value = {
                    "id": -1,
                    "number": "",
                    "name": "",
                    "factory": "A",
                    "comp": 1,
                    "isP": false,
                    "parent": null,
                };
                rowData2.value = [];
                lastClickedRow.value = {};
                gridApi.value.setRowData(store.state.moldDataSearchList);
                gridApi2.value.setRowData([]);
                dialogConfig.value.state = false;
                notify({
                    group: "moldData",
                    title: "已刪除模具資料",
                    type: "success",
                });
            }).catch(e => {
                notify({
                    group: "moldData",
                    title: "獲取資料失敗，請稍後再試或連繫管理員",
                    type: "error",
                });
                console.error(e);
            });
        };

        ////////////////////////////////////////////模具母子檢視，不可修改////////////////////////////////////////////
        let rowData2 = ref([]);
        let columnDefs2 = reactive([
            {headerName: "模具ID", field: "id", hide: true},
            {headerName: "模具編號", field: "number"},
            {headerName: "模具名稱", field: "name"},
            {headerName: "分支數量", field: "comp"},
            {
                headerName: "模具圖片", field: "image", editable: false, suppressKeyboardEvent: () => {
                    return true
                }, cellRenderer: imageGrid, onCellClicked: (e) => {
                    if (!e.value) {
                        return false;
                    }
                    imageInLightBox.value = e.value;
                    toggleBox();
                },
            },
        ]);
        let gridApi2 = ref(null);
        let columnApi2 = ref(null);
        const getRowId2 = params => {
            return params.data.id
        };
        const onGridReady2 = params => {
            gridApi2.value = params.api;
            columnApi2.value = params.columnApi;
        };
        const onFirstDataRendered2 = params => {
            params.api.sizeColumnsToFit();
        };
        const onGridSizeChanged2 = () => {
            gridApi2.value.sizeColumnsToFit();
        };

        //掛載後匯入模具資訊
        onMounted(() => {
            // columnDefs2[columnDefs2.findIndex(x => x.field === "number")].cellEditorParams.options = computed(() => store.state.moldList).value;
            // moldDemandVar.value.options = computed(() => store.state.moldList).value;
        });
        //檢查有沒有儲存
        onBeforeRouteLeave(() => {
            if (moldDetailVar.value.isEditing) {
                if (!window.confirm("正在編輯資料中，若現在離開本頁面將遺失尚未儲存的資料，確定要離開嗎?")) {
                    return false
                }
            }
        });

        const imageInLightBox = ref("");
        const lightBoxElement = ref(null);
        const toggleBox = () => {
            lightBoxElement.value.toggleBox();
        };
        const clickImageBox = () => {
            if (!formData2.value.image) {
                return false;
            }
            imageInLightBox.value = formData2.value.image;
            toggleBox();
        };


        return {
            /////全局變數/////
            localeText: computed(() => store.state.AG_GRID_LOCALE_CH),
            facOptions: [
                {label: "財", value: "A"},
                {label: "陳", value: "B"},
            ],
            facOptions2: [
                {label: "", value: ""},
                {label: "財", value: "A"},
                {label: "陳", value: "B"},
            ],

            /////搜尋區/////
            formData,
            submitSearch,
            cleanForm,

            /////產品搜尋結果/////
            columnDefs,
            defaultColDef: {
                editable: false,
                filter: false,
                sortable: false,
                lockPosition: true,
            },
            columnTypes: {
                rightAligned: {
                    headerClass: 'ag-right-aligned-header',
                    cellClass: 'ag-right-aligned-cell'
                },
            },
            rowData,
            onGridReady,
            onFirstDataRendered,
            onGridSizeChanged,
            onRowClicked,
            lastClickedRow,

            /////產品資訊區/////
            formData2,
            // isErrorItem: formData2.value.id<0||formData2.value.id===null||formData2.value.id==='',
            moldDetailVar: moldDetailVar,
            editSave,
            editSaveStyle,
            moldChecker,
            moldRuler,
            pMoldChecker,
            cMoldChecker,
            sMoldChecker,
            modification,
            createNewMold,
            copyMold,
            cancellation,
            deletionCheck,
            deletion,

            fCompParser,
            fParentNullify,
            parentDis,
            compDis,
            dialogConfig,

            /////模具需求設定/////
            columnDefs2,
            defaultColDef2: {
                editable: true,
                filter: false,
                sortable: false,
                lockPosition: true,
            },
            columnTypes2: {
                rightAligned: {
                    headerClass: 'ag-right-aligned-header',
                    cellClass: 'ag-right-aligned-cell'
                },
            },
            rowData2,
            onGridReady2,
            onFirstDataRendered2,
            onGridSizeChanged2,
            getRowId2,

            lightBoxElement,
            imageInLightBox,
            clickImageBox,
        }
    }
}
</script>

<style scoped lang="scss">
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
@import "~@formkit/themes/dist/genesis/theme.css";
@import "~gitart-vue-dialog/dist/style.css";

.container {
  width: 98%;
  margin: 0 auto;
  display: inline-flex;

  hr {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .button-bar-btn-margin {
    margin: 2px 5px;
  }

  .button-bar {
    margin-bottom: 5px;

    button {
      margin: 5px 2px 0 2px !important;
    }
  }

  .left-panel {
    width: 25%;
    min-width: 250px;

    .search-box {
      border-radius: 2px;
      border: 3px solid #56dc8e;
      height: 128.5px;
      //width: 25%;
      //min-width: 250px;
      z-index: 50;
      overflow-y: hidden;
      padding: 5px 5px;
      margin-right: 5px;
      transition: height .5s ease-out;
      background-color: #ffffff;

      &:hover, &:focus-within {
        height: 280px;
        transition: height .2s ease-in;
      }
    }

    .search-result-table {
      //position: absolute;
      //width: 25%;
      //min-width: 250px;
      z-index: 49;

      ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #F5F5F5;
      }

      ::-webkit-scrollbar {
        width: 15px;
        background-color: #F5F5F5;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
        background-color: #56dc8e;

        &:hover {
          background-color: #44b173;
        }
      }
    }
  }

  .right-panel {
    width: 75%;
    min-width: 250px;
    height: 518px;
    background-color: #ffffff;

    .product-detail-table {
      .triple {
        display: flex;
        justify-content: space-evenly;
      }

      .triple > ::v-deep(.formkit-outer) {
        width: calc(33% - 0.5em)
      }

      ::v-deep(.formkit-outer[data-type="checkbox"]) {
        display: flex;
        align-items: center;
      }

      //height: 346px;
      overflow-y: hidden;
    }

    .mold-child-table {
      z-index: 50;

      ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #F5F5F5;
      }

      ::-webkit-scrollbar {
        width: 15px;
        background-color: #F5F5F5;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
        background-color: #56dc8e;

        &:hover {
          background-color: #44b173;
        }
      }

    }
  }

  .img-box {
    width: 100%;
    height: 160px;
    border: solid;
    display: flex;
    justify-content: center;
    align-items: center;

    .img {
      max-width: 100%;
      height: auto;
      max-height: 154px;
      margin: 0;
    }
  }
}

.table-title {
  margin: 20px auto 0;
  font-size: 2em;
}

::v-deep(.ag-header-cell-label) {
  justify-content: center !important;
}

::v-deep(.formkit-label) {
  text-align: left;
}

::v-deep(.formkit-wrapper) {
  margin: 0 auto;

  &[data-type=check] {
    opacity: 30%;
  }
}

::v-deep(.formkit-label) {
  color: #888888;
}

::v-deep(.formkit-actions) {
  display: none;
}

.g-dialog-frame {
  .wrapper {
    color: #000;
  }

  .content {
    padding: 20px;

    .text {
      white-space: pre;
    }
  }

  .title {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    padding: 10px 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);

    button {
      margin-left: 10px;
    }
  }
}
</style>
<style lang="scss">
.number-input {
  height: 40px;
  border-radius: 3px;
  border: none;
  outline: 1px solid silver;
  background-color: #ffffff;
  text-align: center;
  display: inline-flex;
  font-size: 15px;

  &:focus {
    border: none;
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.auto-complete {
  //height: 40px;
  border-radius: 5px;
  border: 1px solid grey;
  background-color: #eeeeee;
  padding: 2px;
  text-align: center;
  //display: inline-flex;
  outline: none;

  input {
    //margin-bottom: 5px;
    height: 34px;
  }

  .options {
    margin-top: 5px;
    overflow-y: scroll;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }

    .selected {
      border-radius: 2px;
      padding: 4px;
      background-color: #b8b8b8;
      cursor: pointer;

      &:hover {
        background-color: #d9d9d9;
        -webkit-transition: background-color 0.3s ease;
        -ms-transition: background-color 0.3s ease;
        transition: background-color 0.3s ease;
      }
    }

    .default {
      border-radius: 2px;
      padding: 4px;
      background-color: #eeeeee;
      cursor: pointer;

      &:hover {
        background-color: #d9d9d9;
        -webkit-transition: background-color 0.3s ease;
        -ms-transition: background-color 0.3s ease;
        transition: background-color 0.3s ease;
      }
    }
  }
}

.img-box2 {
  width: 100%;
  //border: solid;
  display: flex;
  justify-content: center;
  align-items: center;

  .img {
    max-width: 100%;
    height: auto;
    margin: 0;
  }
}
</style>
