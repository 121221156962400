import {createStore} from 'vuex';
import axios from 'axios';

export default createStore({
    state: {
        //發布時記得修改
        //development: '/api'
        //local-production: ''
        //host: 'http://sees-dashborad.rf.gd'
        //host: 'https://sees-dashborad.xyz' 沒用
        baseURL: 'https://sees-dashborad.rf.gd',

        //登入
        isAuthenticated: 0,
        username: "",
        password: "",
        keepLogin: false,

        //產品編號清單
        productList: [],
        productListIsLoaded: false,
        //模具編號清單
        moldList: [],//用axios帶資料到這裡
        moldListIsLoaded: false,

        //模具搜尋清單 for 模具查詢工具
        moldSearchList: [],
        moldSearchIsLoaded: false,
        moldRelationTable: [],
        moldProductTable: [],
        moldRelationIsLoaded: false,

        //鑄造系統
        castOrder: [],//單筆鑄造單內容
        clickedOrderId: "",
        castOrderIsLoaded: false,
        castOrderList: [],//所有鑄造單之清單
        castOrderListIsLoaded: false,
        rowsPerPage: 10,//每頁顯示筆數
        rowsCount: 100,//總共幾筆鑄造單，100只是預設值
        castOrderResults: [],//鑄造單轉換結果
        castOrderResultsIsLoaded: false,
        productMoldAssociationTable: [],//單個產品之模具關聯表
        productMoldAssociationTableIsLoaded: false,
        customizedTable: [],//單筆鑄造單對應之客製品列表
        customizedTableIsLoaded: false,
        customizedMoldTable: [],//客製品之模具需求
        customizedMoldTableIsLoaded: false,
        moldTextMutationTable: null,//模具名稱替換表格
        moldTextMutationTableIsLoaded: false,
        //[產品資料]
        productDataSearchList: [],
        productDataSearchListIsLoaded: false,
        productData: {
            "id": -1,
            "number": "",
            "class": "",
            "name": "",
            "series": "",
            "jewelry": "",
            "type": ""
        },
        productDataIsLoaded: false,
        productDataMold: [],
        productDataMoldIsLoaded: false,
        productDataNewId: -1,
        productDataMoldDemandNewId: -1,
        //[模具資料]
        moldDataSearchList: [],
        moldDataSearchListIsLoaded: false,
        moldData: {
            "id": -1,
            "number": "",
            "name": "",
            "factory": "A",
            "comp": 1,
            "isP": false,
            "parent": null,
        },
        moldDataIsLoaded: false,
        moldDataChild: [],
        moldDataChildIsLoaded: false,
        moldDataNewId: -1,
        moldDataDelCheck: [],

        //ag grid元件翻譯
        AG_GRID_LOCALE_CH: {
            // Set Filter
            selectAll: '(Select All)',
            selectAllSearchResults: '(Select All Search Results)',
            searchOoo: 'Search...',
            blanks: '(Blanks)',
            noMatches: 'No matches',

            // Number Filter & Text Filter
            filterOoo: '篩選...',
            equals: '等於',
            notEqual: '不等於',
            blank: '空值',
            notBlank: '不為空',
            empty: '選擇一個',

            // Number Filter
            lessThan: '小於',
            greaterThan: '大於',
            lessThanOrEqual: '小於等於',
            greaterThanOrEqual: '大於等於',
            inRange: '在範圍',
            inRangeStart: '從',
            inRangeEnd: '到',

            // Text Filter
            contains: '包含',
            notContains: '不包含',
            startsWith: '開頭為',
            endsWith: '結尾為',

            // Date Filter
            dateFormatOoo: 'yyyy-mm-dd',

            // Filter Conditions
            andCondition: '和',
            orCondition: '或',

            // Filter Buttons
            applyFilter: '應用',
            resetFilter: '重設',
            clearFilter: '清除',
            cancelFilter: '取消',

            // Filter Titles
            textFilter: '文字篩選',
            numberFilter: '數值篩選',
            dateFilter: '日期篩選',
            setFilter: '設定篩選器',

            // Side Bar
            columns: '欄位',
            filters: '篩選器',

            // columns tool panel
            pivotMode: 'Pivot Mode',
            groups: 'Row Groups',
            rowGroupColumnsEmptyMessage: 'Drag here to set row groups',
            values: 'Values',
            valueColumnsEmptyMessage: 'Drag here to aggregate',
            pivots: 'Column Labels',
            pivotColumnsEmptyMessage: 'Drag here to set column labels',

            // Header of the Default Group Column
            group: 'Group',

            // Other
            loadingOoo: '讀取中...',
            noRowsToShow: '沒有資料',
            enabled: '啟用',

            // Menu
            pinColumn: 'Pin Column',
            pinLeft: 'Pin Left',
            pinRight: 'Pin Right',
            noPin: 'No Pin',
            valueAggregation: 'Value Aggregation',
            autosizeThiscolumn: 'Autosize This Column',
            autosizeAllColumns: 'Autosize All Columns',
            groupBy: 'Group by',
            ungroupBy: 'Un-Group by',
            addToValues: 'Add ${variable} to values',
            removeFromValues: 'Remove ${variable} from values',
            addToLabels: 'Add ${variable} to labels',
            removeFromLabels: 'Remove ${variable} from labels',
            resetColumns: 'Reset Columns',
            expandAll: 'Expand All',
            collapseAll: 'Close All',
            copy: 'Copy',
            ctrlC: 'Ctrl+C',
            copyWithHeaders: 'Copy With Headers',
            copyWithHeaderGroups: 'Copy With Header Groups',
            paste: 'Paste',
            ctrlV: 'Ctrl+V',
            export: 'Export',
            csvExport: 'CSV Export',
            excelExport: 'Excel Export',

            // Enterprise Menu Aggregation and Status Bar
            sum: 'Sum',
            min: 'Min',
            max: 'Max',
            none: 'None',
            count: 'Count',
            avg: 'Average',
            filteredRows: 'Filtered',
            selectedRows: 'Selected',
            totalRows: 'Total Rows',
            totalAndFilteredRows: 'Rows',
            more: 'More',
            to: 'to',
            of: 'of',
            page: 'Page',
            nextPage: 'Next Page',
            lastPage: 'Last Page',
            firstPage: 'First Page',
            previousPage: 'Previous Page',

            // Pivoting
            pivotColumnGroupTotals: 'Total',

            // Enterprise Menu (Charts)
            pivotChartAndPivotMode: 'Pivot Chart & Pivot Mode',
            pivotChart: 'Pivot Chart',
            chartRange: 'Chart Range',

            columnChart: 'Column',
            groupedColumn: 'Grouped',
            stackedColumn: 'Stacked',
            normalizedColumn: '100% Stacked',

            barChart: 'Bar',
            groupedBar: 'Grouped',
            stackedBar: 'Stacked',
            normalizedBar: '100% Stacked',

            pieChart: 'Pie',
            pie: 'Pie',
            doughnut: 'Doughnut',

            line: 'Line',

            xyChart: 'X Y (Scatter)',
            scatter: 'Scatter',
            bubble: 'Bubble',

            areaChart: 'Area',
            area: 'Area',
            stackedArea: 'Stacked',
            normalizedArea: '100% Stacked',

            histogramChart: 'Histogram',

            // Charts
            pivotChartTitle: 'Pivot Chart',
            rangeChartTitle: 'Range Chart',
            settings: 'Settings',
            data: 'Data',
            format: 'Format',
            categories: 'Categories',
            defaultCategory: '(None)',
            series: 'Series',
            xyValues: 'X Y Values',
            paired: 'Paired Mode',
            axis: 'Axis',
            navigator: 'Navigator',
            color: 'Color',
            thickness: 'Thickness',
            xType: 'X Type',
            automatic: 'Automatic',
            category: 'Category',
            number: 'Number',
            time: 'Time',
            xRotation: 'X Rotation',
            yRotation: 'Y Rotation',
            ticks: 'Ticks',
            width: 'Width',
            height: 'Height',
            length: 'Length',
            padding: 'Padding',
            spacing: 'Spacing',
            chart: 'Chart',
            title: 'Title',
            titlePlaceholder: 'Chart title - double click to edit',
            background: 'Background',
            font: 'Font',
            top: 'Top',
            right: 'Right',
            bottom: 'Bottom',
            left: 'Left',
            labels: 'Labels',
            size: 'Size',
            minSize: 'Minimum Size',
            maxSize: 'Maximum Size',
            legend: 'Legend',
            position: 'Position',
            markerSize: 'Marker Size',
            markerStroke: 'Marker Stroke',
            markerPadding: 'Marker Padding',
            itemSpacing: 'Item Spacing',
            itemPaddingX: 'Item Padding X',
            itemPaddingY: 'Item Padding Y',
            layoutHorizontalSpacing: 'Horizontal Spacing',
            layoutVerticalSpacing: 'Vertical Spacing',
            strokeWidth: 'Stroke Width',
            offset: 'Offset',
            offsets: 'Offsets',
            tooltips: 'Tooltips',
            callout: 'Callout',
            markers: 'Markers',
            shadow: 'Shadow',
            blur: 'Blur',
            xOffset: 'X Offset',
            yOffset: 'Y Offset',
            lineWidth: 'Line Width',
            normal: 'Normal',
            bold: 'Bold',
            italic: 'Italic',
            boldItalic: 'Bold Italic',
            predefined: 'Predefined',
            fillOpacity: 'Fill Opacity',
            strokeOpacity: 'Line Opacity',
            histogramBinCount: 'Bin count',
            columnGroup: 'Column',
            barGroup: 'Bar',
            pieGroup: 'Pie',
            lineGroup: 'Line',
            scatterGroup: 'X Y (Scatter)',
            areaGroup: 'Area',
            histogramGroup: 'Histogram',
            groupedColumnTooltip: 'Grouped',
            stackedColumnTooltip: 'Stacked',
            normalizedColumnTooltip: '100% Stacked',
            groupedBarTooltip: 'Grouped',
            stackedBarTooltip: 'Stacked',
            normalizedBarTooltip: '100% Stacked',
            pieTooltip: 'Pie',
            doughnutTooltip: 'Doughnut',
            lineTooltip: 'Line',
            groupedAreaTooltip: 'Area',
            stackedAreaTooltip: 'Stacked',
            normalizedAreaTooltip: '100% Stacked',
            scatterTooltip: 'Scatter',
            bubbleTooltip: 'Bubble',
            histogramTooltip: 'Histogram',
            noDataToChart: 'No data available to be charted.',
            pivotChartRequiresPivotMode: 'Pivot Chart requires Pivot Mode enabled.',
            chartSettingsToolbarTooltip: 'Menu',
            chartLinkToolbarTooltip: 'Linked to Grid',
            chartUnlinkToolbarTooltip: 'Unlinked from Grid',
            chartDownloadToolbarTooltip: 'Download Chart',

            // ARIA
            ariaHidden: 'hidden',
            ariaVisible: 'visible',
            ariaChecked: 'checked',
            ariaUnchecked: 'unchecked',
            ariaIndeterminate: 'indeterminate',
            ariaDefaultListName: 'List',
            ariaColumnSelectAll: 'Toggle Select All Columns',
            ariaInputEditor: 'Input Editor',
            ariaDateFilterInput: 'Date Filter Input',
            ariaFilterList: 'Filter List',
            ariaFilterInput: 'Filter Input',
            ariaFilterColumnsInput: 'Filter Columns Input',
            ariaFilterValue: 'Filter Value',
            ariaFilterFromValue: 'Filter from value',
            ariaFilterToValue: 'Filter to value',
            ariaFilteringOperator: 'Filtering Operator',
            ariaColumn: 'Column',
            ariaColumnList: 'Column List',
            ariaColumnGroup: 'Column Group',
            ariaRowSelect: 'Press SPACE to select this row',
            ariaRowDeselect: 'Press SPACE to deselect this row',
            ariaRowToggleSelection: 'Press Space to toggle row selection',
            ariaRowSelectAll: 'Press Space to toggle all rows selection',
            ariaToggleVisibility: 'Press SPACE to toggle visibility',
            ariaSearch: 'Search',
            ariaSearchFilterValues: 'Search filter values',

            ariaRowGroupDropZonePanelLabel: 'Row Groups',
            ariaValuesDropZonePanelLabel: 'Values',
            ariaPivotDropZonePanelLabel: 'Column Labels',
            ariaDropZoneColumnComponentDescription: 'Press DELETE to remove',
            ariaDropZoneColumnValueItemDescription: 'Press ENTER to change the aggregation type',

            // ARIA Labels for Dialogs
            ariaLabelColumnMenu: 'Column Menu',
            ariaLabelCellEditor: 'Cell Editor',
            ariaLabelDialog: 'Dialog',
            ariaLabelSelectField: 'Select Field',
            ariaLabelTooltip: 'Tooltip',
            ariaLabelContextMenu: 'Context Menu',
            ariaLabelSubMenu: 'SubMenu',
            ariaLabelAggregationFunction: 'Aggregation Function',

            // Number Format (Status Bar, Pagination Panel)
            thousandSeparator: ',',
            decimalSeparator: '.'

        },
    },
    getters: {
        //計算最大分頁數
        maxPages(state) {
            return Math.ceil(state.rowsCount / state.rowsPerPage);
        },

        //回報錯誤
        errorReport(error) {
            if (error.response) {
                console.error(error.response.data);
                console.error(error.response.status);
                console.error(error.response.headers);
            } else if (error.request) {
                console.error(error.request);
            } else {
                console.error('Error', error.message);
            }
            console.error(error.config);
        },
        treatedOptions(state) {
            // return state.moldSearchList.map(x=>x.mNum+(x.mP ?"("+x.mP+")":""));
            return state.moldSearchList.map(x => x.mNum);
        },

        //精簡moldList
        moldListSimple(state) {
            return state.moldList.map(x => x.number);
        },
        moldListNotChild(state) {
            return state.moldList.filter(x => x.parent === null);
        },
        moldListOnlyChild(state) {
            return state.moldList.filter(x => x.parent !== null);
        },
        moldListNotParent(state) {
            const allAsP = state.moldList.filter(x => x.parent !== null).map(x => x.parent);
            return state.moldList.filter(x => x.parent !== null || allAsP.findIndex(a => a === x.number) < 0).sort((a, b) => {
                return +/\d+/.exec(a.number)[0] - +/\d+/.exec(b.number)[0];
            });
        },
    },
    mutations: {
        //儲存產品編號清單請求結果
        setProductList(state, payload) {
            state.productList = payload.data;
            state.productListIsLoaded = payload.status;
        },
        setFalse(state) {
            state.productListIsLoaded = false;
        },
        //儲存模具編號清單請求結果
        setMoldList(state, payload) {
            state.moldList = payload.data.results;
            state.moldListIsLoaded = payload.status;
        },

        //鑄造系統
        setCastOrderList(state, payload) {
            // console.log(payload.data.sql);
            state.castOrderList = payload.data.results;
            state.castOrderListListIsLoaded = payload.status;
        },
        setRowsPerPage(state, payload) {
            state.rowsPerPage = payload;
        },
        setRowsCount(state, payload) {
            state.rowsCount = payload.data.rowsCount;
        },
        setCastOrder(state, payload) {
            state.castOrder = payload.data.results;
        },
        setCastOrderId(state, payload) {
            state.clickedOrderId = payload;
        },
        setCustomizedTable(state, payload) {
            state.customizedTable = payload.data.results;
            state.customizedTableIsLoaded = payload.status;
        },
        setCustomizedMoldTable(state, payload) {
            state.customizedMoldTable = payload.data.results;
            state.customizedMoldTableIsLoaded = payload.status;
        },
        setCastOrderResults(state, payload) {
            state.castOrderResults = payload.data.results;
            state.castOrderResultsIsLoaded = payload.status;
        },
        setProductMoldAssociationTable(state, payload) {
            state.productMoldAssociationTable = payload.data.results;
            state.productMoldAssociationTableIsLoaded = payload.status;
        },
        setAuthenticationResult(state, payload) {
            state.isAuthenticated = payload;
        },
        //儲存模具搜尋清單
        setMoldSearchList(state, payload) {
            state.moldSearchList = payload.data.results;
            state.moldSearchIsLoaded = payload.data.success;
        },
        setMoldRelationTable(state, payload) {
            state.moldRelationTable = payload.data.results;
            state.moldProductTable = payload.data.results2;
            state.moldRelationIsLoaded = payload.data.success;
        },
        //直接修改模具搜尋結果內容
        updateLocalMoldResult(state, payload) {
            if (state.moldRelationTable.length < 1) {
                console.log('there is no table to update.');
                return false
            }
            state.moldRelationTable[payload.rowIndex][payload.colIndex] = payload.newValue;
        },
        //儲存模具換名表格
        setMoldTextMutationTable(state, payload) {
            if (payload.mode === "fetch") {
                state.moldTextMutationTable = payload.data.results;
                state.moldTextMutationTableIsLoaded = payload.data.success;
            } else if (payload.mode === "update") {
                state.moldTextMutationTable = payload.data;
            }
        },

        //[產品資料]搜尋產品清單
        setProductDataSearchList(state, payload) {
            state.productDataSearchList = payload.data.results;
            state.productDataSearchListIsLoaded = payload.data.success;
        },
        //[產品資料]資料增查刪改控制器
        setCtrlProductData(state, payload) {
            const method = payload.method;
            if (method === "r") {
                state.productData = payload.data.results[0];
                state.productDataIsLoaded = payload.data.success;
                state.productDataMold = payload.data.results2;
                state.productDataMoldIsLoaded = payload.data.success;
            } else if (method === "getNewId") {
                state.productDataNewId = payload.data.results[0]["newId"]
            } else if (method === "u") {
                state.productDataSearchList[state.productDataSearchList.findIndex(x => x.id === payload.data.id)].number = payload.data.number;
            } else if (method === "c") {
                state.productDataSearchList.push({"id": payload.new.id, "number": payload.new.number});
            } else if (method === "d") {
                state.productDataSearchList.splice(state.productDataSearchList.findIndex(x => x.id === payload.data), 1)
            }
        },
        //[產品資料]模具設定資料增查刪改控制器
        setCtrlProductDataMoldDemands(state, payload) {
            const method = payload.method;
            if (method === "getNewId") {
                state.productDataMoldDemandNewId = payload.data.results[0]["newId"]
            } else if (method === "u") {
                // state.productDataSearchList[state.productDataSearchList.findIndex(x=>x.id === payload.data.id)].number = payload.data.number;
            } else if (method === "d") {
                // console.log("payload為",payload);
            }
        },
        //[模具資料]資料增查刪改控制器
        setCtrlMoldData(state, payload) {
            const method = payload.method;
            if (method === "r") {
                let m = JSON.parse(JSON.stringify(payload.data.results[0]));
                m.isP = m.isP === 1;
                state.moldData = m;
                state.moldDataIsLoaded = payload.data.success;
                state.moldDataChild = payload.data.results2;
                state.moldDataChildIsLoaded = payload.data.success;
            } else if (method === "getNewId") {
                state.moldDataNewId = payload.data.results[0]["newId"]
            } else if (method === "u") {
                const newData = payload.data.newData;
                state.moldList[state.moldList.findIndex(x=>x.id === newData.id)] = {"id":newData.id,"number": newData.number,"parent": newData.parent};
                state.moldDataSearchList[state.moldDataSearchList.findIndex(x=>x.id === newData.id)] = {"id":newData.id,"number": newData.number};
            } else if (method === "c") {
                const newData = payload.data;
                state.moldList.push({"id": newData.id, "number": newData.number,"parent": newData.parent});
                state.moldDataSearchList.push({"id": newData.id, "number": newData.number});
            } else if (method === "d") {
                state.moldDataSearchList.splice(state.moldDataSearchList.findIndex(x => x.id === payload.data.id), 1);
            } else if (method === "searchMold") {
                state.moldDataSearchList = payload.data.results;
                state.moldDataSearchListIsLoaded = payload.data.success;
            } else if (method === "delCheck") {
                state.moldDataDelCheck = payload.data.results;
            }
        },
    },
    actions: {
        //[首頁]用戶驗證
        async userAuthentication(context, userData) {
            await axios({
                method: 'post',
                baseURL: context.state.baseURL,
                url: '/myphp/userAuthentication.php',
                'Content-Type': 'application/json',
                data: {
                    username: userData.username,
                    password: userData.password,
                }
            }).then(response => {
                context.commit('setAuthenticationResult', response.data.results);
            }).catch((error) => {
                context.getters.errorReport(error);
            });
        },
        //[鑄造系統]請求全產品編號清單
        async fetchProductList(context) {
            await axios({
                method: 'get',
                baseURL: context.state.baseURL,
                // baseURL: 'http://localhost:80',
                url: '/myphp/getProducts.php',
                'Content-Type': 'application/json',
            }).then((response) => {
                context.commit('setProductList', {data: response.data, status: true});
            }).catch((error) => {
                context.getters.errorReport(error);
            });
        },
        //[鑄造系統]請求權模具編號清單
        async fetchMoldList(context) {
            await axios({
                method: 'get',
                baseURL: context.state.baseURL,
                // baseURL: 'http://localhost:80',
                url: '/myphp/getMolds.php',
                'Content-Type': 'application/json',
            }).then((response) => {
                context.commit('setMoldList', {data: response.data, status: true});
            }).catch((error) => {
                context.getters.errorReport(error);
            });
        },
        //[檢視鑄造單]請求鑄造單清單
        async fetchCastingOrderList(context, filter, order = "ASC") {
            if (localStorage.getItem('listOrderProperty')) {
                order = localStorage.getItem('listOrderProperty');
            }
            const f = filter.filterConditions || {"nothing": ""};
            await axios({
                method: 'get',
                baseURL: context.state.baseURL,
                url: '/myphp/getCastingOrderList.php',
                'Content-Type': 'application/json',
                params: {
                    limit: context.state.rowsPerPage,
                    pageNow: filter.currentPage,
                    order: order,
                    filter: JSON.stringify(f),
                }
            }).then((response) => {
                context.commit('setCastOrderList', {data: response.data, status: true});
            }).catch((error) => {
                context.getters.errorReport(error);
            });
        },
        //[檢視鑄造單]請求鑄造單清單總數
        async fetchCastingOrderRowsCount({state, getters, commit}) {
            await axios({
                method: 'get',
                baseURL: state.baseURL,
                url: '/myphp/getCastingOrderRows.php',
                'Content-Type': 'application/json',
            }).then((response) => {
                commit('setRowsCount', {data: response.data, status: true});
                // dispatch('fetchCastingOrderList', 1);
            }).catch((error) => {
                getters.errorReport(error);
            });
        },
        //[檢視鑄造單]請求單筆鑄造單內容
        async fetchCastingOrder(context, uuid) {
            await axios({
                method: 'get',
                baseURL: context.state.baseURL,
                url: '/myphp/getCastingOrder.php',
                'Content-Type': 'application/json',
                params: {
                    uuid: uuid,
                }
            }).then((response) => {
                context.commit('setCastOrder', {data: response.data, status: true});
                context.dispatch('fetchCustomizedTable', uuid);
                context.dispatch('fetchCastingOrderConversion', uuid);
            }).catch((error) => {
                context.getters.errorReport(error);
            });
        },
        //[檢視鑄造單]請求單筆鑄造單轉換結果
        async fetchCastingOrderConversion(context, uuid) {
            await axios({
                method: 'get',
                baseURL: context.state.baseURL,
                url: '/myphp/getCastingOrderResults.php',
                'Content-Type': 'application/json',
                params: {
                    uuid: uuid,
                }
            }).then((response) => {
                context.commit('setCastOrderResults', {data: response.data, status: true});
            }).catch((error) => {
                context.getters.errorReport(error);
            });
        },
        //[浮動小工具]請求產品X模具關連表
        async fetchProductMoldAssociation(context, pNum) {
            await axios({
                method: 'get',
                baseURL: context.state.baseURL,
                url: '/myphp/getProductMoldAssociation.php',
                'Content-Type': 'application/json',
                params: {
                    pNum: pNum,
                }
            }).then((response) => {
                // console.log("done");
                context.commit('setProductMoldAssociationTable', {data: response.data, status: true});
            }).catch((error) => {
                context.getters.errorReport(error);
            });
        },
        //[新增鑄造單]提交鑄造單並同時獲取轉換結果
        async submitConvertAllInOne(context, allData) {
            await axios({
                method: 'post',
                baseURL: context.state.baseURL,
                // baseURL: 'http://localhost:80',
                url: '/myphp/formSubmitVueNew.php',///
                'Content-Type': 'application/json',
                data: allData,
            }).then((response) => {
                console.log("轉換完成!");
                context.commit('setCastOrderResults', {data: response.data, status: true});
            }).catch((error) => {
                context.getters.errorReport(error);
            });
        },
        //[檢視鑄造單]請求鑄造單中客製化產品清單
        async fetchCustomizedTable(context, uuid) {
            await axios({
                method: 'get',
                baseURL: context.state.baseURL,
                url: '/myphp/getCustomizedCastingOrder.php',
                'Content-Type': 'application/json',
                params: {
                    uuid: uuid,
                }
            }).then((response) => {
                context.commit('setCustomizedTable', {data: response.data, status: true});
            }).catch((error) => {
                context.getters.errorReport(error);
            });
        },
        //[檢視鑄造單]請求客製化產品鑄造模具清單
        async fetchCustomizedMoldTable(context, uuid) {
            await axios({
                method: 'get',
                baseURL: context.state.baseURL,
                url: '/myphp/getCustomizedMolds.php',
                'Content-Type': 'application/json',
                params: {
                    uuid: uuid,
                }
            }).then((response) => {
                context.commit('setCustomizedMoldTable', {data: response.data, status: true});
            }).catch((error) => {
                context.getters.errorReport(error);
            });
        },
        //[檢視鑄造單]刪除鑄造單
        async deleteCastingOrder(context, selectedRow) {
            await axios({
                method: 'post',
                baseURL: context.state.baseURL,
                url: '/myphp/deleteCastingOrder.php',///
                'Content-Type': 'application/json',
                data: selectedRow,
            }).then((result) => {
                console.log(result.data.message);
            }).catch((error) => {
                context.getters.errorReport(error);
            });
        },
        //[檢視鑄造單]更新鑄造單
        async updateCastingOrder(context, data) {
            await axios({
                method: 'get',
                baseURL: context.state.baseURL,
                url: '/myphp/updateCastingOrder.php',///
                'Content-Type': 'application/json',
                params: data,
            }).then((result) => {
                console.log(result.data.message);
                context.dispatch('fetchCastingOrderConversion', data.uuid);
            }).catch((error) => {
                context.getters.errorReport(error);
            });
        },
        //[模具搜尋]搜尋符合條件的模具的清單
        async fetchMoldSearchList(context, mNum) {
            await axios({
                method: 'get',
                baseURL: context.state.baseURL,
                // baseURL: 'http://localhost:80',
                url: '/myphp/getMoldSearchResults.php',
                'Content-Type': 'application/json',
                params: {
                    mNum: mNum,
                }
            }).then((response) => {
                context.commit('setMoldSearchList', {data: response.data, status: true});
            }).catch((error) => {
                context.getters.errorReport(error);
            });
        },
        //[模具搜尋]搜尋模具與子母模的資料
        async fetchMoldRelation(context, mNum) {
            await axios({
                method: 'get',
                baseURL: context.state.baseURL,
                // baseURL: 'http://localhost:80',
                url: '/myphp/getMoldRelation.php',
                'Content-Type': 'application/json',
                params: {
                    mNum: mNum,
                }
            }).then((response) => {
                context.commit('setMoldRelationTable', {data: response.data, status: true});
            }).catch((error) => {
                context.getters.errorReport(error);
            });
        },
        //[模具文字替換]獲取模具換名表
        async fetchMoldTextMutationTable(context, username) {
            await axios({
                method: 'get',
                baseURL: context.state.baseURL,
                // baseURL: 'http://localhost:80',
                url: '/myphp/getMoldTextMutation.php',
                'Content-Type': 'application/json',
                params: {
                    username: username,
                }
            }).then((response) => {
                context.commit('setMoldTextMutationTable', {data: response.data, status: true, mode: "fetch"});
            }).catch((error) => {
                context.getters.errorReport(error);
            });
        },
        //[模具文字替換]上傳新的模具換名表
        async updateMoldTextMutationTable(context, payload) {
            await axios({
                method: 'post',
                baseURL: context.state.baseURL,
                // baseURL: 'http://localhost:80',
                url: '/myphp/updateMoldTextMutation.php',
                'Content-Type': 'application/json',
                data: {
                    username: payload.username,
                    str: payload.str,
                }
            }).then((response) => {
                context.commit('setMoldTextMutationTable', {data: payload.str, status: true, mode: "update"});
                console.log(response.data.message);
            }).catch((error) => {
                context.getters.errorReport(error);
            });
        },
        //[模具搜尋]更新模具資料
        async updateBunchMold(context, payload) {
            await axios({
                method: 'post',
                baseURL: context.state.baseURL,
                // baseURL: 'http://localhost:80',
                url: '/myphp/updateBunchMold.php',
                'Content-Type': 'application/json',
                data: {molds: payload.molds}
            }).then((response) => {
                console.log(response.data.message);
            }).catch((error) => {
                context.getters.errorReport(error);
            });
        },
        //[模具搜尋]更新模具資料
        async updateMoldDemand(context, payload) {
            await axios({
                method: 'post',
                baseURL: context.state.baseURL,
                // baseURL: 'http://localhost:80',
                url: '/myphp/updateMoldDemand.php',
                'Content-Type': 'application/json',
                data: {md: payload.md}
            }).then((response) => {
                console.log(response.data.message);
            }).catch((error) => {
                context.getters.errorReport(error);
            });
        },
        //[產品資料]根據多個條件查詢產品
        async fetchProductDataSearchList(context, payload) {
            await axios({
                method: 'post',
                baseURL: context.state.baseURL,
                url: '/myphp/getProductDataSearchList.php',
                'Content-Type': 'application/json',
                data: {form: payload}
            }).then((response) => {
                context.commit('setProductDataSearchList', {data: response.data, status: true});
            }).catch((error) => {
                context.getters.errorReport(error);
            });
        },
        //[產品資料]資料增查刪改控制器，payload應長成{"method":"c/r/u/d","data": ***}
        async ctrlProductData(context, payload) {
            await axios({
                method: 'post',
                baseURL: context.state.baseURL,
                url: '/myphp/ctrlProductData.php',
                'Content-Type': 'application/json',
                data: {"fetch": payload}
            }).then((response) => {
                if (payload.method === "r") {
                    if (response.data.success) {
                        context.commit('setCtrlProductData', {
                            method: payload.method,
                            data: response.data,
                            status: true
                        });
                    } else {
                        console.log(response.data.message);
                    }
                } else if (payload.method === "u") {
                    if (response.data.success) {
                        context.commit('setCtrlProductData', {
                            method: payload.method,
                            data: payload.data,
                            status: true
                        });
                        context.dispatch('fetchProductList').catch(e => console.error(e));
                    } else {
                        console.log(response.data.message);
                    }
                } else if (payload.method === "getNewId") {
                    if (response.data.success) {
                        context.commit('setCtrlProductData', {
                            method: payload.method,
                            data: response.data,
                            status: true
                        });
                    } else {
                        console.log(response.data.message);
                    }
                } else if (payload.method === "c") {
                    if (response.data.success) {
                        context.commit('setCtrlProductData', {
                            method: payload.method,
                            data: response.data,
                            status: true,
                            new: payload.data
                        });
                        context.dispatch('fetchProductList').catch(e => console.error(e));
                    } else {
                        console.log(response.data.message);
                    }
                } else if (payload.method === "d") {
                    if (response.data.success) {
                        context.commit('setCtrlProductData', {
                            method: payload.method,
                            data: payload.data,
                            status: true,
                        });
                        context.dispatch('fetchProductList').catch(e => console.error(e));
                    } else {
                        console.log(response.data.message);
                    }
                }
            }).catch((error) => {
                context.getters.errorReport(error);
            });
        },
        //[產品資料]模具增查刪改控制器，payload應長成{"method":"c/r/u/d","data": ***}
        async ctrlProductDataMoldDemands(context, payload) {
            await axios({
                method: 'post',
                baseURL: context.state.baseURL,
                url: '/myphp/ctrlProductDataMoldDemands.php',
                'Content-Type': 'application/json',
                data: {"fetch": payload}
            }).then((response) => {
                if (payload.method === "u") {
                    if (response.data.success) {
                        // context.commit('setCtrlProductDataMoldDemands', {
                        //     method: payload.method,
                        //     data: payload.data,
                        //     status: true
                        // });
                    } else {
                        console.log(response.data.message);
                    }
                } else if (payload.method === "getNewId") {
                    if (response.data.success) {
                        context.commit('setCtrlProductDataMoldDemands', {
                            method: payload.method,
                            data: response.data,
                            status: true
                        });
                    } else {
                        console.log(response.data.message);
                    }
                } else if (payload.method === "d") {
                    if (response.data.success) {
                        // context.commit('setCtrlProductDataMoldDemands', {
                        //     method: payload.method,
                        //     data: payload.data,
                        //     status: true,
                        // });
                    } else {
                        console.log(response.data.message);
                    }
                }
            }).catch((error) => {
                context.getters.errorReport(error);
            });
        },
        //[模具資料]資料增查刪改控制器
        async ctrlMoldData(context, payload) {
            await axios({
                method: 'post',
                baseURL: context.state.baseURL,
                url: '/myphp/ctrlMoldData.php',
                'Content-Type': 'application/json',
                data: {"fetch": payload}
            }).then((response) => {
                if (payload.method === "r") {
                    if (response.data.success) {
                        context.commit('setCtrlMoldData', {
                            method: payload.method,
                            data: response.data,
                            status: true
                        });
                    } else {
                        console.log(response.data.message);
                    }
                } else if (payload.method === "u") {
                    if (response.data.success) {
                        context.commit('setCtrlMoldData', {
                            method: payload.method,
                            data: payload.data,
                            status: true
                        });
                    } else {
                        console.log(response.data.message);
                    }
                } else if (payload.method === "getNewId") {
                    if (response.data.success) {
                        context.commit('setCtrlMoldData', {
                            method: payload.method,
                            data: response.data,
                            status: true
                        });
                    } else {
                        console.log(response.data.message);
                    }
                } else if (payload.method === "c") {
                    if (response.data.success) {
                        context.commit('setCtrlMoldData', {
                            method: payload.method,
                            data: payload.data,
                            status: true,
                        });
                    } else {
                        console.log(response.data.message);
                    }
                } else if (payload.method === "d") {
                    if (response.data.success) {
                        context.commit('setCtrlMoldData', {
                            method: payload.method,
                            data: payload.data,
                            status: true,
                        });
                        context.dispatch('fetchMoldList').catch(e => console.error(e));
                    } else {
                        console.log(response.data.message);
                    }
                } else if (payload.method === "searchMold") {
                    if (response.data.success) {
                        context.commit('setCtrlMoldData', {
                            method: payload.method,
                            data: response.data,
                            status: true,
                        });
                    } else {
                        console.log(response.data.message);
                    }
                } else if (payload.method === "delCheck") {
                    if (response.data.success) {
                        context.commit('setCtrlMoldData', {
                            method: payload.method,
                            data: response.data,
                            status: true,
                        });
                    } else {
                        console.log(response.data.message);
                    }
                }
            }).catch((error) => {
                context.getters.errorReport(error);
            });
        },
    },
    modules: {}
})
