<template>
  <div class="w3-container">
    <h1>工作派發系統</h1>
    <hr/>
    <div class="w3-bar w3-border-0 w3-light-grey">
      <a class="w3-bar-item w3-button w3-hover-teal w3-hover-text-white">新增工作單</a>
      <a class="w3-bar-item w3-button w3-hover-teal w3-hover-text-white">檢視工作單</a>
      <a class="w3-bar-item w3-button w3-hover-teal w3-hover-text-white">等待出貨</a>
    </div>
    <hr/>
    <a></a>
  </div>
</template>

<script>
export default {
  name: 'Task',
};
</script>

<style scoped>
.w3-hover-teal, .w3-hover-text-white{
  -webkit-transition:all .14s linear;
  transition:all .14s linear;
}
</style>
