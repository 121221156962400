<template>
  <div class="auto-complete">
    <input type="text" :style="{width:width-4+'px'}" autocomplete="off" tabindex="0" :ref="'input'"
           oninput="this.value = this.value.toUpperCase()"
           v-model="selectValue"/>
    <div v-if="filteredOptions.length>0" class="options" :ref="'container'"
         :style="{width:width+'px', height: 26*(filteredOptions.length>maxOptions?maxOptions:filteredOptions.length)+'px'}"
         tabindex="1">
      <div :style="{width:width+'px'}"
           :class="{ selected: option.number===selectValue, default: option.number!==selectValue}"
           v-for="(option,index) in filteredOptions" v-bind="option" :key="option.number" :tabindex="index"
           @click.prevent="onClick(option.number)" :data-value="option.number">{{ option.number }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "autoCompleteEditor",
  data() {
    return {
      selectValue: '',
      options: [],
      maxOptions: 0,
      width: 0,
    }
  },
  methods: {
    getValue() {
      return this.selectValue;
    },
    isPopup() {
      return true;
    },
    setSelect(value) {
      this.selectValue = value;
    },
    getOptions(options) {
      this.options = options;
    },
    getMaxOptions(maxOptions) {
      this.maxOptions = maxOptions ? maxOptions : 7;
    },
    onClick(value) {
      this.setSelect(value);
      // this.params.api.stopEditing();
      // console.log(this.selectValue,this.selectIndex)
    },
    // onKeyDown(event) {
    //   const key = event.key;
    //   if (key === 'ArrowUp') {
    //     event.preventDefault();
    //     let newIndex = this.filteredOptions.indexOf(this.selectValue)-1;
    //     this.setSelect(this.filteredOptions[newIndex]);
    //     event.stopPropagation();
    //   } else if (key === 'ArrowDown') {
    //     event.preventDefault();
    //     // let newIndex = (this.selectIndex + 1 <= this.options.length - 1) ? this.selectIndex + 1 : 0;
    //     // this.setSelect(this.options[newIndex].value);
    //     event.stopPropagation();
    //   }
    // },
  },
  computed: {
    filteredOptions() {
      const regOption = new RegExp('^' + this.selectValue, 'ig'); //+ '|^[a-zA-Z]+' + (this.searchFilter) + ''
      return (this.selectValue !== '') ? this.options.filter(x => this.selectValue.length < 1 || x.number.match(regOption)).slice(0, this.maxOptions) : [];
    }
  },
  created() {
    this.getOptions(this.params.colDef.cellEditorParams.options);
    this.getMaxOptions(this.params.colDef.cellEditorParams.maxOptions);
    this.setSelect(this.params.value);
    // console.log(this.params);
    this.width = this.params.column.actualWidth;
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.input.focus();
    })
  }
}
</script>

<style scoped lang="scss">
</style>
