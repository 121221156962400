<template>
  <div class="lightbox">
    <transition name="lightbox">
      <div class="modal-mask" v-show="isShow">
        <div class="modal-container" @click.self="toggleBox" @keyup.esc.exact="toggleBox">

          <div class="modal-body w3-center" :style="{'width':adjustWidth}" ref="lightboxBody">
            <button class="toggleTable w3-button w3-cyan w3-text-white" style="border-radius: 1px" @click="convertCastOrder">
              切換
            </button>
            <div class="filled-date">{{ filledDate }}</div>
            <div v-show="!isConversion">
              <div style="font-size: 1.6em;margin: 1px auto;clear: both">訂單</div>
              <slot name="order">訂單</slot>
            </div>
            <div v-show="isConversion">
              <div style="font-size: 1.6em;margin: 1px auto;clear: both">鑄造單</div>
              <slot name="results">鑄造單</slot>
            </div>
          </div>

        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "LightBox",
  data() {
    return {
      isShow: false,
      isConversion: false,
      filledDate: "",
    }
  },
  computed: {
    adjustWidth() {
      return !this.isConversion ? '60%' : '90%';
    },
  },
  methods: {
    toggleBox() {
      this.isShow = !this.isShow;
      this.isConversion = false;
      this.scrollToTop();
    },
    convertCastOrder() {
      this.isConversion = !this.isConversion;
      this.scrollToTop();
    },
    setTitle(date) {
      this.filledDate = date;
    },
    scrollToTop(){
      this.$nextTick(()=>{
        this.$refs.lightboxBody.scrollTop = 0;
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.lightbox-enter-to,
.lightbox-leave-from {
  opacity: 1;
}

.lightbox-enter-from,
.lightbox-leave-to {
  opacity: 0;
}

.lightbox-enter-active,
.lightbox-leave-active {
  transition: opacity 0.35s ease;
}

.modal-mask {
  display: table;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, .5);

  .modal-container {
    cursor: pointer;
    display: table-cell;
    vertical-align: middle;

    .modal-body {
      border: 2px solid #555555;
      left: 25px;
      cursor: default;
      //width: 60%;
      height: 95%;
      position: relative;
      background-color: #eeeeee;
      margin: 5px auto;
      overflow-y: scroll;
      transition: width 0.7s ease;

      .toggleTable {
        float: left;
        margin: 5px 0;
        position: sticky;
        top: 0;
        left: 0;
        z-index: 99999;
      }

      .filled-date {
        font-size: 1.5em;
        width: fit-content;
        float: top;
        margin: 5px auto;
      }
    }
  }
}
</style>
