<template>
  <div id="app">
    <nav class="main-menu">
      <div class="w3-display-container">
        <div class="w3-display-topleft">
          <ul class="w3-ul">
            <router-link to="/home" custom v-slot="{ navigate, isActive }">
              <li @click="navigate" role="link" :class="{'active': isActive}" class="w3-padding-16">
                <i class="fa fa-user-circle fa-2x"></i><span>使用者</span>
              </li>
            </router-link>
            <router-link to="/order" custom v-slot="{ navigate, isActive }">
              <li @click="navigate" role="link" :class="{'active': isActive}" class="w3-padding-16">
                <i class="fa fa-th-list fa-2x"></i><span>訂單</span>
              </li>
            </router-link>
            <router-link to="/casting" custom v-slot="{ navigate, isActive }">
              <li @click="navigate" role="link" :class="{'active': isActive}" class="w3-padding-16">
                <i class="fa fa-gavel fa-2x"></i><span>鑄造單</span>
              </li>
            </router-link>
            <router-link to="/task" custom v-slot="{ navigate, isActive }">
              <li @click="navigate" role="link" :class="{'active': isActive}" class="w3-padding-16">
                <i class="fa fa-suitcase fa-2x"></i><span>工作單</span>
              </li>
            </router-link>
            <router-link to="/material" custom v-slot="{ navigate, isActive }">
              <li @click="navigate" role="link" :class="{'active': isActive}" class="w3-padding-16">
                <i class="fa fa-cogs fa-2x"></i><span>發回單</span>
              </li>
            </router-link>
            <li @click="logInOut">{{isAuthenticated?"登出":"登入"}}</li>
          </ul>
        </div>
        <div class="w3-display-bottomleft">
          <span style="padding-left: 9px">
            {{ version }}
          </span>
        </div>
      </div>
    </nav>
    <div class="main-content">
      <router-view/>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import router from "@/router/router";

export default {
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
    }
  },
  computed: {
    ...mapState({
      isAuthenticated: "isAuthenticated",
    }),
  },
  methods:{
    ...mapMutations({
      setAuthenticationResult:'setAuthenticationResult',
    }),
    logInOut(){
      if (this.isAuthenticated){
        this.setAuthenticationResult(0);
        localStorage.removeItem('rememberMe');
        router.push({path: 'login'});
      }
    }
  },
}
</script>

<style lang="scss" scoped>
$text-color-default: #1c1c1c;
$mainmenu-bg-color: #fff;
$mainmenu-text-color: #8a8a8a;
$mainmenu-text-color-hover: #fff;
$mainmenu-link-color-hover: #00bbbb;
$mainmenu-link-color-active: #00bb80;
$mainmenu-width: 150px;
$mainmenu-icon-width: 55px;

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text-color-default;
}

.main-content {
  position: relative;
  z-index: 1;
  left: $mainmenu-icon-width;
  width: 95%;
  margin-left: 1%;
}


.main-menu {
  z-index: 10;
  background: #F7F7F7;
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100vh;
  left: 0;
  width: $mainmenu-icon-width;
  overflow-x: hidden;
  -webkit-transition: width .2s linear;
  transition: width .2s linear;
  -webkit-transform: translateZ(0) scale(1, 1);
  box-shadow: 1px 0 8px rgba(0, 0, 0, 0.5);
  opacity: 1;

  .w3-display-container {
    height: 100vh;
  }

  &:hover {
    width: $mainmenu-width;
    overflow: hidden;
    opacity: 1;
  }

  ul {
    background-color: $mainmenu-bg-color;
    margin: 0 0;
    padding-left: 0px;
    list-style-type: none;
    text-decoration: none;
  }

  li {
    color: $mainmenu-text-color;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: $mainmenu-width;
    height: 50px;
    padding: 0 12px !important;
    cursor: pointer;
    -webkit-transition: all .14s linear;
    transition: all .14s linear;

    &:hover {
      color: $mainmenu-text-color-hover;
      background-color: $mainmenu-link-color-hover;
    }

    &.active {
      color: $mainmenu-text-color-hover;
      background-color: $mainmenu-link-color-active;
    }

    i {
      padding-right: 20px;
    }

    span {
      font-size: 20px;
    }
  }
}
</style>
