<template>
  <div class="w3-container w3-center results-container" v-if=results_split_interval_split_rows>
    <div class="table4Factory" v-for="(value,key) in results_split_interval_split_rows" :key="key">
      <div class="button-bar">
        <div class="w3-right"><span>一欄能放幾個</span><input type="number" v-model="maxRowNumInCol" @blur="checkMaxRow"/></div>
        <button class="print-button w3-button w3-right w3-blue-grey" @click="splitTable">重建</button>
        <button class="print-button w3-button w3-right"
                @click.prevent="textColorToggle"
                :style="{'background-color': textColor?'#ff0000':'#c1c1c1'}">
          顏色: {{ textColor ? "開" : "關" }}
        </button>
        <button class="print-button w3-button w3-right w3-teal" v-print="printObj('#table-'+key)">列印</button>
        <button class="print-button w3-button w3-right w3-indigo" @click.self.prevent="printImage('#table-'+key, value)">下載圖片
        </button>
      </div>
      <div v-bind:id="'table-'+key" class="table-fac" ref="printMe">
        <div class="table-label"><label>{{ key }}廠&nbsp;<span style="font-size: 0.7em">
          {{ getCurrentDateTime }}
        </span></label></div>
        <div class="results-column"
             v-for="(vCol, kCol) in value"
             :key="kCol"
             :style="{'width': (100/Object.keys(value).length)+'%', 'min-width': '33.3%'}">
          <div class="header-text" :style="{'display': kCol%3!==0?'none':'block', 'width': `${widthMulti[Object.keys(value).length-1]??'300%'}`}"><label>{{ key }}廠&nbsp;<span style="font-size: 0.7em">
          {{ getCurrentDateTime }}
        </span></label></div>
          <table class="w3-table-all table-child">
            <thead>
            <tr>
              <th>模具編號</th>
              <th class="w3-center">數量</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(v,k) in vCol" :key="k">
              <td :class="{'isPrior': parseInt(v.productPrior), 'textDeColor': !textColor}" contenteditable="true">
                <span>
                {{ v.moldParent ? v.moldParent + ' => ' : '' }}
                </span>
                <!--                {{ v.moldNum.replace(/-combine/i, '組合').replace(/gw0056/i,'gw0056凹') }}-->
                {{
                  textReplacer(v.moldNum, myRules)
                }}
                {{ parseInt(v.productPrior) ? ' 急!' : '' }}
              </td>
              <td class="w3-center"
                  :class="{'isPrior': parseInt(v.productPrior), 'textDeColor': !textColor}" contenteditable="true">
                {{ v.moldNeeds }}
              </td>
            </tr>
            </tbody>
          </table>
          <div class="footer-text" :style="{'display': kCol%3!==0?'none':'block', 'width': `${widthMulti[Object.keys(value).length-1]??'300%'}`}">{{ (kCol/3)+1 }}/{{ Math.ceil(Object.keys(value).length/3) }}</div>
        </div>
      </div>
      <hr>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import print from 'vue3-print-nb';
import html2canvas from 'html2canvas';
import {mapState} from "vuex";

export default {
  name: "CastingOrderResultsTable",
  components: {},
  directives: {
    print
  },
  data() {
    return {
      widthMulti: ['100%', '200%', '300%'],
      // results: this.castingOrderResults,
      allFactory: [],
      results_split: {},
      currentProductClass: "",
      offset: 0,//紀錄現在到第幾個
      maxRowNumInCol: 33,//一欄能放幾個
      results_split_interval: {},
      results_split_interval_split_rows: {},

      canvasOutput: null,

      textColor: true,
      myRules: [
        {id: 0, regex: "-combine", newText: "組合"},
        {id: 1, regex: "GW0056-1", newText: "GW0056-1凹"},
        {id: 2, regex: "GW0056-2", newText: "GW0056-2凹"},
        {id: 3, regex: "GW0056-3", newText: "GW0056-3凹"},
        {id: 4, regex: "E364-A", newText: "E364-A鼠"},
        {id: 5, regex: "E364-B", newText: "E364-B牛"},
        {id: 6, regex: "E364-C", newText: "E364-C虎"},
        {id: 7, regex: "E364-D", newText: "E364-D兔"},
        {id: 8, regex: "E365-A", newText: "E365-A龍"},
        {id: 9, regex: "E365-B", newText: "E365-B蛇"},
        {id: 10, regex: "E365-C", newText: "E365-C馬"},
        {id: 11, regex: "E365-D", newText: "E365-D羊"},
        {id: 12, regex: "E366-A", newText: "E366-A猴"},
        {id: 13, regex: "E366-B", newText: "E366-B雞"},
        {id: 14, regex: "E366-C", newText: "E366-C狗"},
        {id: 15, regex: "E366-D", newText: "E366-D豬"},
        {id: 16, regex: "E325-A", newText: "E325-A龍"},
        {id: 17, regex: "E325-B", newText: "E325-B羊"},
        {id: 18, regex: "E325-C", newText: "E325-C雞"},
        {id: 19, regex: "E325-D", newText: "E325-D豬"},
        {id: 20, regex: "E326-A", newText: "E326-A蛇"},
        {id: 21, regex: "E326-B", newText: "E326-B馬"},
        {id: 22, regex: "E326-C", newText: "E326-C猴"},
        {id: 23, regex: "E326-D", newText: "E326-D狗"},
        {id: 24, regex: "E327-A", newText: "E327-A鼠"},
        {id: 25, regex: "E327-B", newText: "E327-B牛"},
        {id: 26, regex: "E327-C", newText: "E327-C虎"},
        {id: 27, regex: "E327-D", newText: "E327-D兔"},
      ],
    }
  },
  props: {
    currentDate: {
      type: String,
      required: false,
      default: "",
      note: '顯示鑄造單下單日期'
    },
  },
  // props: ["castingOrderResults"],
  methods: {
    textReplacer(text = "", rules = []) { //rules應該長得像是[{regex: new RegExp("-combine","i"), newText: "組合"}]
      if (!text || text.trim() === " ") return "";
      if (!rules || rules.length === 0) return text;
      _.forEach(rules, (value) => {
        if (value['method'] === 0) {
          text = text.replace(new RegExp(`^${value['regex']}$`, "i"), value['newText']);
        } else if (value['method'] === 1) {
          text = text.replace(new RegExp(`^${value['regex']}`, "i"), value['newText']);
        } else if (value['method'] === 2) {
          text = text.replace(new RegExp(`${value['regex']}$`, "i"), value['newText']);
        } else if (value['method'] === 3) {
          text = text.replace(new RegExp(value['regex'], "i"), value['newText']);
        }
      });
      return text;
    },
    splitTable() {
      this.results_split = {};
      this.results_split_interval = {};
      this.results_split_interval_split_rows = {};
      this.allFactory = _.uniq(_.map(this.castOrderResults, "moldFac"));
      _.forEach(this.allFactory, (value) => {
        this.results_split[value] = _.filter(this.castOrderResults, a => a.moldFac === value);
      });
      // console.log(this.results_split);
      this.insertEmptyRow();
    },
    insertEmptyRow() {
      _.forEach(this.results_split, (value, key) => {
        this.results_split_interval[key] = {};
        _.forEach(value, (v, k) => {
          let productClass = v.sortNum.match(/^[a-zA-Z]+/ig)[0];
          if (this.currentProductClass !== productClass && this.currentProductClass !== "") {
            // console.log(this.currentProductClass, ">", productClass);
            this.results_split_interval[key][k + this.offset] = {
              moldNum: "",
              moldParent: "",
              moldNeeds: "",
              productPrior: 0,
              moldFac: "",
              sortNum: ""
            };
            this.offset += 1;
          }
          this.results_split_interval[key][k + this.offset] = v;
          this.currentProductClass = productClass;
          // console.log(k + ":" + this.currentProductClass);
        });
        this.currentProductClass = "";
        this.offset = 0;
      });
      // console.log(this.results_split_interval);
      // console.log(Object.keys(this.results_split_interval.B).length);
      this.splitRows();
    },
    splitRows() {
      _.forEach(this.results_split_interval, (value, key) => {
        this.results_split_interval_split_rows[key] = {};
        let rowsNum = Object.keys(this.results_split_interval[key]).length;
        let maxRowNumInCol = this.maxRowNumInCol;//調整一欄能放幾個
        let colsNum = Math.ceil(rowsNum / maxRowNumInCol);
        let newKey = 0;
        _.forEach(_.range(colsNum), (v) => {
          // console.log("new column "+v);
          this.results_split_interval_split_rows[key][v] = {};
        });
        _.forEach(value, (v, k) => {
          let currentCol = Math.floor(k / maxRowNumInCol);
          let currentNum = v.moldNum;
          // console.log("currentCol is "+currentCol+" currentNum is "+currentNum);
          if (currentNum || Object.keys(this.results_split_interval_split_rows[key][currentCol]).length !== 0) {
            this.results_split_interval_split_rows[key][currentCol][newKey] = v;
            newKey += 1;
          }
        });
      });
      // console.log(this.results_split_interval_split_rows);
    },

    //列印指定鑄造結果表格
    printObj(id) {
      // console.log(id);
      return {
        id: id,
        popTitle: '鑄造單',
        // extraCss: "@page {margin: 0} @media print {*{-webkit-print-color-adjust: exact !important;break-inside: avoid;}}",
        extraHead: '<meta http-equiv="Content-Language" content="zh-TW"/>',
      };
    },

    //輸出指定鑄造結果表格圖檔
    printImage(selector, data) {
      const pages = Math.ceil(Object.keys(data).length/3);
      console.log(pages);
      const width = document.querySelector(selector).clientWidth;
      const height = document.querySelector(selector).clientHeight;
      let options = {
        // width: 1300,
        // height: 1300 * pages,
        windowWidth: width,
        windowHeight: height,
        // x: 2.5,//150
        // y: -10,
      };
      html2canvas(document.querySelector(selector), options).then((canvas) => {
        const link = document.createElement("a");
        const filename = 'img_' + selector + '_' + new Date().toLocaleDateString() + '.png';
        link.href = canvas.toDataURL("image/png;base64");
        // link.target = "_blank";
        link.setAttribute("download", filename);
        link.click();
        link.remove();
      })
    },

    textColorToggle() {
      this.textColor = !this.textColor;
    },

    checkMaxRow(){
      if (this.maxRowNumInCol < 20 || this.maxRowNumInCol === null || this.maxRowNumInCol === undefined){
        this.maxRowNumInCol = 20;
      } else if (this.maxRowNumInCol > 35) {
        this.maxRowNumInCol = 35;
      }
    }
  },
  computed: {
    ...mapState({
      castOrderResults: 'castOrderResults',//
      castOrderResultsIsLoaded: 'castOrderResultsIsLoaded',//
      moldTextMutationTable: 'moldTextMutationTable',//讀取模具變換表
    }),
    getCurrentDateTime() {
      const today = new Date();
      let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
      let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      return date + ' ' + time;
    },
    getCurrentDateOnly() {
      const today = new Date();
      return today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    },
  },
  watch: {
    castOrderResults() {
      // console.log("AAAAAGGA!");
      // this.results = this.castingOrderResults;
      this.splitTable();
    },
  },
  mounted() {
    if (typeof this.moldTextMutationTable === "string" || this.moldTextMutationTable !== "") {
      this.myRules = JSON.parse(this.moldTextMutationTable.toString().trim() + '');
    }
    this.splitTable();
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/defaultStyle";

.results-container {
  margin: 0 auto;
  min-width: 200px;

  .table4Factory {
    margin-bottom: 2em;
    width: 100%;

    .button-bar {
      display: inline-flex;
      align-items: center;
      .print-button {
        margin: auto 5px;
      }
    }

    .table-fac {
      width: 90%;
      margin: 0 auto;
      padding: 0 7px;
      position: relative;


      .table-label {
        width: 50%;
        margin: 0 auto;

        label {
          font-size: 2em;
          font-weight: bold;
        }
      }

      .results-column {
        position: relative;
        display: inline-flex;
        flex-wrap: wrap;
        width: 33.3%;
        //min-width: 240px;
        .footer-text, .header-text{
          order: 1;
          display: none!important;
        }
      }
    }
  }
}

table {
  user-select: text;
  margin-top: 10px;
  margin-bottom: 10px;
  //min-width: 240px;

  thead tr th, tfoot tr th {
    height: 20px;
    font-size: 0.8em;
    background-color: #74ffa2;

    &:nth-child(1) {
      width: 80%;
    }

    &:nth-child(2) {
      width: 20%;
    }
  }

  tbody {
    tr {
      td {
        height: $table-row-height;
        padding: 2px 0 2px !important;
        border-bottom: 1px solid #000000;

        &:first-child {
          padding: 2px 0 2px 10px !important;
        }

        vertical-align: middle;
        max-height: 40px;
        min-height: 0;
        font-size: $table-row-font-size;
        color: #000000;

        &:not(:nth-child(1)) {
          border-left: 1px solid #000000;
        }

        span {
          font-size: 0.85em !important;
          font-weight: bold;
        }

        &.isPrior {
          color: #ff0000;
          font-size: 1.15em;
          font-weight: bold;

          &.textDeColor {
            color: #000000;
          }
        }
      }

      //&:not(:nth-last-child(1)){
      //  td {
      //    border-bottom: 1px solid #000000;
      //  }
      //}
    }
  }
}

@media print {
  .table-fac {
    -webkit-print-color-adjust: exact !important;
    break-inside: avoid;
    margin: 0 1px 0 0;

    thead tr th, tfoot tr th {
      height: 20px;
      font-size: 0.8em;
      background-color: #74ffa2;

      &:nth-child(1) {
        width: 70%;
      }

      &:nth-child(2) {
        width: 30%;
      }
    }

    .table-label {
      position: absolute;
      width: 100%;
      break-after: avoid-page;
      -webkit-column-break-after: avoid;
      z-index: 99;
      display: none;

      label {
        font-size: 0.5em;
        font-weight: bold;
        margin: 0 auto;
      }
    }

    .results-column {
      position: relative;
      display: inline-flex;
      flex-wrap: wrap;
      //width: 33.3%;
      //min-width: 240px;
      .footer-text{
        order: 1;
        //width: 300%;
        text-align: center;
        position: absolute;
        //transform: translateY(1020px);
        transform: translateY(calc( 100vh - 25px ));
      }
      .header-text{
        order: 0;
        //width: 300%;
        text-align: center;
        position: absolute;
        //transform: translateY(-10px);
        font-size: 1em;
        font-weight: bold;
      }
      .table-child {
        transform: translateY(10px);
      }
    }
  }


  table {
    break-after: auto;
    -webkit-column-break-after: auto;
  }
  tr, td, div {
    break-inside: avoid;
    -webkit-column-break-inside: avoid;
    break-after: auto;
    -webkit-column-break-after: auto;
  }
  thead {
    display: table-header-group
  }
  tfoot {
    display: table-footer-group
  }


}
</style>
